export const environment = {
  production: false,
  apiEndpoint: 'https://localhost:44303/',
  dotNetApiEndpoint: 'https://localhost:44303/',
  appInsights: {
    instrumentationKey: '4344d663-38b3-4332-bafc-79482a96b4e8'
  }
  //    //apiEndpoint: 'https://relyfynetcanaryws.azurewebsites.net/RelyFyDotNet/',
  //   //apiEndpoint: 'https://relyfynetws-spring.azurewebsites.net/RelyFyDotNet/',
  //  // apiEndpoint: 'https://stagingnet-relyfynetspring.azurewebsites.net/RelyFyDotNet/',

  //  dotNetApiEndpoint: 'https://eventkindcanaryapi.azurewebsites.net/api/',
  //   //dotNetApiEndpoint: 'https://eventkinddevapi.azurewebsites.net/api/',
  //   // dotNetApiEndpoint: 'https://eventkindprodapi.azurewebsites.net/api/', // prod
  //   messageHubUrl: 'https://localhost:44303/messagehub',
  //   //messageHubUrl: 'https://eventkinddevapi.azurewebsites.net/messagehub',
  //   demoEmpUUID: '0ca34992-372d-47d9-8a79-c04e1abe556f',
  //   joinEventUrl:"http://localhost:4200/view/landing?eventUUID=",

  //   appInsights: {
  //     instrumentationKey: '4344d663-38b3-4332-bafc-79482a96b4e8'
  //   },
  //   freshChat: {
  //     // token: "f4045fde-1b11-4262-b091-2d1b0ec4d491",
  //     token: "451b2cad-5717-4562-ba12-03dac18aefc4", // prod
  //     host: 'https://wchat.freshchat.com',
  //   }
};

