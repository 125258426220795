import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { ChannelDetailComponent } from './channel-detail/channel-detail.component';
import { EventDetailComponent } from './Component/event-detail/event-detail.component';
import { StaticChannelComponent } from './static-channel/static-channel.component';
import { AuditoriumComponent } from './Component/auditorium/auditorium.component';
import { VideoEndComponent } from './Component/video-end/video-end.component';
import { EventlandingComponent } from 'pck-general-components';
import { UserBlogComponent } from './Component/user-blog/user-blog.component';
import { FirstEventBlogComponent } from './Component/first-event-blog/first-event-blog.component';
import { SecondEventBlogComponent } from './Component/second-event-blog/second-event-blog.component';
import { ThirdEventBlogComponent } from './Component/third-event-blog/third-event-blog.component';
import { FifthEventBlogComponent } from './Component/fifth-event-blog/fifth-event-blog.component';
import { FreeMasterClassComponent as GeorgiaFreeMasterClassComponent } from './15thDec/sfc/georgia/free-master-class/free-master-class.component';
import { FreeMasterClassComponent as NCFreeMasterClassComponent } from './15thDec/sfc/north-carolina/free-master-class/free-master-class.component';
import { FreeMasterClassComponent as icwpFreeMasterClassComponent } from './15thDec/icwp/free-master-class/free-master-class.component';
import { VideoDetailComponent } from './15thDec/video-detail/video-detail.component';

const routes: Routes = [
  { path: '', component: StaticChannelComponent },
  { path: 'success-summit', component: StaticChannelComponent },
  { path: 'live-event-end', component: VideoEndComponent },
  { path: 'live-event', component: AuditoriumComponent },
  { path: 'live-event/solving-staffing-challenges', component: AuditoriumComponent },
  { path: 'live-event/finding-growth', component: AuditoriumComponent },
  { path: 'live-event/blueprint-for-serenity', component: AuditoriumComponent },
  { path: 'live-event/5s-for-compliance', component: AuditoriumComponent },
  { path: 'live-event/consolidating-the-chaos', component: AuditoriumComponent },
  { path: 'live-event/consolidating-the-chaos/care-confusion', component: FirstEventBlogComponent },
  { path: 'live-event/consolidating-the-chaos/care-relationship-network', component: SecondEventBlogComponent },
  { path: 'live-event/blueprint-for-serenity/user-blog', component: ThirdEventBlogComponent },
  { path: 'live-event/5s-for-compliance/stress-free-compliance', component: UserBlogComponent },
  { path: 'live-event/consolidating-the-chaos/user-blog', component: FifthEventBlogComponent },

  { path: 'sfc/georgia/free-master-class', component: GeorgiaFreeMasterClassComponent },
  { path: 'sfc/north-carolina/free-master-class', component: NCFreeMasterClassComponent },
  { path: 'icwp/free-master-class', component: icwpFreeMasterClassComponent },
  { path: 'sfc/georgia/free-master-class/video', component: VideoDetailComponent },
  { path: 'sfc/north-carolina/free-master-class/video', component: VideoDetailComponent },
  { path: 'icwp/free-master-class/video', component: VideoDetailComponent },

  { path: '**', redirectTo: 'success-summit' }
  // { path: 'eventDetail', component: EventDetailComponent},
  // { path: 'eventlanding', component: EventlandingComponent},
  // { path: ':channel', component: ChannelDetailComponent} 
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes),
    CommonModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
