import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-second-event-blog',
  templateUrl: './second-event-blog.component.html',
  styleUrls: ['./second-event-blog.component.scss']
})
export class SecondEventBlogComponent implements OnInit{
  ngOnInit(): void {
    var html = document.getElementsByTagName('body')[0];
    html.classList.add("blog");
  }

}
