<div id="first" class="th1" *ngIf="eventData.id=='506e6132-cc82-49e4-a28e-d933eca43e89'">
  <section class="video-section1">
    <div class="video-description-container1  video_details_dv">
      <div class="session-date-time">
        <div class="session-date">
          <!-- <h1 class="session-day" *ngIf="eventData  && eventData.day">{{eventData.day}}</h1> -->
          <h1 class="session-day">11</h1>
          <h1 class="session-month">Oct 23</h1>
        </div>
        <div class="session-time">
          <h1 class="session-time-text">10:00 am</h1>
        </div>
      </div>
      <div class="session-content-parent">
        <div class="session-content">
          <h1 class="session-day">Solving Staffing Challenges</h1>
          <h2 class="session-description">
            5 Proven Strategies Every Medicaid Provider Must Know!
          </h2>
        </div>
        <div class="host-card1">
          <img class="host-image-icon1" alt="" src="assets/public/host-image1@2x.png" />

          <div class="host-information1">
            <h1 class="host-name1">Anand Chaturvedi</h1>
            <h3 class="host-designation1">CEO, Caryfy AI</h3>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="video-description-container video_details_dv">
          <div class="solving-staffing-challenges-parent">
              <h1 class="solving-staffing-challenges h1_heading_dv">
                  Solving Staffing Challenges
              </h1>
              <h2 class="hire-faster-train">
                  Hire faster, train better, and retain right employees
              </h2>
          </div>
          <div class="learning-objectives-parent">
              <h2 class="solving-staffing-challenges">Learning Objectives</h2>
              <h3 class="identify-common-staffing-container">
                  <ul class="identify-common-staffing-pitfa">
                      <li class="identify-common-staffing">
                          Identify common staffing pitfalls in HCBS
                      </li>
                      <li>
                          Apply best practices for hiring, training and retaining
                          caregivers
                      </li>
                  </ul>
              </h3>
          </div>
      </div> -->
  </section>
  <section class="michelle-story custom-michelle-story">
    <div class="michelle-story-container">
      <img class="story-image-icon" alt="" src="assets/public/story-image@2x.png" />

      <div class="story-content">
        <h2 class="session-day">Michael’s Story: A Familiar Echo</h2>
        <div class="story-description">
          <p class="its-like-im-running-on-a-tre">
            <span>It’s like I’m running on a treadmill, Michael confided, his
              voice threading through the quiet room, constantly moving but
              getting nowhere. His eyes, usually a beacon of unwavering
              resolve, flickered with the weariness of constant goodbyes and
              hellos in his team.
            </span>
            <b class="every-new-face">"Every new face, every training session, it feels like a déjà
              vu of departure, and amidst it all, I’m missing out...on life,
              on my kids."</b>
          </p>
          <p class="but-michaels-struggle">
            But Michael’s struggle isn’t solitary. It resonates with many
            home and community based services (HCBS) owners, perhaps even
            you, navigating through the perpetual cycle of hiring and
            training, all while silently yearning for a moment of respite, a
            slice of time that belongs just to you and your loved ones.
          </p>
        </div>
      </div>
    </div>
  </section>
  <section class="different-tomorrow custom-different-tomorrow">
    <div class="michelle-story-container">
      <div class="tomorrow-content">
        <h2 class="session-day">Imagine a Different Tomorrow</h2>
        <div class="story-description">
          Imagine a world where your business thrives even when you step
          back, where your evenings are not entwined with worries about
          who’s leaving next, and your days are not mired in endless hiring
          cycles. Envision being a leader who empowers, inspires, and crafts
          not just a business, but a legacy, all while being confidently at
          the helm, steering through the challenges with clarity and
          expertise.
        </div>
      </div>
      <img class="tomorrow-image-icon" alt="" src="assets/public/tomorrow-image@2x.png" />
    </div>
  </section>
  <section class="testimonial-stories custom-testimonial-stories">
    <div class="testimonial-stories-container">
      <h2 class="testimonials-stories-title">
        Testimonials: Stories of Transformation
      </h2>
      <div class="testimonials-stories-items">
        <div class="stories-items">
          <img class="stories-items-child" alt="" src="assets/public/ellipse-3@2x.png" />

          <div class="stories-text">
            <p class="its-like-im-running-on-a-tre">
              <b class="every-new-face">Sarah</b>
              <span>
                went from administrative chaos to a structured, stable, and
                thriving pediatric home care agency, all while reclaiming
                her evenings to be with her family, untouched by staffing
                worries.</span>
            </p>
          </div>
        </div>
        <div class="stories-items">
          <img class="stories-items-child" alt="" src="assets/public/ellipse-5@2x.png" />

          <div class="stories-text">
            <p class="its-like-im-running-on-a-tre">
              <b class="every-new-face">James</b>
              <span>
                turned his elderly adult day health care facility from a
                whirlwind of constant hiring into a model of stability and
                efficiency, ensuring his clients received unwavering,
                high-quality care and he preserved his peace of mind.</span>
            </p>

          </div>
        </div>
        <div class="stories-items">
          <img class="stories-items-child" alt="" src="assets/public/ellipse-4@2x.png" />

          <div class="stories-text">
            <p class="its-like-im-running-on-a-tre">
              <b class="every-new-face">Anita</b>
              <span>, owner of a residential care facility found her way out of
                the hiring web, elevating her facility into a beacon of
                stability, employee satisfaction, and exemplary client
                care.</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="yout-pathway custom-yout-pathway">
    <div class="testimonial-stories-container">
      <div class="your-pathway-subheading-parent">
        <h3 class="session-day">Your Pathway to Transformation</h3>
        <h2 class="your-pathway-heading">
          Webinar on Solving Staffing Challenges
        </h2>
      </div>
      <h4 class="session-day">What Awaits You</h4>
      <div class="pathways-item-list">
        <div class="pathways-item-card">
          <div class="pathways-card-icon">
            <img class="icon" alt="" src="assets/public/icon.svg" />
          </div>
          <div class="pathways-card-content">
            <b class="stories-text" style="font-weight: 700;">Specifying Roles:</b>
            <div class="stories-text">
              Create a stable team structure and enhance operational
              efficiency.
            </div>
          </div>
        </div>
        <div class="pathways-item-card">
          <div class="pathways-card-icon">
            <img class="icon" alt="" src="assets/public/icon1.svg" />
          </div>
          <div class="pathways-card-content">
            <b class="stories-text" style="font-weight: 700;">Mastering Recruiting:</b>
            <div class="stories-text">
              Ensure your team is enriched and stabilized with every new
              hire.
            </div>
          </div>
        </div>
        <div class="pathways-item-card">
          <div class="pathways-card-icon">
            <img class="icon" alt="" src="assets/public/icon2.svg" />
          </div>
          <div class="pathways-card-content">
            <b class="stories-text" style="font-weight: 700;">Assessing and Rewarding:</b>
            <div class="stories-text">
              Boost morale and foster a culture of appreciation and
              motivation.
            </div>
          </div>
        </div>
        <div class="pathways-item-card">
          <div class="pathways-card-icon">
            <img class="icon" alt="" src="assets/public/icon3.svg" />
          </div>
          <div class="pathways-card-content">
            <b class="stories-text" style="font-weight: 700;">Training and Empowering:</b>
            <div class="stories-text">
              Elevate your leadership and steer your business with
              confidence.
            </div>
          </div>
        </div>
      </div>
      <h4 class="session-day">Benefits for You</h4>
      <div class="pathways-item-list">
        <div class="pathways-item-card">
          <div class="pathways-card-icon">
            <img class="icon" alt="" src="assets/public/icon4.svg" />
          </div>
          <div class="pathways-card-content">
            <b class="stories-text" style="font-weight: 700;">Break Free</b>
            <div class="stories-text">
              from the exhausting hiring cycle.
            </div>
          </div>
        </div>
        <div class="pathways-item-card">
          <div class="pathways-card-icon">
            <img class="icon" alt="" src="assets/public/icon5.svg" />
          </div>
          <div class="pathways-card-content">
            <b class="stories-text" style="font-weight: 700;">Reclaim Your Time</b>
            <div class="stories-text">to be with your loved ones.</div>
          </div>
        </div>
        <div class="pathways-item-card">
          <div class="pathways-card-icon">
            <img class="icon" alt="" src="assets/public/icon6.svg" />
          </div>
          <div class="pathways-card-content">
            <b class="stories-text" style="font-weight: 700;">Build a Legacy</b>
            <div class="stories-text">
              that transcends beyond business to empowerment.
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="next-step custom-next-step">
    <div class="testimonial-stories-container">
      <h2 class="take-the-next">Take the Next Step</h2>
      <div class="be-a-part-container">
        <span>Be a part of in this transformative webinar and step into a realm
          where your business, your team, and your personal life coexist in
          harmonious balance. Navigate through the challenges, not alone,
          but together with a community that understands, supports, and
          uplifts.
        </span>
        <b>Join us </b>
        <span>in crafting a tomorrow where you are not just a provider but an
          empowered leader, a present parent, and a confident
          entrepreneur.</span>
      </div>
      <div class="be-a-part-container">
        Take a step towards solving your staffing challenges, towards being
        the leader you aspire to be, and towards crafting a future that
        aligns with your dreams, aspirations, and the tranquility of
        undisturbed moments with your loved ones. Let’s navigate through
        this, together.
      </div>
    </div>
  </section>
  <section class="other-session1 custom-other-session-dv">
    <div class="other-session-container1">
      <h2 class="session-day">Our Sessions</h2>
      <div class="card-container1">
        <div class="session-card6">
          <div class="card-image-75">
            <img class="card-image-icon6" alt="" src="assets/public/card-image6@2x.png" />
          </div>
          <div class="card-description6">
            <div class="card-meta-info6">
              <div class="date6">
                <img class="icon-reply5" alt="" src="assets/public/date-icon4.svg" />

                <div class="comments3">October 11th,2023</div>
              </div>
              <div class="badges6">
                <div class="online6">
                  <div class="comments3">Online</div>
                </div>
                <div class="free6">
                  <div class="comments3">Free</div>
                </div>
              </div>
            </div>
            <div class="card-title-group6">
              <a class="cart-heading6"
                [href]="isUserLogin?liveURL+firstEventUUID:'live-event/solving-staffing-challenges'">Solving Staffing
                Challenges</a>
              <a class="cart-subheading6">Hire faster, train better, and retain right employees</a>
            </div>
            <div class="tags6">
              <div class="tag-text24">#staffing-challenges</div>
              <div class="tag-text24">#staffing-pitfalls</div>
              <div class="tag-text24">#employees</div>
              <div class="tag-text24">#staffing-issues</div>
            </div>
          </div>
        </div>
        <div class="session-card6">
          <div class="card-image-75">
            <img class="card-image-icon6" alt="" src="assets/public/card-image6@2x.png" />
          </div>
          <div class="card-description6">
            <div class="card-meta-info6">
              <div class="date6">
                <img class="icon-reply5" alt="" src="assets/public/date-icon5.svg" />

                <div class="comments3">October 18th,2023</div>
              </div>
              <div class="badges6">
                <div class="online6">
                  <div class="comments3">Online</div>
                </div>
                <div class="free6">
                  <div class="comments3">Free</div>
                </div>
              </div>
            </div>
            <div class="card-title-group6">
              <a class="cart-heading6" [href]="isUserLogin?liveURL+secondEventUUID:'live-event/finding-growth'">Finding
                Growth</a>
              <a class="cart-subheading6">How to grow by leveraging digital marketing for
                referrals</a>
            </div>
            <div class="tags6">
              <div class="tag-text24">#digital-marketing</div>
              <div class="tag-text24">#social-media</div>
              <div class="tag-text24">#digital-tools</div>
              <div class="tag-text24">#AI-generated-process</div>
            </div>
          </div>
        </div>
        <div class="session-card6">
          <div class="card-image-75">
            <img class="card-image-icon6" alt="" src="assets/public/card-image6@2x.png" />
          </div>
          <div class="card-description6">
            <div class="card-meta-info6">
              <div class="date6">
                <img class="icon-reply5" alt="" src="assets/public/date-icon6.svg" />

                <div class="comments3">October 25th,2023</div>
              </div>
              <div class="badges6">
                <div class="online6">
                  <div class="comments3">Online</div>
                </div>
                <div class="free6">
                  <div class="comments3">Free</div>
                </div>
              </div>
            </div>
            <div class="card-title-group6">
              <a class="cart-heading6"
                [href]="isUserLogin?liveURL+thirdEventUUID:'live-event/blueprint-for-serenity'">Blueprint for
                Serenity</a>
              <a class="cart-subheading6">The DREAM Method to Tame Paperwork</a>
            </div>
            <div class="tags6">
              <div class="tag-text24">#easy-process</div>
              <div class="tag-text24">#paperless</div>
              <div class="tag-text24">#detail-reports</div>
              <div class="tag-text24">#auto-generation</div>
            </div>
          </div>
        </div>
        <div class="session-card6">
          <div class="card-image-75">
            <img class="card-image-icon6" alt="" src="assets/public/card-image@1x.jpg" />
          </div>
          <div class="card-description6">
            <div class="card-meta-info6">
              <div class="date6">
                <img class="icon-reply5" alt="" src="assets/public/date-icon4.svg" />

                <div class="comments3">November 1st,2023</div>
              </div>
              <div class="badges6">
                <div class="online6">
                  <div class="comments3">Online</div>
                </div>
                <div class="free6">
                  <div class="comments3">Free</div>
                </div>
              </div>
            </div>
            <div class="card-title-group6">
              <a class="cart-heading6" [href]="isUserLogin?liveURL+fourthEventUUID:'live-event/5s-for-compliance'">5S
                for Compliance</a>
              <a class="cart-subheading6">
                <p class="making-compliance-stress1">
                  Making compliance stress free
                </p>
              </a>
            </div>
            <div class="tags6">
              <div class="tag-text24">#compliance</div>
              <div class="tag-text24">#management</div>
              <div class="tag-text24">#stress-free</div>
              <div class="tag-text24">#simplify-documentation</div>
            </div>
          </div>
        </div>
        <div class="session-card6">
          <div class="card-image-75">
            <img class="card-image-icon6" alt="" src="assets/public/card-image6@2x.png" />
          </div>
          <div class="card-description6">
            <div class="card-meta-info6">
              <div class="date6">
                <img class="icon-reply5" alt="" src="assets/public/date-icon6.svg" />

                <div class="comments3">November 8th,2023</div>
              </div>
              <div class="badges6">
                <div class="online6">
                  <div class="comments3">Online</div>
                </div>
                <div class="free6">
                  <div class="comments3">Free</div>
                </div>
              </div>
            </div>
            <div class="card-title-group6">
              <a class="cart-heading6"
                [href]="isUserLogin?liveURL+fifthEventUUID:'live-event/consolidating-the-chaos'">Consolidating the
                Chaos</a>
              <a class="cart-subheading6">How a Unified Care Ecosystem Transforms Productivity</a>
            </div>
            <div class="tags6">
              <div class="tag-text24">#implementation</div>
              <div class="tag-text24">#stress-free</div>
              <div class="tag-text24">#management</div>
              <div class="tag-text24">#improvement</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="testimonials1 custom-testimonials-blg">
    <div class="testimonial-container1">
      <div class="column2">
        <h2 class="session-day">WONDERING IF THIS REALLY WORKS?</h2>
        <h2 class="testimonial-description1">
          Check out how many medicaid entrepreneurs are mastering
          operational efficiency using this SIMPLE SYSTEM
        </h2>
      </div>
      <div class="column3">
        <div class="testimonial-item2">
          <h4 class="testimonial-quote2">
            "These sessions are informative, good take-aways and concise
            enough for owners and managers to take full advantage of the
            resources."
          </h4>
          <b class="testimonial-author2">– M Sizemore, Augusta, GA</b>
        </div>
        <div class="testimonial-item2">
          <h4 class="testimonial-quote2">
            "Truly appreciate you creating this summit!"
          </h4>
          <b class="testimonial-author2">– O Cisse, Atlanta, GA</b>
        </div>
      </div>
    </div>
  </section>
  <footer class="cta-section1 custom-ta-section-dv">
    <div class="cta-container1">
      <h2 class="cta-title1">Have any question?</h2>
      <h3 class="cta-description1">
        If you have any questions or require further information, please
        don't hesitate to reach out to us. We look forward to your
        participation and meaningful discussions during the session.
      </h3>
      <div class="cta-contact-person1">
        <img class="contact-image-icon1" alt="" src="assets/public/contact-image@1x.jpg" />

        <h3 class="contact-name1" style="color:#606060!important;font-weight: 400!important;font-size: 24px;">Beth C.
          Greer</h3>
        <h4 class="contact-email1">beth@carebusinessadvisor.com</h4>
      </div>
    </div>
  </footer>
</div>

<!-- second event start -->
<div id="second" class="th1" *ngIf="eventData.id=='b46f9aeb-5832-41aa-a057-2c44a527bc67'">
  <section class="video-section1">
    <div class="video-description-container1  video_details_dv">
      <div class="session-date-time">
        <div class="session-date">
          <!-- <h1 class="session-day" *ngIf="eventData  && eventData.day">{{eventData.day}}</h1> -->
          <h1 class="session-day">18</h1>
          <h1 class="session-month">Oct 23</h1>
        </div>
        <div class="session-time">
          <h1 class="session-time-text">10:00 am</h1>
        </div>
      </div>
      <div class="session-content-parent">
        <div class="session-content">
          <h1 class="session-day">Finding Growth</h1>
          <h1 class="session-day" *ngIf="!eventData.title">Finding Growth: 5 Proven Strategies Every Medicaid Provider
            Must Know!</h1>
          <h2 class="session-description">
            How to grow by leveraging digital marketing for referrals.
          </h2>
          <h2 class="session-description">
            5 Proven Strategies Every Medicaid Provider Must Know!
          </h2>
        </div>
        <div class="host-card1">
          <img class="host-image-icon1" alt="" src="assets/public/host-image1@2x.png" />

          <div class="host-information1">
            <h1 class="host-name1">Anand Chaturvedi</h1>
            <h3 class="host-designation1">CEO, Caryfy AI</h3>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="video-description-container video_details_dv">
          <div class="solving-staffing-challenges-parent">
              <h1 class="solving-staffing-challenges h1_heading_dv">
                  Solving Staffing Challenges
              </h1>
              <h2 class="hire-faster-train">
                  Hire faster, train better, and retain right employees
              </h2>
          </div>
          <div class="learning-objectives-parent">
              <h2 class="solving-staffing-challenges">Learning Objectives</h2>
              <h3 class="identify-common-staffing-container">
                  <ul class="identify-common-staffing-pitfa">
                      <li class="identify-common-staffing">
                          Identify common staffing pitfalls in HCBS
                      </li>
                      <li>
                          Apply best practices for hiring, training and retaining
                          caregivers
                      </li>
                  </ul>
              </h3>
          </div>
      </div> -->
  </section>
  <section class="michelle-story custom-michelle-story">
    <div class="michelle-story-container">
      <img class="story-image-icon" alt="" src="assets/public/story_image.png" />

      <div class="story-content">
        <!-- <h2 class="session-day">Michael’s Story: A Familiar Echo</h2> -->
        <div class="story-description">
          <p class="its-like-im-running-on-a-tre">
            Are you a dedicated Medicaid care provider looking to elevate your services and outreach? Dive into the
            revolutionary SENSE methodology—a game-changing blueprint tailored exclusively for you. In this exclusive
            webinar, uncover actionable insights and strategies that will redefine the trajectory of your care business.
          </p>

        </div>
      </div>
    </div>
  </section>
  <section class="different-tomorrow custom-different-tomorrow">
    <div class="michelle-story-container">
      <div class="tomorrow-content">
        <h2 class="session-day">What You'll Discover:</h2>
        <div class="story-description">
          <p><b>Search Engine Mastery:</b>
            Learn the secrets of optimizing your online presence, ensuring families find you when they need you the
            most.</p>
          <p>
            <b>Email Campaigning Excellence: </b>
            Craft compelling campaigns that resonate with your audience, fostering trust and building lasting
            connections.
          </p>
          <p>
            <b> Nurturing Referral Relationships:</b>
            Discover techniques to strengthen and maintain vital referral
            sources, turning them into your most potent allies.
          </p>
          <p>
            <b>Social Media Savviness:</b>
            Navigate the digital realm with confidence, establishing a robust online presence that amplifies your
            brand.
          </p>
          <p>
            <b>Event Planning, Online & Offline: </b>
            Engage with your community, host impactful
            events, and build bridges that last.
          </p>

        </div>
      </div>
      <img class="tomorrow-image-icon" alt="" src="assets/public/tomorrow-image.png" />
    </div>
  </section>


  <section class="yout-pathway custom-yout-pathway">
    <div class="testimonial-stories-container">
      <div class="your-pathway-subheading-parent">

        <h2 class="your-pathway-heading">
          Why Attend?
        </h2>
      </div>
      <h4 class="session-day">Benefits for You</h4>
      <div class="pathways-item-list">
        <div class="pathways-item-card">
          <div class="pathways-card-icon">
            <img class="icon" alt="" src="assets/public/actionable_strategies.svg" />
          </div>
          <div class="pathways-card-content">
            <b class="stories-text" style="font-weight: 700;">Actionable Strategies</b>
            <div class="stories-text">
              This isn't just theory. Walk away with practical steps you can implement immediately.
            </div>
          </div>
        </div>
        <div class="pathways-item-card">
          <div class="pathways-card-icon">
            <img class="icon" alt="" src="assets/public/expert_insights.svg" />
          </div>
          <div class="pathways-card-content">
            <b class="stories-text" style="font-weight: 700;">Expert Insights</b>
            <div class="stories-text">
              Benefit from the accumulated wisdom of industry leaders and pioneers.
            </div>
          </div>
        </div>
        <div class="pathways-item-card">
          <div class="pathways-card-icon">
            <img class="icon" alt="" src="assets/public/network_collaborate.svg" />
          </div>
          <div class="pathways-card-content">
            <b class="stories-text" style="font-weight: 700;">Network & Collaborate</b>
            <div class="stories-text">
              Connect with like-minded professionals, share challenges, and uncover solutions.
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>



  <section class="testimonial-stories custom-testimonial-stories">
    <div class="testimonial-stories-container">
      <h2 class="testimonials-stories-title">
        Testimonials: Stories of Transformation
      </h2>
      <div class="testimonials-stories-items">
        <div class="stories-items">
          <img class="stories-items-child" alt="" src="assets/public/ellipse-3@2x.png" />

          <div class="stories-text">
            <p class="its-like-im-running-on-a-tre">

              <span>
                The SENSE methodology transformed my approach to care services. I've never felt more aligned with my
                mission.</span>
              <br>
              <b class="every-new-face">Medicaid Case Manager, GA</b>
            </p>
          </div>
        </div>
        <div class="stories-items">
          <img class="stories-items-child" alt="" src="assets/public/ellipse-5@2x.png" />

          <div class="stories-text">
            <span>
              This approach was a game-changer for me. The strategies are not just effective; they resonate with the
              heart of care services.</span>
            <br>
            <b class="every-new-face">Medicaid PSS provider, NC</b>
          </div>
        </div>
      </div>
    </div>
  </section>







  <section class="next-step custom-next-step join-conversation-dv">
    <div class="testimonial-stories-container">
      <h2 class="take-the-next" style="color: #4D4D4D!important;">Join the Conversation</h2>

      <div class="be-a-part-container" style="color: #4D4D4D!important;">
        Stay updated, engage in discussions, and be part of a community passionate about transforming care services.
      </div>

      <div class="social-btn-blg">
        <a href="https://www.facebook.com/Caryfy/" style="text-decoration: none;" class="" style="margin: 5px;">
          <img class="logo-caryfyai-icon" alt="" style="width: 50px!important;" src="assets/public/facebook.png" /></a>


        <a href="https://www.linkedin.com/company/caryfy/" style="text-decoration: none;" class="" style="margin: 5px;">
          <img class="logo-caryfyai-icon" alt="" style="width: 50px!important;" src="assets/public/linkedin.png" /></a>
      </div>
    </div>
  </section>
  <section class="other-session1 custom-other-session-dv">
    <div class="other-session-container1">
      <h2 class="session-day">Our Sessions</h2>
      <div class="card-container1">
        <div class="session-card6">
          <div class="card-image-75">
            <img class="card-image-icon6" alt="" src="assets/public/card-image6@2x.png" />
          </div>
          <div class="card-description6">
            <div class="card-meta-info6">
              <div class="date6">
                <img class="icon-reply5" alt="" src="assets/public/date-icon4.svg" />

                <div class="comments3">October 11th,2023</div>
              </div>
              <div class="badges6">
                <div class="online6">
                  <div class="comments3">Online</div>
                </div>
                <div class="free6">
                  <div class="comments3">Free</div>
                </div>
              </div>
            </div>
            <div class="card-title-group6">
              <a class="cart-heading6"
                [href]="isUserLogin?liveURL+firstEventUUID:'live-event/solving-staffing-challenges'">Solving Staffing
                Challenges</a>
              <a class="cart-subheading6">Hire faster, train better, and retain right employees</a>
            </div>
            <div class="tags6">
              <div class="tag-text24">#staffing-challenges</div>
              <div class="tag-text24">#staffing-pitfalls</div>
              <div class="tag-text24">#employees</div>
              <div class="tag-text24">#staffing-issues</div>
            </div>
          </div>
        </div>
        <div class="session-card6">
          <div class="card-image-75">
            <img class="card-image-icon6" alt="" src="assets/public/card-image6@2x.png" />
          </div>
          <div class="card-description6">
            <div class="card-meta-info6">
              <div class="date6">
                <img class="icon-reply5" alt="" src="assets/public/date-icon5.svg" />

                <div class="comments3">October 18th,2023</div>
              </div>
              <div class="badges6">
                <div class="online6">
                  <div class="comments3">Online</div>
                </div>
                <div class="free6">
                  <div class="comments3">Free</div>
                </div>
              </div>
            </div>
            <div class="card-title-group6">
              <a class="cart-heading6" [href]="isUserLogin?liveURL+secondEventUUID:'live-event/finding-growth'">Finding
                Growth</a>
              <a class="cart-subheading6">How to grow by leveraging digital marketing for
                referrals</a>
            </div>
            <div class="tags6">
              <div class="tag-text24">#digital-marketing</div>
              <div class="tag-text24">#social-media</div>
              <div class="tag-text24">#digital-tools</div>
              <div class="tag-text24">#AI-generated-process</div>
            </div>
          </div>
        </div>
        <div class="session-card6">
          <div class="card-image-75">
            <img class="card-image-icon6" alt="" src="assets/public/card-image6@2x.png" />
          </div>
          <div class="card-description6">
            <div class="card-meta-info6">
              <div class="date6">
                <img class="icon-reply5" alt="" src="assets/public/date-icon6.svg" />

                <div class="comments3">October 25th,2023</div>
              </div>
              <div class="badges6">
                <div class="online6">
                  <div class="comments3">Online</div>
                </div>
                <div class="free6">
                  <div class="comments3">Free</div>
                </div>
              </div>
            </div>
            <div class="card-title-group6">
              <a class="cart-heading6"
                [href]="isUserLogin?liveURL+thirdEventUUID:'live-event/blueprint-for-serenity'">Blueprint for
                Serenity</a>
              <a class="cart-subheading6">The DREAM Method to Tame Paperwork</a>
            </div>
            <div class="tags6">
              <div class="tag-text24">#easy-process</div>
              <div class="tag-text24">#paperless</div>
              <div class="tag-text24">#detail-reports</div>
              <div class="tag-text24">#auto-generation</div>
            </div>
          </div>
        </div>
        <div class="session-card6">
          <div class="card-image-75">
            <img class="card-image-icon6" alt="" src="assets/public/card-image@1x.jpg" />
          </div>
          <div class="card-description6">
            <div class="card-meta-info6">
              <div class="date6">
                <img class="icon-reply5" alt="" src="assets/public/date-icon4.svg" />

                <div class="comments3">November 1st,2023</div>
              </div>
              <div class="badges6">
                <div class="online6">
                  <div class="comments3">Online</div>
                </div>
                <div class="free6">
                  <div class="comments3">Free</div>
                </div>
              </div>
            </div>
            <div class="card-title-group6">
              <a class="cart-heading6" [href]="isUserLogin?liveURL+fourthEventUUID:'live-event/5s-for-compliance'">5S
                for Compliance</a>
              <a class="cart-subheading6">
                <p class="making-compliance-stress1">
                  Making compliance stress free
                </p>
              </a>
            </div>
            <div class="tags6">
              <div class="tag-text24">#compliance</div>
              <div class="tag-text24">#management</div>
              <div class="tag-text24">#stress-free</div>
              <div class="tag-text24">#simplify-documentation</div>
            </div>
          </div>
        </div>
        <div class="session-card6">
          <div class="card-image-75">
            <img class="card-image-icon6" alt="" src="assets/public/card-image6@2x.png" />
          </div>
          <div class="card-description6">
            <div class="card-meta-info6">
              <div class="date6">
                <img class="icon-reply5" alt="" src="assets/public/date-icon6.svg" />

                <div class="comments3">November 8th,2023</div>
              </div>
              <div class="badges6">
                <div class="online6">
                  <div class="comments3">Online</div>
                </div>
                <div class="free6">
                  <div class="comments3">Free</div>
                </div>
              </div>
            </div>
            <div class="card-title-group6">
              <a class="cart-heading6"
                [href]="isUserLogin?liveURL+fifthEventUUID:'live-event/consolidating-the-chaos'">Consolidating the
                Chaos</a>
              <a class="cart-subheading6">How a Unified Care Ecosystem Transforms Productivity</a>
            </div>
            <div class="tags6">
              <div class="tag-text24">#implementation</div>
              <div class="tag-text24">#stress-free</div>
              <div class="tag-text24">#management</div>
              <div class="tag-text24">#improvement</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="testimonials1 custom-testimonials-blg">
    <div class="testimonial-container1">
      <div class="column2">
        <h2 class="session-day">WONDERING IF THIS REALLY WORKS?</h2>
        <h2 class="testimonial-description1">
          Check out how many medicaid entrepreneurs are mastering
          operational efficiency using this SIMPLE SYSTEM
        </h2>
      </div>
      <div class="column3">
        <div class="testimonial-item2">
          <h4 class="testimonial-quote2">
            "These sessions are informative, good take-aways and concise
            enough for owners and managers to take full advantage of the
            resources."
          </h4>
          <b class="testimonial-author2">– M Sizemore, Augusta, GA</b>
        </div>
        <div class="testimonial-item2">
          <h4 class="testimonial-quote2">
            "Truly appreciate you creating this summit!"
          </h4>
          <b class="testimonial-author2">– O Cisse, Atlanta, GA</b>
        </div>
      </div>
    </div>
  </section>
  <footer class="cta-section1 custom-ta-section-dv">
    <div class="cta-container1">
      <h2 class="cta-title1">Have any question?</h2>
      <h3 class="cta-description1">
        If you have any questions or require further information, please
        don't hesitate to reach out to us. We look forward to your
        participation and meaningful discussions during the session.
      </h3>
      <div class="cta-contact-person1">
        <img class="contact-image-icon1" alt="" src="assets/public/contact-image@1x.jpg" />

        <h3 class="contact-name1" style="color:#606060!important;font-weight: 400!important;font-size: 24px;">Beth C.
          Greer</h3>
        <h4 class="contact-email1">beth@carebusinessadvisor.com</h4>
      </div>
    </div>
  </footer>
</div>
<!-- second event end -->

<div id="third" class="th1" *ngIf="eventData.id=='ba99bfaa-359d-413c-8ca5-3306e79290bd'">
  <section class="video-section1">
    <div class="video-description-container1  video_details_dv">
      <div class="session-date-time">
        <div class="session-date">
          <!-- <h1 class="session-day" *ngIf="eventData  && eventData.day">{{eventData.day}}</h1> -->
          <h1 class="session-day">25</h1>
          <h1 class="session-month">Oct 23</h1>
        </div>
        <div class="session-time">
          <h1 class="session-time-text">10:00 am</h1>
        </div>
      </div>
      <div class="session-content-parent">
        <div class="session-content">
          <h1 class="session-day">Blueprint for Serenity</h1>
          <h2 class="session-description">
            The DREAM Method to Tame Paperwork
          </h2>
        </div>
        <div class="host-card1">
          <img class="host-image-icon1" alt="" src="assets/public/host-image1@2x.png" />

          <div class="host-information1">
            <h1 class="host-name1">Anand Chaturvedi</h1>
            <h3 class="host-designation1">CEO, Caryfy AI</h3>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="video-description-container video_details_dv">
            <div class="solving-staffing-challenges-parent">
                <h1 class="solving-staffing-challenges h1_heading_dv">
                    Solving Staffing Challenges
                </h1>
                <h2 class="hire-faster-train">
                    Hire faster, train better, and retain right employees
                </h2>
            </div>
            <div class="learning-objectives-parent">
                <h2 class="solving-staffing-challenges">Learning Objectives</h2>
                <h3 class="identify-common-staffing-container">
                    <ul class="identify-common-staffing-pitfa">
                        <li class="identify-common-staffing">
                            Identify common staffing pitfalls in HCBS
                        </li>
                        <li>
                            Apply best practices for hiring, training and retaining
                            caregivers
                        </li>
                    </ul>
                </h3>
            </div>
        </div> -->
  </section>



  <section class="michelle-story custom-michelle-story">
    <div class="michelle-story-container">
      <img class="story-image-icon" alt="" src="assets/public/intro-dearm-method.png" />

      <div class="story-content">
        <!-- <h2 class="session-day">Michael’s Story: A Familiar Echo</h2> -->
        <div class="story-description">
          <p class="its-like-im-running-on-a-tre">
            In the heart of every caregiver lies a profound purpose— to nurture,
            to heal, and to uplift. However, the relentless whirlpool of paperwork often dims this noble essence,
            shrouding days in administrative chaos. It's time to break free, to unveil a realm of
            operational serenity where caregiving thrives over bureaucracy. Welcome to the
            “Blueprint for Serenity - DREAM Method to Skip Paperwork as a Care Provider”
            webinar, your stepping stone towards a harmonious caregiving odyssey.
          </p>

        </div>
      </div>
    </div>
  </section>
  <!-- <section class="michelle-story custom-michelle-story">
        <div class="michelle-story-container">
          <img
            class="story-image-icon"
            alt=""
            src="assets/public/story_image.png"
          />
  
          <div class="story-content">
            <h2 class="session-day">Michael’s Story: A Familiar Echo</h2>
            <div class="story-description">
              <p class="its-like-im-running-on-a-tre">
                Are you a dedicated Medicaid care provider looking to elevate your services and outreach? Dive into the 
                revolutionary SENSE methodology—a game-changing blueprint tailored exclusively for you. In this exclusive 
                webinar, uncover actionable insights and strategies that will redefine the trajectory of your care business.
              </p>
             
            </div>
          </div>
        </div>
      </section> -->





  <section class="different-tomorrow custom-different-tomorrow">
    <div class="michelle-story-container">
      <div class="tomorrow-content">
        <h2 class="session-day">What You'll Discover:</h2>
        <div class="story-description">
          <p>Here's what you'll learn in the Blueprint for Serenity webinar:</p>
          <ul>
            <li>The 5-step formula to effortlessly stay on top of Medicaid requirements</li>
            <li>How to leverage technology to reduce administrative work by 50% or more
            </li>
            <li>Pro tips to streamline workflows and processes</li>
            <li>The secret to automating compliance with easy checklists
            </li>
            <li>How successful providers have cut paperwork by over 80%
            </li>
          </ul>
          <p>With our DREAM Method, you'll escape the paperwork nightmare for good.</p>
        </div>
      </div>
      <img class="tomorrow-image-icon" alt="" src="assets/public/discover-search-img.png" />
    </div>
  </section>
  <!-- <section class="different-tomorrow custom-different-tomorrow">
        <div class="michelle-story-container">
          <div class="tomorrow-content">
            <h2 class="session-day">What You'll Discover:</h2>
            <div class="story-description">
              <p><b>Search Engine Mastery:</b> 
                Learn the secrets of optimizing your online presence, ensuring families find you when they need you the most.</p>
                <p>
                  <b>Email Campaigning Excellence: </b> 
                  Craft compelling campaigns that resonate with your audience, fostering trust and building lasting connections. 
                 </p>
                 <p>
                  <b> Nurturing Referral Relationships:</b> 
                  Discover techniques to strengthen and maintain vital referral 
                  sources, turning them into your most potent allies. 
                 </p>
                 <p>
                  <b>Social Media Savviness:</b> 
                  Navigate the digital realm with confidence, establishing a robust online presence that amplifies your 
                  brand. 
                 </p>
                 <p>
                  <b>Event Planning, Online & Offline: </b>
                  Engage with your community, host impactful 
                  events, and build bridges that last.
                 </p> 
                
            </div>
          </div>
          <img
            class="tomorrow-image-icon"
            alt=""
            src="assets/public/tomorrow-image.png"
          />
        </div>
      </section> -->



  <section class="yout-pathway custom-yout-pathway">
    <div class="testimonial-stories-container">
      <div class="your-pathway-subheading-parent">

        <h2 class="your-pathway-heading">
          Who Should Attend:
        </h2>
      </div>
      <div class="pathways-item-list">
        <div class="pathways-item-card">
          <div class="pathways-card-icon">
            <img class="icon" alt="" src="assets/public/coach-img.png" />
          </div>
          <div class="pathways-card-content">
            <b class="stories-text" style="font-weight: 700;">Care Coaches</b>
            <div class="stories-text">
              <!-- This isn't just theory. Walk away with practical steps you can implement immediately. -->
            </div>
          </div>
        </div>
        <div class="pathways-item-card">
          <div class="pathways-card-icon">
            <img class="icon" alt="" src="assets/public/support.png" />
          </div>
          <div class="pathways-card-content">
            <b class="stories-text" style="font-weight: 700;">Personal Support Service Providers</b>
            <div class="stories-text">
              <!-- Benefit from the accumulated wisdom of industry leaders and pioneers. -->
            </div>
          </div>
        </div>
        <div class="pathways-item-card">
          <div class="pathways-card-icon">
            <img class="icon" alt="" src="assets/public/property-insurance.png" />
          </div>
          <div class="pathways-card-content">
            <b class="stories-text" style="font-weight: 700;">Personal Care Home Owners</b>
            <div class="stories-text">
              <!-- Connect with like-minded professionals, share challenges, and uncover solutions. -->
            </div>
          </div>
        </div>
        <div class="pathways-item-card">
          <div class="pathways-card-icon">
            <img class="icon" alt="" src="assets/public/receptionist.png" />
          </div>
          <div class="pathways-card-content">
            <b class="stories-text" style="font-weight: 700;">Nursing Home Administrators</b>
            <div class="stories-text">
              <!-- Connect with like-minded professionals, share challenges, and uncover solutions. -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- <section class="yout-pathway custom-yout-pathway">
        <div class="testimonial-stories-container">
          <div class="your-pathway-subheading-parent">
         
            <h2 class="your-pathway-heading">
              Why Attend?
            </h2>
          </div>
          <h4 class="session-day">Benefits for You</h4>
          <div class="pathways-item-list">
            <div class="pathways-item-card">
              <div class="pathways-card-icon">
                <img class="icon" alt="" src="assets/public/actionable_strategies.svg" />
              </div>
              <div class="pathways-card-content">
                <b class="stories-text" style="font-weight: 700;">Actionable Strategies</b>
                <div class="stories-text">
                  This isn't just theory. Walk away with practical steps you can implement immediately.
                </div>
              </div>
            </div>
            <div class="pathways-item-card">
              <div class="pathways-card-icon">
                <img class="icon" alt="" src="assets/public/expert_insights.svg" />
              </div>
              <div class="pathways-card-content">
                <b class="stories-text" style="font-weight: 700;">Expert Insights</b>
                <div class="stories-text">
                  Benefit from the accumulated wisdom of industry leaders and pioneers.
                </div>
              </div>
            </div>
            <div class="pathways-item-card">
              <div class="pathways-card-icon">
                <img class="icon" alt="" src="assets/public/network_collaborate.svg" />
              </div>
              <div class="pathways-card-content">
                <b class="stories-text" style="font-weight: 700;">Network & Collaborate</b>
                <div class="stories-text">
                  Connect with like-minded professionals, share challenges, and uncover solutions.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> -->


  <section class="michelle-story custom-michelle-story">
    <div class="michelle-story-container">
      <img class="story-image-icon" alt="" src="assets/public/case_study.png" />

      <div class="story-content">
        <h2 class="session-day">Case Study</h2>
        <div class="story-description">
          <p class="its-like-im-running-on-a-tre">
            Hear from individuals who transformed their caregiving ventures, basking now in a
            realm where paperwork is a breeze and caregiving is the melody.
          </p>

        </div>
      </div>
    </div>
  </section>
  <section class="testimonial-stories custom-testimonial-stories">
    <div class="testimonial-stories-container">
      <h2 class="testimonials-stories-title">
        Testimonials: Stories of Transformation
      </h2>
      <div class="testimonials-stories-items">
        <div class="stories-items">
          <img class="stories-items-child" alt="" src="assets/public/ellipse-3@2x.png" />

          <div class="stories-text">
            <p class="its-like-im-running-on-a-tre">

              <span>
                The SENSE methodology transformed my approach to care services. I've never felt more aligned with my
                mission.</span>
              <br>
              <b class="every-new-face">Medicaid Case Manager, GA</b>
            </p>
          </div>
        </div>
        <div class="stories-items">
          <img class="stories-items-child" alt="" src="assets/public/ellipse-5@2x.png" />

          <div class="stories-text">
            <span>
              This approach was a game-changer for me. The strategies are not just effective; they resonate with the
              heart of care services.</span>
            <br>
            <b class="every-new-face">Medicaid PSS provider, NC</b>
          </div>
        </div>
      </div>
    </div>
  </section>







  <section class="next-step custom-next-step join-conversation-dv">
    <div class="testimonial-stories-container">
      <h2 class="take-the-next" style="color: #4D4D4D!important;">Join the Conversation</h2>

      <div class="be-a-part-container" style="color: #4D4D4D!important;">
        Stay updated, engage in discussions, and be part of a community passionate about transforming care services.
      </div>

      <div class="social-btn-blg">
        <a href="https://www.facebook.com/Caryfy/" style="text-decoration: none;" class="" style="margin: 5px;">
          <img class="logo-caryfyai-icon" alt="" style="width: 50px!important;" src="assets/public/facebook.png" /></a>


        <a href="https://www.linkedin.com/company/caryfy/" style="text-decoration: none;" class="" style="margin: 5px;">
          <img class="logo-caryfyai-icon" alt="" style="width: 50px!important;" src="assets/public/linkedin.png" /></a>
      </div>
    </div>
  </section>
  <section class="other-session1 custom-other-session-dv">
    <div class="other-session-container1">
      <h2 class="session-day">Our Sessions</h2>
      <div class="card-container1">
        <div class="session-card6">
          <div class="card-image-75">
            <img class="card-image-icon6" alt="" src="assets/public/card-image6@2x.png" />
          </div>
          <div class="card-description6">
            <div class="card-meta-info6">
              <div class="date6">
                <img class="icon-reply5" alt="" src="assets/public/date-icon4.svg" />

                <div class="comments3">October 11th,2023</div>
              </div>
              <div class="badges6">
                <div class="online6">
                  <div class="comments3">Online</div>
                </div>
                <div class="free6">
                  <div class="comments3">Free</div>
                </div>
              </div>
            </div>
            <div class="card-title-group6">
              <a class="cart-heading6"
                [href]="isUserLogin?liveURL+firstEventUUID:'live-event/solving-staffing-challenges'">Solving Staffing
                Challenges</a>
              <a class="cart-subheading6">Hire faster, train better, and retain right employees</a>
            </div>
            <div class="tags6">
              <div class="tag-text24">#staffing-challenges</div>
              <div class="tag-text24">#staffing-pitfalls</div>
              <div class="tag-text24">#employees</div>
              <div class="tag-text24">#staffing-issues</div>
            </div>
          </div>
        </div>
        <div class="session-card6">
          <div class="card-image-75">
            <img class="card-image-icon6" alt="" src="assets/public/card-image6@2x.png" />
          </div>
          <div class="card-description6">
            <div class="card-meta-info6">
              <div class="date6">
                <img class="icon-reply5" alt="" src="assets/public/date-icon5.svg" />

                <div class="comments3">October 18th,2023</div>
              </div>
              <div class="badges6">
                <div class="online6">
                  <div class="comments3">Online</div>
                </div>
                <div class="free6">
                  <div class="comments3">Free</div>
                </div>
              </div>
            </div>
            <div class="card-title-group6">
              <a class="cart-heading6" [href]="isUserLogin?liveURL+secondEventUUID:'live-event/finding-growth'">Finding
                Growth</a>
              <a class="cart-subheading6">How to grow by leveraging digital marketing for
                referrals</a>
            </div>
            <div class="tags6">
              <div class="tag-text24">#digital-marketing</div>
              <div class="tag-text24">#social-media</div>
              <div class="tag-text24">#digital-tools</div>
              <div class="tag-text24">#AI-generated-process</div>
            </div>
          </div>
        </div>
        <div class="session-card6">
          <div class="card-image-75">
            <img class="card-image-icon6" alt="" src="assets/public/card-image6@2x.png" />
          </div>
          <div class="card-description6">
            <div class="card-meta-info6">
              <div class="date6">
                <img class="icon-reply5" alt="" src="assets/public/date-icon6.svg" />

                <div class="comments3">October 25th,2023</div>
              </div>
              <div class="badges6">
                <div class="online6">
                  <div class="comments3">Online</div>
                </div>
                <div class="free6">
                  <div class="comments3">Free</div>
                </div>
              </div>
            </div>
            <div class="card-title-group6">
              <a class="cart-heading6"
                [href]="isUserLogin?liveURL+thirdEventUUID:'live-event/blueprint-for-serenity'">Blueprint for
                Serenity</a>
              <a class="cart-subheading6">The DREAM Method to Tame Paperwork</a>
            </div>
            <div class="tags6">
              <div class="tag-text24">#easy-process</div>
              <div class="tag-text24">#paperless</div>
              <div class="tag-text24">#detail-reports</div>
              <div class="tag-text24">#auto-generation</div>
            </div>
          </div>
        </div>
        <div class="session-card6">
          <div class="card-image-75">
            <img class="card-image-icon6" alt="" src="assets/public/card-image@1x.jpg" />
          </div>
          <div class="card-description6">
            <div class="card-meta-info6">
              <div class="date6">
                <img class="icon-reply5" alt="" src="assets/public/date-icon4.svg" />

                <div class="comments3">November 1st,2023</div>
              </div>
              <div class="badges6">
                <div class="online6">
                  <div class="comments3">Online</div>
                </div>
                <div class="free6">
                  <div class="comments3">Free</div>
                </div>
              </div>
            </div>
            <div class="card-title-group6">
              <a class="cart-heading6" [href]="isUserLogin?liveURL+fourthEventUUID:'live-event/5s-for-compliance'">5S
                for Compliance</a>
              <a class="cart-subheading6">
                <p class="making-compliance-stress1">
                  Making compliance stress free
                </p>
              </a>
            </div>
            <div class="tags6">
              <div class="tag-text24">#compliance</div>
              <div class="tag-text24">#management</div>
              <div class="tag-text24">#stress-free</div>
              <div class="tag-text24">#simplify-documentation</div>
            </div>
          </div>
        </div>
        <div class="session-card6">
          <div class="card-image-75">
            <img class="card-image-icon6" alt="" src="assets/public/card-image6@2x.png" />
          </div>
          <div class="card-description6">
            <div class="card-meta-info6">
              <div class="date6">
                <img class="icon-reply5" alt="" src="assets/public/date-icon6.svg" />

                <div class="comments3">November 8th,2023</div>
              </div>
              <div class="badges6">
                <div class="online6">
                  <div class="comments3">Online</div>
                </div>
                <div class="free6">
                  <div class="comments3">Free</div>
                </div>
              </div>
            </div>
            <div class="card-title-group6">
              <a class="cart-heading6"
                [href]="isUserLogin?liveURL+fifthEventUUID:'live-event/consolidating-the-chaos'">Consolidating the
                Chaos</a>
              <a class="cart-subheading6">How a Unified Care Ecosystem Transforms Productivity</a>
            </div>
            <div class="tags6">
              <div class="tag-text24">#implementation</div>
              <div class="tag-text24">#stress-free</div>
              <div class="tag-text24">#management</div>
              <div class="tag-text24">#improvement</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="testimonials1 custom-testimonials-blg">
    <div class="testimonial-container1">
      <div class="column2">
        <h2 class="session-day">WONDERING IF THIS REALLY WORKS?</h2>
        <h2 class="testimonial-description1">
          Check out how many medicaid entrepreneurs are mastering
          operational efficiency using this SIMPLE SYSTEM
        </h2>
      </div>
      <div class="column3">
        <div class="testimonial-item2">
          <h4 class="testimonial-quote2">
            "These sessions are informative, good take-aways and concise
            enough for owners and managers to take full advantage of the
            resources."
          </h4>
          <b class="testimonial-author2">– M Sizemore, Augusta, GA</b>
        </div>
        <div class="testimonial-item2">
          <h4 class="testimonial-quote2">
            "Truly appreciate you creating this summit!"
          </h4>
          <b class="testimonial-author2">– O Cisse, Atlanta, GA</b>
        </div>
      </div>
    </div>
  </section>
  <footer class="cta-section1 custom-ta-section-dv">
    <div class="cta-container1">
      <h2 class="cta-title1">Have any question?</h2>
      <h3 class="cta-description1">
        If you have any questions or require further information, please
        don't hesitate to reach out to us. We look forward to your
        participation and meaningful discussions during the session.
      </h3>
      <div class="cta-contact-person1">
        <img class="contact-image-icon1" alt="" src="assets/public/contact-image@1x.jpg" />

        <h3 class="contact-name1" style="color:#606060!important;font-weight: 400!important;font-size: 24px;">Beth C.
          Greer</h3>
        <h4 class="contact-email1">beth@carebusinessadvisor.com</h4>
      </div>
    </div>
  </footer>
</div>

<!-- Fouth Event Start -->
<div id="fourth" class="th1" *ngIf="eventData.id=='a431df9c-b463-4374-a7da-68f2a7ec5663'">
  <section class="video-section1">
    <div class="video-description-container1  video_details_dv">
      <div class="session-date-time">
        <div class="session-date">
          <!-- <h1 class="session-day" *ngIf="eventData  && eventData.day">{{eventData.day}}</h1> -->
          <h1 class="session-day">01</h1>
          <h1 class="session-month">Nov 23</h1>
        </div>
        <div class="session-time">
          <h1 class="session-time-text">10:00 am</h1>
        </div>
      </div>
      <div class="session-content-parent">
        <div class="session-content">
          <h1 class="session-day">The 5S System for Compliance Clarity</h1>
          <h2 class="session-description">
            Making compliance stress free
          </h2>
        </div>
        <div class="host-card1">
          <img class="host-image-icon1" alt="" src="assets/public/host-image1@2x.png" />

          <div class="host-information1">
            <h1 class="host-name1">Anand Chaturvedi</h1>
            <h3 class="host-designation1">CEO, Caryfy AI</h3>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="michelle-story blog-michelle-story">
    <div class="michelle-story-container">
      <div class="story-content" style="gap: var(--gap-base);padding-right: 20px;">
        <div class="host-card">
          <img class="host-image-icon" alt="" src="assets/public/host-image1@2x.png" />

          <div class="host-information user_detailprofile_dv">
            <h1 class="custom-host-name clr-user-host-name">
              <a class="user_link_dv anand-chaturvedi ">Anand Chaturvedi</a>
              <span style="font-weight: 400!important;"> in </span>
              <a href="success-summit" class="user_link_dv anand-chaturvedi clr-user-text anchor-link">Success Summit 2023</a>
              - <span style="font-weight: 400!important;">Nov 01, 2023</span>
            </h1>
          </div>
          <div></div>
          <b class="custom-host-name">5 min read</b>
        </div>
        <div class="story-description-parent ">
          <a href="live-event/5s-for-compliance/stress-free-compliance" style="text-decoration: none!important;">
            <b class="story-description">How I'm Empowering Care Providers to Tame Their Paperwork
              Beast</b>
          </a>

        </div>
        <div class="story-description1 cutom_story-description-parent">
          <p> In this article, the author discusses the overwhelming paperwork
            crisis faced by care providers and presents the DREAM
            methodology as a solution. The DREAM methodology involves steps
            like digitizing records, streamlining workflows, and automating
            repetitive tasks. Real-world results from providers who
            implemented DREAM show increased efficiency and a return of the
            joy of caregiving. The article offers three practical tips for
            those looking to start their own DREAM journey and overcome the
            paperwork burden in the care industry.</p>
        </div>
        <div class="story-description-wrapper" style="background-color:transparent!important;">
          <a href="live-event/5s-for-compliance/stress-free-compliance" class="read-more custom_read-more"
            style="border-radius: 5px;">Read More</a>
        </div>

        <div class="frame-parent">
          <div class="tag-text-wrapper">
            <div class="tag-text">#digital-marketing</div>
          </div>
          <div class="tag-text-wrapper">
            <div class="tag-text">#social-media</div>
          </div>
          <div class="tag-text-wrapper">
            <div class="tag-text">#digital-tools</div>
          </div>
          <div class="tag-text-wrapper">
            <div class="tag-text">#AI-generated-process</div>
          </div>
        </div>
      </div>
      <img class="story-image-icon " alt="How I'm Empowering Care Providers to Tame Their Paperwork
      Beast" src="assets/public/4th_event_image_1.png" style="height: inherit;" />
    </div>
  </section>
  <section class="michelle-story custom-michelle-story">
    <div class="michelle-story-container">
      <img class="story-image-icon" alt="" src="assets/public/4th_event_image_2.png" />

      <div class="story-content">
        <div class="story-description">
          <p class="its-like-im-running-on-a-tre">
            Staying compliant with complex regulations is a constant stressor for home and community care
            agencies. But relief is possible with the revolutionary 5S methodology for simplified compliance
            management. Join renowned expert Anand Chaturvedi as he reveals his proven framework to establish
            complete clarity, consistency, and confidence in your compliance protocols. Welcome to the virtual
            event, "The 5S System for Compliance Clarity".
          </p>

        </div>
      </div>
    </div>
  </section>

  <section class="different-tomorrow custom-different-tomorrow">
    <div class="michelle-story-container">
      <div class="tomorrow-content">
        <h2 class="session-day">What You'll Discover:</h2>
        <div class="story-description">
          <p>Here's what you'll learn in this exclusive training:</p>
          <ul>
            <li>The 3 biggest compliance pitfalls and how the 5S system helps you avoid them
            </li>
            <li>How to implement the 5S pillars of Simplify, Structure, Signatures, Self-Audits, and Sort
            </li>
            <li>An inside look at a case study of a care provider that used this method to transform compliance
              overnight
            </li>
            <li>Anand's proven checklist and step-by-step instructions to turn compliance into a competitive edge
            </li>

          </ul>
          <p>With the 5S framework, you'll gain complete clarity, consistency, and confidence in compliance.
          </p>
        </div>
      </div>
      <img class="tomorrow-image-icon" alt="" src="assets/public/4th_event_image_3.png" />
    </div>
  </section>

  <section class="yout-pathway custom-yout-pathway">
    <div class="testimonial-stories-container">
      <div class="your-pathway-subheading-parent">

        <h2 class="your-pathway-heading">
          Who Should Attend:
        </h2>
      </div>
      <div class="pathways-item-list">
        <div class="pathways-item-card">
          <div class="pathways-card-icon">
            <img class="icon" alt="" src="assets/public/home-care-img.png" />
          </div>
          <div class="pathways-card-content">
            <b class="stories-text" style="font-weight: 700;">Home Care Agency Owners</b>
            <div class="stories-text">
              <!-- This isn't just theory. Walk away with practical steps you can implement immediately. -->
            </div>
          </div>
        </div>
        <div class="pathways-item-card">
          <div class="pathways-card-icon">
            <img class="icon" alt="" src="assets/public/nursing-home-img.png" />
          </div>
          <div class="pathways-card-content">
            <b class="stories-text" style="font-weight: 700;">Personal Care Homes and Residential Services Owners </b>
            <div class="stories-text">
              <!-- Benefit from the accumulated wisdom of industry leaders and pioneers. -->
            </div>
          </div>
        </div>
        <div class="pathways-item-card">
          <div class="pathways-card-icon">
            <img class="icon" alt="" src="assets/public/doctor-img.png" />
          </div>
          <div class="pathways-card-content">
            <b class="stories-text" style="font-weight: 700;">Adult Day Health Managers</b>
            <div class="stories-text">
              <!-- Connect with like-minded professionals, share challenges, and uncover solutions. -->
            </div>
          </div>
        </div>
        <div class="pathways-item-card">
          <div class="pathways-card-icon">
            <img class="icon" alt="" src="assets/public/accountant-img.png" />
          </div>
          <div class="pathways-card-content">
            <b class="stories-text" style="font-weight: 700;">Case Managers</b>
            <div class="stories-text">
              <!-- Connect with like-minded professionals, share challenges, and uncover solutions. -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="michelle-story custom-michelle-story">
    <div class="michelle-story-container">
      <img class="story-image-icon" alt="" src="assets/public/4th_event_image_4.png" />

      <div class="story-content">
        <h2 class="session-day">Case Study</h2>
        <div class="story-description">
          <p class="its-like-im-running-on-a-tre">
            Hear first-hand how agencies leveraged the 5S system to
            radically simplify compliance management.
          </p>

        </div>
      </div>
    </div>
  </section>
  <section class="testimonial-stories custom-testimonial-stories">
    <div class="testimonial-stories-container">
      <h2 class="testimonials-stories-title">
        Testimonials: Stories of Transformation
      </h2>
      <div class="testimonials-stories-items">
        <div class="stories-items">
          <img class="stories-items-child" alt="" src="assets/public/ellipse-3@2x.png" />

          <div class="stories-text">
            <p class="its-like-im-running-on-a-tre">

              <span>
                The SENSE methodology transformed my approach to care services. I've never felt more aligned with my
                mission.</span>
              <br>
              <b class="every-new-face">Medicaid Case Manager, GA</b>
            </p>
          </div>
        </div>
        <div class="stories-items">
          <img class="stories-items-child" alt="" src="assets/public/ellipse-5@2x.png" />

          <div class="stories-text">
            <span>
              This approach was a game-changer for me. The strategies are not just effective; they resonate with the
              heart of care services.</span>
            <br>
            <b class="every-new-face">Medicaid PSS provider, NC</b>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="next-step custom-next-step join-conversation-dv">
    <div class="testimonial-stories-container">
      <h2 class="take-the-next" style="color: #4D4D4D!important;">Join the Conversation</h2>

      <div class="be-a-part-container" style="color: #4D4D4D!important;">
        Stay updated, engage in discussions, and be part of a community passionate about transforming care services.
      </div>

      <div class="social-btn-blg">
        <a href="https://www.facebook.com/Caryfy/" style="text-decoration: none;" class="" style="margin: 5px;">
          <img class="logo-caryfyai-icon" alt="" style="width: 50px!important;" src="assets/public/facebook.png" /></a>


        <a href="https://www.linkedin.com/company/caryfy/" style="text-decoration: none;" class="" style="margin: 5px;">
          <img class="logo-caryfyai-icon" alt="" style="width: 50px!important;" src="assets/public/linkedin.png" /></a>
      </div>
    </div>
  </section>
  <section class="other-session1 custom-other-session-dv">
    <div class="other-session-container1">
      <h2 class="session-day">Our Sessions</h2>
      <div class="card-container1">
        <div class="session-card6">
          <div class="card-image-75">
            <img class="card-image-icon6" alt="" src="assets/public/card-image6@2x.png" />
          </div>
          <div class="card-description6">
            <div class="card-meta-info6">
              <div class="date6">
                <img class="icon-reply5" alt="" src="assets/public/date-icon4.svg" />

                <div class="comments3">October 11th,2023</div>
              </div>
              <div class="badges6">
                <div class="online6">
                  <div class="comments3">Online</div>
                </div>
                <div class="free6">
                  <div class="comments3">Free</div>
                </div>
              </div>
            </div>
            <div class="card-title-group6">
              <a class="cart-heading6"
                [href]="isUserLogin?liveURL+firstEventUUID:'live-event/solving-staffing-challenges'">Solving Staffing
                Challenges</a>
              <a class="cart-subheading6">Hire faster, train better, and retain right employees</a>
            </div>
            <div class="tags6">
              <div class="tag-text24">#staffing-challenges</div>
              <div class="tag-text24">#staffing-pitfalls</div>
              <div class="tag-text24">#employees</div>
              <div class="tag-text24">#staffing-issues</div>
            </div>
          </div>
        </div>
        <div class="session-card6">
          <div class="card-image-75">
            <img class="card-image-icon6" alt="" src="assets/public/card-image6@2x.png" />
          </div>
          <div class="card-description6">
            <div class="card-meta-info6">
              <div class="date6">
                <img class="icon-reply5" alt="" src="assets/public/date-icon5.svg" />

                <div class="comments3">October 18th,2023</div>
              </div>
              <div class="badges6">
                <div class="online6">
                  <div class="comments3">Online</div>
                </div>
                <div class="free6">
                  <div class="comments3">Free</div>
                </div>
              </div>
            </div>
            <div class="card-title-group6">
              <a class="cart-heading6" [href]="isUserLogin?liveURL+secondEventUUID:'live-event/finding-growth'">Finding
                Growth</a>
              <a class="cart-subheading6">How to grow by leveraging digital marketing for
                referrals</a>
            </div>
            <div class="tags6">
              <div class="tag-text24">#digital-marketing</div>
              <div class="tag-text24">#social-media</div>
              <div class="tag-text24">#digital-tools</div>
              <div class="tag-text24">#AI-generated-process</div>
            </div>
          </div>
        </div>
        <div class="session-card6">
          <div class="card-image-75">
            <img class="card-image-icon6" alt="" src="assets/public/card-image6@2x.png" />
          </div>
          <div class="card-description6">
            <div class="card-meta-info6">
              <div class="date6">
                <img class="icon-reply5" alt="" src="assets/public/date-icon6.svg" />

                <div class="comments3">October 25th,2023</div>
              </div>
              <div class="badges6">
                <div class="online6">
                  <div class="comments3">Online</div>
                </div>
                <div class="free6">
                  <div class="comments3">Free</div>
                </div>
              </div>
            </div>
            <div class="card-title-group6">
              <a class="cart-heading6"
                [href]="isUserLogin?liveURL+thirdEventUUID:'live-event/blueprint-for-serenity'">Blueprint for
                Serenity</a>
              <a class="cart-subheading6">The DREAM Method to Tame Paperwork</a>
            </div>
            <div class="tags6">
              <div class="tag-text24">#easy-process</div>
              <div class="tag-text24">#paperless</div>
              <div class="tag-text24">#detail-reports</div>
              <div class="tag-text24">#auto-generation</div>
            </div>
          </div>
        </div>
        <div class="session-card6">
          <div class="card-image-75">
            <img class="card-image-icon6" alt="" src="assets/public/card-image@1x.jpg" />
          </div>
          <div class="card-description6">
            <div class="card-meta-info6">
              <div class="date6">
                <img class="icon-reply5" alt="" src="assets/public/date-icon4.svg" />

                <div class="comments3">November 1st,2023</div>
              </div>
              <div class="badges6">
                <div class="online6">
                  <div class="comments3">Online</div>
                </div>
                <div class="free6">
                  <div class="comments3">Free</div>
                </div>
              </div>
            </div>
            <div class="card-title-group6">
              <a class="cart-heading6" [href]="isUserLogin?liveURL+fourthEventUUID:'live-event/5s-for-compliance'">5S
                for Compliance</a>
              <a class="cart-subheading6">
                <p class="making-compliance-stress1">
                  Making compliance stress free
                </p>
              </a>
            </div>
            <div class="tags6">
              <div class="tag-text24">#compliance</div>
              <div class="tag-text24">#management</div>
              <div class="tag-text24">#stress-free</div>
              <div class="tag-text24">#simplify-documentation</div>
            </div>
          </div>
        </div>
        <div class="session-card6">
          <div class="card-image-75">
            <img class="card-image-icon6" alt="" src="assets/public/card-image6@2x.png" />
          </div>
          <div class="card-description6">
            <div class="card-meta-info6">
              <div class="date6">
                <img class="icon-reply5" alt="" src="assets/public/date-icon6.svg" />

                <div class="comments3">November 8th,2023</div>
              </div>
              <div class="badges6">
                <div class="online6">
                  <div class="comments3">Online</div>
                </div>
                <div class="free6">
                  <div class="comments3">Free</div>
                </div>
              </div>
            </div>
            <div class="card-title-group6">
              <a class="cart-heading6"
                [href]="isUserLogin?liveURL+fifthEventUUID:'live-event/consolidating-the-chaos'">Consolidating the
                Chaos</a>
              <a class="cart-subheading6">How a Unified Care Ecosystem Transforms Productivity</a>
            </div>
            <div class="tags6">
              <div class="tag-text24">#implementation</div>
              <div class="tag-text24">#stress-free</div>
              <div class="tag-text24">#management</div>
              <div class="tag-text24">#improvement</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="testimonials1 custom-testimonials-blg">
    <div class="testimonial-container1">
      <div class="column2">
        <h2 class="session-day">WONDERING IF THIS REALLY WORKS?</h2>
        <h2 class="testimonial-description1">
          Check out how many medicaid entrepreneurs are mastering
          operational efficiency using this SIMPLE SYSTEM
        </h2>
      </div>
      <div class="column3">
        <div class="testimonial-item2">
          <h4 class="testimonial-quote2">
            "These sessions are informative, good take-aways and concise
            enough for owners and managers to take full advantage of the
            resources."
          </h4>
          <b class="testimonial-author2">– M Sizemore, Augusta, GA</b>
        </div>
        <div class="testimonial-item2">
          <h4 class="testimonial-quote2">
            "Truly appreciate you creating this summit!"
          </h4>
          <b class="testimonial-author2">– O Cisse, Atlanta, GA</b>
        </div>
      </div>
    </div>
  </section>
  <footer class="cta-section1 custom-ta-section-dv">
    <div class="cta-container1">
      <h2 class="cta-title1">Have any question?</h2>
      <h3 class="cta-description1">
        If you have any questions or require further information, please
        don't hesitate to reach out to us. We look forward to your
        participation and meaningful discussions during the session.
      </h3>
      <div class="cta-contact-person1">
        <img class="contact-image-icon1" alt="" src="assets/public/contact-image@1x.jpg" />

        <h3 class="contact-name1" style="color:#606060!important;font-weight: 400!important;font-size: 24px;">Beth C.
          Greer</h3>
        <h4 class="contact-email1">beth@carebusinessadvisor.com</h4>
      </div>
    </div>
  </footer>
</div>

<!-- Fifth event start -->
<div id="fifth" class="th1" *ngIf="eventData.id=='ff182c2c-a413-444c-a195-138e22ea9d75'">
  <section class="video-section1">
    <div class="video-description-container1  video_details_dv">
      <div class="session-date-time">
        <div class="session-date">
          <!-- <h1 class="session-day" *ngIf="eventData  && eventData.day">{{eventData.day}}</h1> -->
          <h1 class="session-day">08</h1>
          <h1 class="session-month">Nov 23</h1>
        </div>
        <div class="session-time">
          <h1 class="session-time-text">10:00 am</h1>
        </div>
      </div>
      <div class="session-content-parent">
        <div class="session-content">
          <h1 class="session-day">Consolidating the Chaos</h1>
          <h2 class="session-description">
            How a Unified Care Ecosystem Transforms Productivity
          </h2>
        </div>
        <div class="host-card1">
          <img class="host-image-icon1" alt="" src="assets/public/host-image1@2x.png" />

          <div class="host-information1">
            <h1 class="host-name1">Anand Chaturvedi</h1>
            <h3 class="host-designation1">CEO, Caryfy AI</h3>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="michelle-story blog-michelle-story">
    <div class="michelle-story-container">
      <div class="story-content" style="gap: var(--gap-base);padding-right: 20px;">
        <div class="host-card">
          <img class="host-image-icon" alt="" src="assets/public/host-image1@2x.png" />

          <div class="host-information user_detailprofile_dv">
            <h1 class="custom-host-name  clr-user-host-name">
              <a class="user_link_dv anand-chaturvedi">Anand Chaturvedi</a>
              <span style="font-weight: 400!important;"> in </span>
              <a href="success-summit" class="user_link_dv anand-chaturvedi clr-user-text anchor-link">Success Summit 2023</a>
              - <span style="font-weight: 400!important;">Nov 08, 2023</span>
            </h1>
          </div>
          <div></div>
          <b class="custom-host-name">3 min read</b>
        </div>
        <div class="story-description-parent">
          <a href="live-event/consolidating-the-chaos/care-confusion" style="text-decoration: none!important;">
            <b class="story-description">The Hidden Crisis Destroying Medicaid Care Providers’ Productivity: Inside the
              World of Care Confusion</b>
          </a>
        </div>

        <div class="story-description1 cutom_story-description-parent">
          Medicaid care providers face an existential threat from 'Care Confusion' - the chaos of disjointed systems and
          processes. It's a crisis causing inefficiencies, errors, and ballooning costs. However, there's hope with
          innovative solutions like the 'Care Relationship Network,' a unified ecosystem that promises clarity,
          continuity, and efficient care delivery. This network offers a path to a more streamlined, sustainable
          healthcare future, saving providers from the brink of catastrophe.
        </div>
        <div class="story-description-wrapper" style="background-color:transparent!important;">
          <a href="live-event/consolidating-the-chaos/care-confusion" class="read-more custom_read-more"
            style="border-radius: 5px;">Read More</a>
        </div>
        <div class="frame-parent">
          <div class="tag-text-wrapper">
            <div class="tag-text">#digital-marketing</div>
          </div>
          <div class="tag-text-wrapper">
            <div class="tag-text">#social-media</div>
          </div>
          <div class="tag-text-wrapper">
            <div class="tag-text">#digital-tools</div>
          </div>
          <div class="tag-text-wrapper">
            <div class="tag-text">#AI-generated-process</div>
          </div>
        </div>
      </div>
      <img class="story-image-icon" alt="The Hidden Crisis Destroying Medicaid Care Providers’ Productivity: Inside the
      World of Care Confusion" src="assets/public/5th_event_image_1.png" style="height: inherit;" />
    </div>
  </section>
  <section class="michelle-story blog-michelle-story">
    <div class="michelle-story-container">
      <div class="story-content">
        <div class="host-card">
          <img class="host-image-icon" alt="" src="assets/public/host-image1@2x.png" />

          <div class="host-information user_detailprofile_dv">
            <h1 class="custom-host-name ">
              <a class="user_link_dv anand-chaturvedi">Anand Chaturvedi</a>
              in
              <a href="success-summit" class="use r_link_dv anand-chaturvedi clr-user-tex anchor-link">Success Summit 2023</a>
              - Nov 08, 2023
            </h1>
          </div>
          <div></div>
          <b class="custom-host-name">2 min read</b>
        </div>
        <a href="live-event/consolidating-the-chaos/care-relationship-network" style="text-decoration: none!important;">
          <b class="story-description">How to Finally Eliminate Care Confusion and Unlock Productivity</b>
        </a>
        <div class="story-description1">
          In the fight against Care Confusion, a Care Relationship Network offers the key to synchronized success. Learn
          how to implement this solution with six strategic steps: integrate data, train staff, start with a pilot
          program, monitor analytics, maximize automated workflows, and ensure tools deliver value. With a focus on
          careful planning and change management, you can permanently eliminate care confusion and usher in a new era of
          healthcare efficiency.
        </div>
        <div class="story-description-wrapper" style="background-color:transparent!important;">
          <a href="live-event/consolidating-the-chaos/care-relationship-network" class="read-more custom_read-more"
            style="border-radius: 5px;">Read More</a>
        </div>
        <div class="frame-parent">
          <div class="tag-text-wrapper">
            <div class="tag-text">#digital-marketing</div>
          </div>
          <div class="tag-text-wrapper">
            <div class="tag-text">#social-media</div>
          </div>
          <div class="tag-text-wrapper">
            <div class="tag-text">#digital-tools</div>
          </div>
          <div class="tag-text-wrapper">
            <div class="tag-text">#AI-generated-process</div>
          </div>
        </div>
      </div>
      <img class="story-image-icon" alt="How to Finally Eliminate Care Confusion and Unlock Productivity" src="assets/public/5th_event_image_2.png" style="height: inherit;" />
    </div>
  </section>
  <section class="michelle-story custom-michelle-story">
    <div class="michelle-story-container">
      <img class="story-image-icon" alt="" src="assets/public/5th_event_image_3.png" />

      <div class="story-content">
        <div class="story-description">
          <p class="its-like-im-running-on-a-tre">
            Do you feel like you're drowning in disjointed systems and scattered processes that lead
            to headaches, redundancies, and gaps in patient care? Are you desperate for solutions
            that streamline your care organization rather than cause more chaos?
          </p>
          <p class="its-like-im-running-on-a-tre">
            You're not alone. Over 93% of Medicaid care providers face the epidemic of <b>Care
              Confusion</b> from fragmented processes and technologies that don't "talk" to each
            other. This forces already overwhelmed staff to manually piece together data,
            workflows, and communication across misaligned silos.
          </p>
          <p class="its-like-im-running-on-a-tre">The result? Ballooning costs, compliance failures, errors and
            inefficiencies that steal
            you time and money while putting your care quality and financial stability at risk.
          </p>
          <p class="its-like-im-running-on-a-tre">But there is hope for a new era of clarity and continuity!
          </p>
        </div>
      </div>
    </div>
  </section>

  <section class="different-tomorrow custom-different-tomorrow">
    <div class="michelle-story-container">
      <div class="tomorrow-content">
        <h2 class="session-day">What You'll Discover:</h2>
        <div class="story-description">

          <ul>
            <li>The innovative Care Relationship Network approach proven
              to seamlessly connect disjointed systems and workflows onto
              one streamlined platform
            </li>
            <li>
              Real-world examples of providers gaining back 10+ hours per week previously wasted on redundancies and
              rework</li>
            <li>
              How a holistic view of data, scheduling, communication, and compliance eliminates roles through automation
            </li>
            <li>
              Compliance, financial, and care benefits of consolidated technologies and protocols
            </li>
            <li>
              Steps to implement an integrated ecosystem at your own organization for sustainable success
            </li>

          </ul>

        </div>
      </div>
      <img class="tomorrow-image-icon" alt="" src="assets/public/5th_event_image_4.png" />
    </div>
  </section>

  <section class="yout-pathway custom-yout-pathway">
    <div class="testimonial-stories-container">
      <div class="your-pathway-subheading-parent">

        <h2 class="your-pathway-heading">
          Who Should Attend:
        </h2>
      </div>
      <div class="pathways-item-list">
        <div class="pathways-item-card">
          <div class="pathways-card-icon">
            <img class="icon" alt="" src="assets/public/home-care-img.png" />
          </div>
          <div class="pathways-card-content">
            <b class="stories-text" style="font-weight: 700;">Home Care Agency Owners</b>
            <div class="stories-text">
              <!-- This isn't just theory. Walk away with practical steps you can implement immediately. -->
            </div>
          </div>
        </div>
        <div class="pathways-item-card">
          <div class="pathways-card-icon">
            <img class="icon" alt="" src="assets/public/nursing-home-img.png" />
          </div>
          <div class="pathways-card-content">
            <b class="stories-text" style="font-weight: 700;">Personal Care Homes and Residential Services Owners </b>
            <div class="stories-text">
              <!-- Benefit from the accumulated wisdom of industry leaders and pioneers. -->
            </div>
          </div>
        </div>
        <div class="pathways-item-card">
          <div class="pathways-card-icon">
            <img class="icon" alt="" src="assets/public/doctor-img.png" />
          </div>
          <div class="pathways-card-content">
            <b class="stories-text" style="font-weight: 700;">Adult Day Health Managers</b>
            <div class="stories-text">
              <!-- Connect with like-minded professionals, share challenges, and uncover solutions. -->
            </div>
          </div>
        </div>
        <div class="pathways-item-card">
          <div class="pathways-card-icon">
            <img class="icon" alt="" src="assets/public/accountant-img.png" />
          </div>
          <div class="pathways-card-content">
            <b class="stories-text" style="font-weight: 700;">Case Managers</b>
            <div class="stories-text">
              <!-- Connect with like-minded professionals, share challenges, and uncover solutions. -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="michelle-story custom-michelle-story">
    <div class="michelle-story-container">
      <img class="story-image-icon" alt="" src="assets/public/5th_event_image_5.png" />

      <div class="story-content">
        <h2 class="session-day">Case Study</h2>
        <div class="story-description">
          <p class="its-like-im-running-on-a-tre">
            The time for change is now. Stop drowning in chaos and reclaim your ability to
            efficiently provide excellent coordinated care!
          </p>
        </div>
      </div>
    </div>
  </section>
  <section class="testimonial-stories custom-testimonial-stories">
    <div class="testimonial-stories-container">
      <h2 class="testimonials-stories-title">
        Testimonials: Stories of Transformation
      </h2>
      <div class="testimonials-stories-items">
        <div class="stories-items">
          <img class="stories-items-child" alt="" src="assets/public/ellipse-3@2x.png" />

          <div class="stories-text">
            <p class="its-like-im-running-on-a-tre">

              <span>
                "This training finally gave me the tools and knowledge I needed to lift my organization
                out of care confusion. My staff is happier and patients are receiving better coordinated
                care than ever before possible. I wish I had done this years ago!"</span>
              <br>
              <b class="every-new-face">Jason K., Home Health Director</b>
            </p>
          </div>
        </div>
        <div class="stories-items">
          <img class="stories-items-child" alt="" src="assets/public/ellipse-5@2x.png" />

          <div class="stories-text">
            <span>
              "Absolutely eye-opening. I didn't realize how much our current fragmented systems
              were hampering productivity and care continuity until seeing the possibilities of
              a unified platform approach. This is our facility’s future</span>
            <br>
            <b class="every-new-face">Sam W.,Personal Care Home Administrator</b>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="next-step custom-next-step join-conversation-dv">
    <div class="testimonial-stories-container">
      <h2 class="take-the-next" style="color: #4D4D4D!important;">Join the Conversation</h2>

      <div class="be-a-part-container" style="color: #4D4D4D!important;">
        Stay updated, engage in discussions, and be part of a community passionate about transforming care services.
      </div>

      <div class="social-btn-blg">
        <a href="https://www.facebook.com/Caryfy/" style="text-decoration: none;" class="" style="margin: 5px;">
          <img class="logo-caryfyai-icon" alt="" style="width: 50px!important;" src="assets/public/facebook.png" /></a>


        <a href="https://www.linkedin.com/company/caryfy/" style="text-decoration: none;" class="" style="margin: 5px;">
          <img class="logo-caryfyai-icon" alt="" style="width: 50px!important;" src="assets/public/linkedin.png" /></a>
      </div>
    </div>
  </section>
  <section class="other-session1 custom-other-session-dv">
    <div class="other-session-container1">
      <h2 class="session-day">Our Sessions</h2>
      <div class="card-container1">
        <div class="session-card6">
          <div class="card-image-75">
            <img class="card-image-icon6" alt="" src="assets/public/card-image6@2x.png" />
          </div>
          <div class="card-description6">
            <div class="card-meta-info6">
              <div class="date6">
                <img class="icon-reply5" alt="" src="assets/public/date-icon4.svg" />

                <div class="comments3">October 11th,2023</div>
              </div>
              <div class="badges6">
                <div class="online6">
                  <div class="comments3">Online</div>
                </div>
                <div class="free6">
                  <div class="comments3">Free</div>
                </div>
              </div>
            </div>
            <div class="card-title-group6">
              <a class="cart-heading6"
                [href]="isUserLogin?liveURL+firstEventUUID:'live-event/solving-staffing-challenges'">Solving Staffing
                Challenges</a>
              <a class="cart-subheading6">Hire faster, train better, and retain right employees</a>
            </div>
            <div class="tags6">
              <div class="tag-text24">#staffing-challenges</div>
              <div class="tag-text24">#staffing-pitfalls</div>
              <div class="tag-text24">#employees</div>
              <div class="tag-text24">#staffing-issues</div>
            </div>
          </div>
        </div>
        <div class="session-card6">
          <div class="card-image-75">
            <img class="card-image-icon6" alt="" src="assets/public/card-image6@2x.png" />
          </div>
          <div class="card-description6">
            <div class="card-meta-info6">
              <div class="date6">
                <img class="icon-reply5" alt="" src="assets/public/date-icon5.svg" />

                <div class="comments3">October 18th,2023</div>
              </div>
              <div class="badges6">
                <div class="online6">
                  <div class="comments3">Online</div>
                </div>
                <div class="free6">
                  <div class="comments3">Free</div>
                </div>
              </div>
            </div>
            <div class="card-title-group6">
              <a class="cart-heading6" [href]="isUserLogin?liveURL+secondEventUUID:'live-event/finding-growth'">Finding
                Growth</a>
              <a class="cart-subheading6">How to grow by leveraging digital marketing for
                referrals</a>
            </div>
            <div class="tags6">
              <div class="tag-text24">#digital-marketing</div>
              <div class="tag-text24">#social-media</div>
              <div class="tag-text24">#digital-tools</div>
              <div class="tag-text24">#AI-generated-process</div>
            </div>
          </div>
        </div>
        <div class="session-card6">
          <div class="card-image-75">
            <img class="card-image-icon6" alt="" src="assets/public/card-image6@2x.png" />
          </div>
          <div class="card-description6">
            <div class="card-meta-info6">
              <div class="date6">
                <img class="icon-reply5" alt="" src="assets/public/date-icon6.svg" />

                <div class="comments3">October 25th,2023</div>
              </div>
              <div class="badges6">
                <div class="online6">
                  <div class="comments3">Online</div>
                </div>
                <div class="free6">
                  <div class="comments3">Free</div>
                </div>
              </div>
            </div>
            <div class="card-title-group6">
              <a class="cart-heading6"
                [href]="isUserLogin?liveURL+thirdEventUUID:'live-event/blueprint-for-serenity'">Blueprint for
                Serenity</a>
              <a class="cart-subheading6">The DREAM Method to Tame Paperwork</a>
            </div>
            <div class="tags6">
              <div class="tag-text24">#easy-process</div>
              <div class="tag-text24">#paperless</div>
              <div class="tag-text24">#detail-reports</div>
              <div class="tag-text24">#auto-generation</div>
            </div>
          </div>
        </div>
        <div class="session-card6">
          <div class="card-image-75">
            <img class="card-image-icon6" alt="" src="assets/public/card-image@1x.jpg" />
          </div>
          <div class="card-description6">
            <div class="card-meta-info6">
              <div class="date6">
                <img class="icon-reply5" alt="" src="assets/public/date-icon4.svg" />

                <div class="comments3">November 1st,2023</div>
              </div>
              <div class="badges6">
                <div class="online6">
                  <div class="comments3">Online</div>
                </div>
                <div class="free6">
                  <div class="comments3">Free</div>
                </div>
              </div>
            </div>
            <div class="card-title-group6">
              <a class="cart-heading6" [href]="isUserLogin?liveURL+fourthEventUUID:'live-event/5s-for-compliance'">5S
                for Compliance</a>
              <a class="cart-subheading6">
                <p class="making-compliance-stress1">
                  Making compliance stress free
                </p>
              </a>
            </div>
            <div class="tags6">
              <div class="tag-text24">#compliance</div>
              <div class="tag-text24">#management</div>
              <div class="tag-text24">#stress-free</div>
              <div class="tag-text24">#simplify-documentation</div>
            </div>
          </div>
        </div>
        <div class="session-card6">
          <div class="card-image-75">
            <img class="card-image-icon6" alt="" src="assets/public/card-image6@2x.png" />
          </div>
          <div class="card-description6">
            <div class="card-meta-info6">
              <div class="date6">
                <img class="icon-reply5" alt="" src="assets/public/date-icon6.svg" />

                <div class="comments3">November 8th,2023</div>
              </div>
              <div class="badges6">
                <div class="online6">
                  <div class="comments3">Online</div>
                </div>
                <div class="free6">
                  <div class="comments3">Free</div>
                </div>
              </div>
            </div>
            <div class="card-title-group6">
              <a class="cart-heading6"
                [href]="isUserLogin?liveURL+fifthEventUUID:'live-event/consolidating-the-chaos'">Consolidating the
                Chaos</a>
              <a class="cart-subheading6">How a Unified Care Ecosystem Transforms Productivity</a>
            </div>
            <div class="tags6">
              <div class="tag-text24">#implementation</div>
              <div class="tag-text24">#stress-free</div>
              <div class="tag-text24">#management</div>
              <div class="tag-text24">#improvement</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="testimonials1 custom-testimonials-blg">
    <div class="testimonial-container1">
      <div class="column2">
        <h2 class="session-day">WONDERING IF THIS REALLY WORKS?</h2>
        <h2 class="testimonial-description1">
          Check out how many medicaid entrepreneurs are mastering
          operational efficiency using this SIMPLE SYSTEM
        </h2>
      </div>
      <div class="column3">
        <div class="testimonial-item2">
          <h4 class="testimonial-quote2">
            "These sessions are informative, good take-aways and concise
            enough for owners and managers to take full advantage of the
            resources."
          </h4>
          <b class="testimonial-author2">– M Sizemore, Augusta, GA</b>
        </div>
        <div class="testimonial-item2">
          <h4 class="testimonial-quote2">
            "Truly appreciate you creating this summit!"
          </h4>
          <b class="testimonial-author2">– O Cisse, Atlanta, GA</b>
        </div>
      </div>
    </div>
  </section>
  <footer class="cta-section1 custom-ta-section-dv">
    <div class="cta-container1">
      <h2 class="cta-title1">Have any question?</h2>
      <h3 class="cta-description1">
        If you have any questions or require further information, please
        don't hesitate to reach out to us. We look forward to your
        participation and meaningful discussions during the session.
      </h3>
      <div class="cta-contact-person1">
        <img class="contact-image-icon1" alt="" src="assets/public/contact-image@1x.jpg" />

        <h3 class="contact-name1" style="color:#606060!important;font-weight: 400!important;font-size: 24px;">Beth C.
          Greer</h3>
        <h4 class="contact-email1">beth@carebusinessadvisor.com</h4>
      </div>
    </div>
  </footer>
</div>