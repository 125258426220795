<div class="session-page-user-reaches-be">
    <header class="header custom-header-dv">
        <div class="logo-wrap success-summit-logo">
            <img class="logo-event-icon" alt="" src="assets/public/logo-event.svg" />
        </div>
        <div class="logo-wrap caryfy_logo">
            <div class="inner_caryfy_logo">
              <!-- <img class="presented-by-icon" alt="" src="assets/public/presented-by.svg" /> -->
      
              <img class="logo-caryfyai-icon" alt="" src="assets/public/caryfysponsored.png" />
            </div>
          </div>
    
        <hr class="border-bottom-dv">
    </header>
    <div class="pre-session pre-session-dv thankyou_blg_main">
        <div class="thankyou_blg_dv">
            <div class="motivation">
                <div class="its-wonderful-to">
                    <div>
                        <h1>Thank you for your participation!</h1>
                        <h6>We hope you enjoyed our session</h6>
                        <!-- <h3>Staffing Challenges</h3> -->
                        <div class="beth_img_dv">
                            <img class="" alt="" src="assets/public/unnamed-21.png" />
                            <p>Sincerely,</p>
                            <p>Beth C. Greer</p>
                            <a href="mailto:beth@carebusinessadvisor.com">beth@carebusinessadvisor.com</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
    </div>
</div>


