import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { throwError as observableThrowError, throwError } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";

const httpOptions = {
  headers: new HttpHeaders({ 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('token')}` })
};

@Injectable({
  providedIn: 'root'
})

export abstract class RestService {
  private headerCollection: HttpHeaders;
  public baseUrl = "";

  protected constructor(
    protected httpClient: HttpClient,
    baseUrl?: string,
    header?: HttpHeaders
  ) {
    if (baseUrl) this.baseUrl = baseUrl;
    this.headerCollection = new HttpHeaders();
    if (header) this.headerCollection = header;
  }

  protected GET<T>(path: string): Promise<T | undefined> {
    const url = this.url(path);
    return this.httpClient
      .get<T>(url, { headers: httpOptions.headers })
      .pipe(
        tap((res: any) => {
          console.log("-> Result", res);
        }),
        catchError((error: any) => {
          return throwError(() => error);
        })
      )
      .toPromise();
  }

  protected GETRESPONSE<T>(path: string): Promise<HttpResponse<T> | undefined> {
    const url = this.url(path);
    return this.httpClient
      .get<T>(url, { headers: httpOptions.headers, observe: "response" })
      .pipe(
        map((res: any) => {
          console.log("-> Result", res);
          console.log("-> Headers", res.headers);
          return res;
        }),
        catchError((error: any) => {
          return observableThrowError(error);
        })
      )
      .toPromise();
  }

  protected POST<T>(path: string, request: any): Promise<T | undefined> {
    const url = this.url(path);
    const body = JSON.stringify(request);
    return this.httpClient
      .post<T>(url, body, { headers: httpOptions.headers },)
      .pipe(
        tap((res: any) => {
          console.log("-> Result", res);
        }),
        catchError((error: any) => {
          return observableThrowError(error);
        })
      )
      .toPromise();
  }

  protected PUT<T>(path: string, request: any): Promise<T | undefined> {
    const url = this.url(path);
    const body = JSON.stringify(request);
    return this.httpClient
      .put<T>(url, body, { headers: httpOptions.headers })
      .pipe(
        tap((res: any) => {
          console.log("-> Result", res);
        }),
        catchError((error: any) => {
          return observableThrowError(error);
        })
      )
      .toPromise();
  }

  protected DELETE<T>(path: string, request: any): Promise<T | undefined> {
    const url = this.url(path);
    return this.httpClient
      .request<T>("delete", url, {
        body: request,
        headers: httpOptions.headers,
      })
      .pipe(
        map((res: any) => {
          console.log("-> Result", res);
          return res;
        }),
        catchError((error: any) => {
          return observableThrowError(error);
        })
      )
      .toPromise();
  }

  protected url(path: string): string {
    const absoluteSecureCheck = /^https?:\/\//i;
    const absoluteUnSecureCheck = /^http?:\/\//i;

    let url = '';
    if (absoluteSecureCheck.test(path) || absoluteUnSecureCheck.test(path)) {
      url = path
    }
    else if (absoluteSecureCheck.test(this.baseUrl + path) || absoluteUnSecureCheck.test(this.baseUrl + path)) {
      url = this.baseUrl + path;
    }
    else {
      url = this.currentOrigin() + this.baseUrl + path;
    }

    return url;
  }

  protected currentOrigin(): string {
    return location.origin;
  }
}