export const environment = {
  production: false,
  // apiEndpoint: 'https://localhost:44303/api/',
  // dotNetApiEndpoint: 'https://localhost:44303/api/',
  apiEndpoint: 'https://eventkindprodnewapi.azurewebsites.net/api/',
  dotNetApiEndpoint: 'https://eventkindprodnewapi.azurewebsites.net/api/',
  messageHubUrl: 'https://eventkindprodnewapi.azurewebsites.net//messagehub',
  appInsights: {
    instrumentationKey: '4344d663-38b3-4332-bafc-79482a96b4e8'
  }
};

