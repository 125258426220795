
export class Message  {
    sessionUUID?: string;
    eventBreakoutUUID?: string;
    userUUID: string;
    firstName: string;
    lastName: string;
    commentText: string;
    type: string;
    createdDate: any;
    commentType?: string;
  toUserId: string;
  };

export interface BroadcastMessage {
  uuid: string;
  messageText: string;
};