<div [innerHTML]="html"></div>
<div class="event-15th-sfc-nc">
  <header class="section15">
    <div class="container12">
      <img class="brand-product-logos3" alt="" src="assets/images/container@2x.png" />
    </div>
  </header>
  <section class="section1 fontfamily north-banner">
    <div class="container">
      <img class="col-image-icon" alt="" src="assets/images/Col_Image.png" />

      <div class="col-text">
        <div class="h5-parent">
          <b class="h5">FREE MASTERCLASS</b>
          <h1 class="h1">From Care Wreck to Care Empowered:</h1>
        </div>
        <div class="h3">
          The One Thing You Can Do To Transform Your Medicaid Practice 
        </div>
        <button class="button" (click)="redirect()">
          <b class="watch-now">Watch Now</b>
        </button>
      </div>
    </div>
  </section>
  <section class="section2">
    <div class="container1">
      <b class="h2">Special event for Medicaid Home and Community-Based Services Waiver
        - HCBS Waiver in North Carolina</b>
      <div class="paragraphs">
        Hundreds of care providers are simplifying care coordination with
        this simple solution!
      </div>
      <button class="button1" (click)="redirect()">
        <b class="watch-now1">Watch Now</b>
      </button>
    </div>
  </section>
  <section class="section3">
    <div class="container">
      <div class="col-text1">
        <div class="h2-parent">
          <b class="h2">Join this workshop and discover...</b>
          <div class="paragraphs">
            <ul class="how-to-move-out-of-care-chaos">
              <li class="how-to-move">
                How to move out of care chaos and into the success you want
                and need.
              </li>
              <li class="how-to-move">
                Strategies to save time and make Medicaid compliance
                effortlessly streamlined.
              </li>
              <li>
                The secrets to improving your financial health and expanding
                your service offerings.
              </li>
            </ul>
          </div>
        </div>
        <button class="button2" (click)="redirect()">
          <b class="watch-now1">Watch Now</b>
        </button>
      </div>
      <img class="col-image-icon1" alt="" src="assets/images/col-image@2x.png" />
    </div>
  </section>
  <section class="section2">
    <div class="container3">
      <div class="h2-parent">
        <b class="h5">WONDERING IF THIS REALLY WORKS?</b>
        <div class="paragraphs">
          Check out how many care providers are mastering the overwhelming
          stress and chaos using this...
        </div>
      </div>
      <div class="person-image">
        <img class="otlperson-icon" alt="" src="assets/images/otlperson@2x.png" />
      </div>
      <div class="paragraphs3">
        <p class="implementing-these-tools">
          "Implementing these tools has saved us countless hours in
          documentation and allowed us to focus more on client care."
        </p>
        <p class="implementing-these-tools">
          <b>– Bernae Plechette</b>
        </p>
      </div>
    </div>
  </section>
  <section class="section5">
    <div class="container4">
      <div class="brand-product-logos-wrapper">
        <img class="brand-product-logos" alt="" src="assets/images/brand--product-logos@2x.png" />
      </div>
      <div class="copyright-2023">
        Copyright © 2023 caryfy.ai. All rights reserved
      </div>
    </div>
  </section>
</div>