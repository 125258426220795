import { Component } from '@angular/core';
import { DomSanitizer, Meta, SafeHtml, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-free-master-class',
  templateUrl: './free-master-class.component.html',
  styleUrls: ['./free-master-class.component.scss']
})
export class FreeMasterClassComponent {
  pageJsonld = {
    "@context": "https://schema.org",
    "@type": "Event",
    "name": "FROM CARE WRECK TO CARE EMPOWERED",
    "description": "SPECIAL EVENT FOR INDEPENDENT CARE WAIVER PROGRAM - ICWP CASE MANAGERS IN GEORGIA",
    "startDate": "2023-12-15T10:00-05:00",
    "eventStatus": "https://schema.org/EventScheduled",
    "eventAttendanceMode": "https://schema.org/OnlineEventAttendanceMode",
    "location": {
      "@type": "VirtualLocation",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "75 Fifth Street NW",
        "addressLocality": "Atlanta",
        "addressRegion": "GA",
        "postalCode": "30328",
        "addressCountry": "United States"
      },
      "url": "https://www.eventkind.com/sfc/georgia/free-master-class"
    },
    "performer": {
      "@type": "Person",
      "name": "Anand Chaturvedi"
    },
    "keywords": [
      "Medicaid",
      "Independent Care Waiver Program",
      "ICWP Case Managers",
      "Special Event",
      "Georgia",
      "Healthcare",
      "Training",
      "Collaboration",
      "Best Practices",
      "ICWP",
      "Independent Care Waiver Program",
      "case managers",
      "Georgia",
      "special event",
      "healthcare professionals",
      "care management",
      "disability services",
      "community support",
      "Georgia ICWP",
      "healthcare event",
      "case management training",
      "independent living",
      "disability care",
      "case management",
      "Medicaid case management"
    ]
  };
  html: SafeHtml;

  constructor(private router: Router,
    private sanitizer: DomSanitizer,
    private meta: Meta,
    private titleService: Title) {
    this.titleService.setTitle("Empowering ICWP Case Managers: Georgia's Journey from Care Wreck to Care Empowered");
    this.meta.removeTag('name=name');
    this.meta.removeTag('name=description');
    this.meta.removeTag('name=keywords');
    this.meta.addTag({ name: 'name', content: 'FROM CARE WRECK TO CARE EMPOWERED' });
    this.meta.addTag({ name: 'description', content: 'SPECIAL EVENT FOR INDEPENDENT CARE WAIVER PROGRAM - ICWP CASE MANAGERS IN GEORGIA' });
    this.meta.addTag({ name: 'keywords', content: 'Medicaid, Independent Care Waiver Program, ICWP Case Managers, Special Event, Georgia, Healthcare, Training, Collaboration, Best Practices, ICWP, Independent Care Waiver Program, case managers, Georgia, special event, healthcare professionals, care management, disability services, community support, Georgia ICWP, healthcare event, case management training, independent living, disability care, case management, Medicaid case management' });
  }

  ngOnInit() {
    this.html = this.getSafeHTML(this.pageJsonld);
  }

  redirect() {
    this.router.navigateByUrl("icwp/free-master-class/video")
  }

  getSafeHTML(jsonLD: { [key: string]: any }): SafeHtml {
    const json = jsonLD ? JSON.stringify(jsonLD, null, 2).replace(/<\/script>/g, '<\\/script>') : '';
    // escape / to prevent script tag in JSON
    const html = `<script type="application/ld+json">${json}</script>`;
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
