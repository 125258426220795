import { EventEmitter, Injectable } from '@angular/core';  
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';  
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environment';
import { BroadcastMessage, Message } from '../model/message';

@Injectable({
  providedIn: 'root'
})
export class SignalRChatService {  

  private messageHubUrl = environment.messageHubUrl;
  messageReceived = new EventEmitter<Message>();  
  messageReceivedBreakoutComment = new EventEmitter<Message>();  
  messageReceivedBreakoutQA = new EventEmitter<Message>();  
  connectionEstablished = new EventEmitter<Boolean>(); 
  ReceiveEventBroadcastMessage = new EventEmitter<BroadcastMessage>(); 
  SponserReceiveMessage = new EventEmitter<any>(); 
  refreshChatData:any = new BehaviorSubject('');
  leaderBoardRefresh = new EventEmitter<any>();

  private connectionIsEstablished = false;  
  private _hubConnection: HubConnection;  
  
  constructor() {  
    this.createConnection();  
    this.registerOnServerEvents();  
    this.startConnection();  
  }  
  
  sendMessage(message: Message) {  
    this._hubConnection.invoke('SendMessage', message);  
  }  
  
  sendEventBreakoutMessage(message: Message) {  
    this._hubConnection.invoke('SendEventBreakoutMessage', message);  
  }  
  
  SendEventBroadcastMessage(message: BroadcastMessage) {  
    this._hubConnection.invoke('SendEventBroadcastMessage', message);  
  } 

  sendCommentsReplayMessage(message) {  
    this._hubConnection.invoke('sendCommentReplyMessage', message);  
  }

  sendEndUserToExhibitor(message){
    this._hubConnection.invoke('sendSponserMessage', message);  
  }

  getPointsByEventRfresh(message){
    this._hubConnection.invoke('getPointsByEvent',message);
  }

  private createConnection() {  
    this._hubConnection = new HubConnectionBuilder()  
      .withUrl(this.messageHubUrl)  
      .build();  
      // console.log(this._hubConnection);
  }  
  
  startConnection(): void {  
    this._hubConnection  
      .start()  
      .then(() => {  
        this.connectionIsEstablished = true;  
        console.log('Hub connection started');  
        this.connectionEstablished.emit(true);  
      })  
      .catch(err => {  
        console.log('Error while establishing connection, retrying...');  
        setTimeout(function () { startConnection(); }, 5000);  
      });  
  }  
  
  private registerOnServerEvents(): void {  
    this._hubConnection.on('ReceiveMessage', (data: any) => {  
      // console.log(data);
      this.messageReceived.emit(data);
    }); 

    this._hubConnection.on('SponserReceiveMessage', (data: any) => {
      this.SponserReceiveMessage.emit(data);
    });

    this._hubConnection.on('ReceiveEventBreakoutMessage_Comment', (data: any) => {  
      // console.log(data);
      this.messageReceivedBreakoutComment.emit(data);  
    }); 
    
    this._hubConnection.on('ReceiveEventBreakoutMessage_QA', (data: any) => {  
      // console.log(data);
      this.messageReceivedBreakoutQA.emit(data);  
    }); 
    
    this._hubConnection.on('ReceiveEventBroadcastMessage', (data: any) => {  
      // console.log(data);
      this.ReceiveEventBroadcastMessage.emit(data);  
    }); 

    this._hubConnection.on('ReceivePointsByEvent', (data: any) => {  
      this.leaderBoardRefresh.emit(data);  
    }); 

    this._hubConnection.onclose((data: any) => {
      console.log('disconnected ' + data);
      this.startConnection();
    });
  }  
}    

function startConnection() {
  throw new Error('Function not implemented.');
}
