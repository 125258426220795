<div [innerHTML]="html"></div>

<div class="event-15th-video">
  <header class="section15">
    <div class="container12">
      <img class="brand-product-logos3" alt="" src="assets/images/container@2x.png" />
    </div>
  </header>
  <section class="section7 pt-cts-dv">
    <div class="container5 video-container-dv">
      <div class="col-image-icon2 video-main-blg">
        <video #singlePlayerTarget id="singlePlayerTarget" class="video-js vjs-default-skin vjs-big-play-centered p-0"
          controls preload="auto" autoplay muted></video>

        <!-- <img class="col-image-icon2" alt="" src="assets/images/col-image@2x.png" /> -->
      </div>
      <div class="video-content-dv">
        <div class="col-text2">
          <div class="h5-group">
            <b class="h51">FREE MASTERCLASS</b>
            <h1 class="h11">From Care Wreck to Care Empowered</h1>
            <br><br>
            <p><b>Key Takeaways:</b></p>
            <ul>
              <li>Shift from Care Wreck to SUCCESS.</li>
              <li>Move your care team from chaos to triumph.</li>
              <li>Roadmap for total confidence in Medicaid Compliance.</li>
              <li>AND PLENTY MORE!</li>
            </ul>
          </div>
          <div class="wrapper-btn">
            <a style="text-decoration: none;" class="vdo_btn_dv" target="_blank" href="https://caryfy.ai/try-demo/">
              Book a Demo
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="section8 video-chat-footer">
    <div class="container6">
      <div class="brand-product-logos-container">
        <img class="brand-product-logos1" alt="" src="assets/images/brand--product-logos@2x.png" />
      </div>
      <div class="copyright-20231">
        Copyright © 2023 caryfy.ai. All rights reserved
      </div>
    </div>
  </section>
</div>
<!-- <div class="event-15th-video">
  <header class="section15">
    <div class="container12">
      <img class="brand-product-logos3" alt="" src="assets/images/container@2x.png" />
    </div>
  </header>
  <section class="section7 pt-cts-dv">
    <div class="container5">
      <div class="col-text2">
        <div class="h5-group">
          <b class="h51">FREE MASTERCLASS</b>
          <h1 class="h11">From Care Wreck to Care Empowered</h1>
        </div>
      </div>
      <div class="col-image-icon2 video-main-blg">
        <video #singlePlayerTarget id="singlePlayerTarget" class="video-js vjs-default-skin vjs-big-play-centered p-0"
          controls preload="auto" autoplay muted></video>
      </div>
    </div>
  </section>
  <section class="section8 video-chat-footer">
    <div class="container6">
      <div class="brand-product-logos-container">
        <img class="brand-product-logos1" alt="" src="assets/images/brand--product-logos@2x.png" />
      </div>
      <div class="copyright-20231">
        Copyright © 2023 caryfy.ai. All rights reserved
      </div>
    </div>
  </section>
</div> -->