import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { EnvironmentService, PublicRestService } from 'pck-api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChannelService extends PublicRestService {
  private payloadUrl: string | undefined;
  constructor(
    @Inject(HttpClient) http: HttpClient,
    @Inject(EnvironmentService) public environmentData: EnvironmentService
  ) {
    super(http);
  }

  getChannelList(): Promise<any> {
    this.payloadUrl = this.environmentData.serviceUrl + 'Channel/GetAll';
    return super.get<any>(this.payloadUrl);
  }

  getByChannelName(channelName: string) {
    this.payloadUrl = this.environmentData.serviceUrl + 'Channel/GetByName/' + channelName;
    return super.get<any>(this.payloadUrl);
  }

  getChannelByUUID(channeluuid:string) {
    this.payloadUrl = this.environmentData.serviceUrl + 'Channel/GetDetail/' + channeluuid;
    return super.get<any>(this.payloadUrl);
  }

   getFeatureChannels() {
    this.payloadUrl = this.environmentData.serviceUrl + 'Channel/GetFeatureChannels';
    return super.get<any>(this.payloadUrl);
  }

   getRelatedChannels(categoryId:number) {
    this.payloadUrl = this.environmentData.serviceUrl + 'Channel/GetRelatedChanels/' + categoryId;
    return super.get<any>(this.payloadUrl);
  }

  RegisterViaEvent(requestData:any):  Observable<any> {
    this.payloadUrl =this.environmentData.serviceUrl  + `SubscribeChannel/Create`;
    return this.http.post(this.payloadUrl, requestData).pipe();
  }

  GetRegisterUser(requestData:any):  Observable<any> {
    this.payloadUrl = this.environmentData.serviceUrl + `SubscribeChannel/GetRegisterUser`;
    return this.http.post(this.payloadUrl, requestData).pipe();
  }
}
