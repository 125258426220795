<div class="mainbody">
  <div class="event-page">
    <section class="section banner">
      <div class="row inneer-banner">
        <div class="logo-success-summit-parent">
          <img class="logo-success-summit-icon" alt="" src="assets/public//logo-success-summit.svg" />

          <div class="badge">
            <b class="register-now">WEDNESDAY - 10 A.M. EST</b>
          </div>
          <div class="texth1-parent">
            <h1 class="texth1">
              <p class="free-masterclass">FREE MASTERCLASS</p>
              <p class="free-masterclass">FOR MEDICAID CARE PROVIDERS</p>
            </h1>
            <h5 class="texth5">
              <span>Medicaid Care providers in </span>
              <span class="georgia">{{stateName}}</span>
              <span> are planning for 2024 with this FREE Success Summit!</span>
            </h5>
          </div>
          <!-- <div class="button-group">
            <div class="button button-blg" data-bs-toggle="modal" data-bs-target="#varyingcontentModal"
              data-bs-whatever="@mdo">
              <b class="register-now ">{{defaulText}}</b>
            </div>
            <div class="button1 cursor-pointer" (click)="onVideoClick($event, videoDetail)">
              <img class="ioniconpplaycircleoutline" alt="" src="assets/public//ioniconpplaycircleoutline.svg" />

              <b class="register-now" style="cursor:pointer;">WATCH VIDEO</b>
            </div>
          </div> -->
        </div>
        <img class="pexels-theo-decker-5954514-1-icon" alt="" src="assets/public//pexelstheodecker5954514-1@2x.png" />
      </div>
    </section>
    <section class="section1">
      <h2 class="texth2">Join this workshop and discover...</h2>
      <div class="itm-wrap">   
        <div class="itm-card1">
          <img class="card-image-icon" alt="" src="assets/public//card-image1@2x.png" />

          <div class="date-parent">
            <div class="date1">
              <img class="ioniconccalendaroutline" alt="" src="assets/public//ioniconccalendaroutline.svg" />

              <div class="september-20th2023">October 11th,2023</div>
            </div>
            <a class="card-title"  href="live-event/solving-staffing-challenges">Solving Staffing Challenges – Hire faster, train better, and
              retain right employees</a>
            <div class="tags-wrap">
              <div class="tag-itms">
                <div class="compliance">#staffing</div>
              </div>
              <div class="tag-itms">
                <div class="compliance">#challenges</div>
              </div>
              <div class="tag-itms">
                <div class="compliance">#employees</div>
              </div>
              <div class="tag-itms">
                <div class="compliance">#strategy</div>
              </div>
            </div>
          </div>
          <div class="badge-wrap">
            <div class="badge-online">
              <div class="online">Online</div>
            </div>
            <div class="badge-free">
              <div class="online">Free</div>
            </div>
          </div>
        </div>
        <div class="itm-card1">
          <img class="card-image-icon"  alt="" src="assets/public//card-image4@2x.png" />

          <div class="date-parent">
            <div class="date1">
              <img class="ioniconccalendaroutline" alt="" src="assets/public//ioniconccalendaroutline.svg" />

              <div class="september-20th2023">October 18th,2023</div>
            </div>
            <a class="card-title" href="live-event/finding-growth" >Finding Growth – How to grow by leveraging digital marketing
              for referrals</a>
            <div class="tags-wrap">
              <div class="tag-itms">
                <div class="compliance">#digital-marketing</div>
              </div>
              <div class="tag-itms">
                <div class="compliance">#social-media</div>
              </div>
              <div class="tag-itms">
                <div class="compliance">#digital-tools</div>
              </div>
              <div class="tag-itms">
                <div class="compliance">#AI-generated-process</div>
              </div>
            </div>
          </div>
          <div class="badge-wrap">
            <div class="badge-online">
              <div class="online">Online</div>
            </div>
            <div class="badge-free">
              <div class="online">Free</div>
            </div>
          </div>
        </div>
        <!-- <div class="itm-card1">
          <img class="card-image-icon" alt="" src="assets/public//card-image2@2x.png" />

          <div class="date-parent">
            <div class="date1">
              <img class="ioniconccalendaroutline" alt="" src="assets/public//ioniconccalendaroutline.svg" />

              <div class="september-20th2023">October 4th,2023</div>
            </div>
            <a class="card-title">Operations Management – Improving cash flow and employee
              accountability</a>
            <div class="tags-wrap">
              <div class="tag-itms">
                <div class="compliance">#operations</div>
              </div>
              <div class="tag-itms">
                <div class="compliance">#improvement</div>
              </div>
              <div class="tag-itms">
                <div class="compliance">#accountability</div>
              </div>
              <div class="tag-itms">
                <div class="compliance">#management</div>
              </div>
            </div>
          </div>
          <div class="badge-wrap">
            <div class="badge-online">
              <div class="online">Online</div>
            </div>
            <div class="badge-free">
              <div class="online">Free</div>
            </div>
          </div>
        </div> -->
        <div class="itm-card1">
          <img class="card-image-icon" alt="" src="assets/public//card-image3@2x.png" />

          <div class="date-parent">
            <div class="date1">
              <img class="ioniconccalendaroutline" alt="" src="assets/public//ioniconccalendaroutline.svg" />

              <div class="september-20th2023">October 25th,2023</div>
            </div>
            <a class="card-title" href="live-event/blueprint-for-serenity">Blueprint for Serenity: The DREAM Method to Tame Paperwork</a>
            <div class="tags-wrap">
              <div class="tag-itms">
                <div class="compliance">#easy-process</div>
              </div>
              <div class="tag-itms">
                <div class="compliance">#paperless</div>
              </div>
              <div class="tag-itms">
                <div class="compliance">#detail-reports</div>
              </div>
              <div class="tag-itms">
                <div class="compliance">#auto-generation</div>
              </div>
            </div>
          </div>
          <div class="badge-wrap">
            <div class="badge-online">
              <div class="online">Online</div>
            </div>
            <div class="badge-free">
              <div class="online">Free</div>
            </div>
          </div>
        </div>
        <div class="itm-card1">
          <img class="card-image-icon" alt="" src="assets/public//card-image@2x.png" />

          <div class="date-parent">
            <div class="date">
              <img class="ioniconccalendaroutline" alt="" src="assets/public//ioniconccalendaroutline.svg" />

              <div class="september-20th2023">November 1st,2023</div>
            </div>
            <a class="card-title" href="live-event/5s-for-compliance">5S for Compliance – Making compliance stress free</a>
            <div class="tags-wrap">
              <div class="tag-itms">
                <div class="compliance">#compliance</div>
              </div>
              <div class="tag-itms">
                <div class="compliance">#management</div>
              </div>
              <div class="tag-itms">
                <div class="compliance">#stress-free</div>
              </div>
              <div class="tag-itms">
                <div class="compliance">#process</div>
              </div>
            </div>
          </div>
          <!-- <div class="badge-online-parent"> -->
          <div class="badge-wrap">
            <div class="badge-online">
              <div class="online">Online</div>
            </div>
            <div class="badge-free">
              <div class="online">Free</div>
            </div>
          </div>
        </div>
        <div class="itm-card1">
          <img class="card-image-icon" alt="" src="assets/public//card-image5@2x.png" />

          <div class="date-parent">
            <div class="date1">
              <img class="ioniconccalendaroutline" alt="" src="assets/public//ioniconccalendaroutline.svg" />

              <div class="september-20th2023">November 8th,2023</div>
            </div>
            <a class="card-title" href="live-event/consolidating-the-chaos">Consolidating the Chaos: How a Unified Care Ecosystem Transforms Productivity</a>
            <div class="tags-wrap">
              <div class="tag-itms">
                <div class="compliance">#implementation</div>
              </div>
              <div class="tag-itms">
                <div class="compliance">#stress-free</div>
              </div>
              <div class="tag-itms">
                <div class="compliance">#management</div>
              </div>
              <div class="tag-itms">
                <div class="compliance">#improvement</div>
              </div>
            </div>
          </div>
          <div class="badge-wrap">
            <div class="badge-online">
              <div class="online">Online</div>
            </div>
            <div class="badge-free">
              <div class="online">Free</div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section2">
      <img class="image-2-icon" alt="" src="assets/public//image-2@2x.png" />

      <div class="column">
        <b class="wondering-if-this">WONDERING IF THIS REALLY WORKS?</b>
        <!-- <h1 class="wondering-if-this">WONDERING IF THIS REALLY WORKS?</h1> -->
        <div class="check-out-how">
          Check out how many medicaid entrepreneurs are mastering operational
          efficiency using this SIMPLE SYSTEM
        </div>
        <!-- <div class="button2 cursor-pointer" data-bs-toggle="modal" data-bs-target="#varyingcontentModal"
          data-bs-whatever="@mdo">
          <b class="register-now">{{defaulText}}</b>
        </div> -->
      </div>
      <div class="column1">
        <img class="icon" alt="" src="assets/public//1.svg" />


        <div class="these-sessions-are-informative-parent">
          <div class="wondering-if-this">
            "These sessions are informative, good take-aways and concise
            enough for owners and managers to take full advantage of the
            resources."
          </div>
          <b class="m-sizemore-augusta">– M Sizemore, Augusta, GA</b>
        </div>
        <div class="truly-appreciate-you-creating-parent">
          <div class="wondering-if-this">
            "Truly appreciate you creating this summit!"
          </div>
          <b class="m-sizemore-augusta">– O Cisse, Atlanta, GA</b>
        </div>
      </div>
    </section>
    <section class="section3">
      <h2 class="texth21">
        Your first 3 steps to reach new heights of success in 2024 begin here.
      </h2>
      <div class="itm-wrap1">
        <div class="itm-step">
          <div class="itm-number">Step #1</div>
          <div class="texth5-parent">
            <h5 class="texth51">Register For The Free Workshop</h5>
            <p class="textp">
              You can get my best secrets to reduce your operational costs ...
              completely FREE!
            </p>
          </div>
        </div>
        <div class="itm-step">
          <div class="itm-number">Step #2</div>
          <div class="texth5-parent">
            <h5 class="texth51">Show Up For The Training</h5>
            <p class="textp">
              After you register, make SURE you're ready to add your thoughts
              during the workshop! Don't miss out on the information and
              content I'll be sharing with you!
            </p>
          </div>
        </div>
        <div class="itm-step2">
          <div class="itm-number">Step #3</div>
          <div class="texth5-parent">
            <h5 class="texth51">
              Get Your Free Success Plan for 2024 After The Webinars
            </h5>
            <p class="textp">
              When you attend live, you'll also receive this free gift to help
              you implement your plan.
            </p>
          </div>
        </div>
      </div>
      <!-- <div class="button3 cursor-pointer " data-bs-toggle="modal" data-bs-target="#varyingcontentModal">
        <b class="register-now"><span *ngIf="!isUserRegister">GAIN ACCESS & CLAIM YOUR GIFT</span>
          <span *ngIf="isUserRegister">You've already registered</span>
        </b>
      </div> -->
    </section>
    <div class="seection-parent">
      <div class="seection">
        <div class="texth3">Meet Your Host</div>
        <div class="itm-host">
          <div class="host-detail">
            <img class="host-image-icon" alt="" src="assets/public//ellipse-1@2x.png" />

            <div class="host-name">
              <div class="online">Anand Chaturvedi</div>
              <div class="care-business-coach">CEO, Caryfy AI</div>
            </div>
          </div>
          <div class="a-veteran-healthcare">
            A veteran healthcare IT executive with a passion for transforming
            care delivery through technology. More than a decade of experience
            spanning care coordination software, tele-health platforms and
            healthcare analytics.
          </div>
        </div>
      </div>
      <div class="section4">
        <div class="image-free-gift-parent">
          <img class="image-free-gift-icon" alt="" src="assets/public//image-free-gift.svg" />

          <div class="discover-the-6-secrets-to-reac-parent">
            <div class="save-your-free">
              Discover the 6 Secrets to reach new heights of success in 2024!
            </div>
            <b class="success-plan-2024">Success Plan 2024</b>
            <div class="save-your-free">
              Save your FREE seat & get your FREE GIFT
            </div>
            <!-- <div class="button4 cursor-pointer" data-bs-toggle="modal" data-bs-target="#varyingcontentModal">
              <div class="online">{{defaulText}}</div>
            </div> -->
          </div>
        </div>
      </div>
      <div class="section5">
        <div class="when-you-attend">When You Attend The Workshop Live!</div>
        <div class="event-intro">
          <img class="image-4-icon" alt="" src="assets/public//image-4@2x.png" />

          <div class="you-know-trying-container">
            <p class="you-know-trying">
              You know, trying to learn how to grow your business with
              strategies you found on the internet or learned in the community
              association meetings isn't the way to be successful as a
              Medicaid care provider. Yet most medicaid entrepreneurs have
              tried it at one time or another.
            </p>
            <p class="you-know-trying">
              I have been doing this for more than a decade. I don't say this
              to brag.
            </p>
            <p class="you-know-trying">
              Just to point out that care providers DON'T have to endure
              struggling with high operational costs and stressful compliance.
            </p>
            <p class="you-know-trying">
              The same is possible for you after you discover how to be
              successful as a care provider in {{stateName}}.
            </p>
            <p class="free-masterclass">
              Because I've taught hundreds of care providers how to do this in
              a rather expensive course.
            </p>
          </div>
        </div>
        <div class="event-learnings">
          <div class="save-your-free">
            I'm revealing this all in a free online training where you'll
            discover...
          </div>
          <div class="making-compliance-stress-container">
            <ul class="making-compliance-stress-free">
              <li class="making-compliance-stress">
                Making compliance stress free
              </li>
              <li class="making-compliance-stress">
                Hire faster, train better, and retain right employees
              </li>
              <li class="making-compliance-stress">
                Improving cash flow and employee accountability
              </li>
              <li class="making-compliance-stress">
                How to generate care quality forms and reports, without extra
                effort
              </li>
              <li class="making-compliance-stress">
                How to grow by leveraging digital marketing for referrals
              </li>
              <li>How to implement change for improving your business</li>
            </ul>
          </div>
        </div>
        <div class="this-training-is-container">
          <p class="you-know-trying">
            This training is designed for those who want to be more successful
            in 2024 as care providers in {{stateName}}.
          </p>
          <p class="free-masterclass">
            You’ll all in these 6 SPECIFIC steps PLUS your own personalized
            Success Plan for 2024
          </p>
        </div>
        <!-- <div class="button5 cursor-pointer " data-bs-toggle="modal" data-bs-target="#varyingcontentModal">
          <div class="online">{{defaulText}}</div>
        </div> -->
      </div>
    </div>
  </div>


</div>
<!-- Varying modal content -->
<div class="modal fade" id="varyingcontentModal" tabindex="-1" aria-labelledby="varyingcontentModalLabel"
  aria-hidden="true" *ngIf="!isUserRegister">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="varyingcontentModalLabel">Register</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form [formGroup]="registerFormGroup">
          <div class="mb-3">
            <label for="firstName-name" class="col-form-label">Name:</label>
            <input type="text" formControlName="FirstName" class="form-control" id="firstName-name">
          </div>
          <div class="mb-3">
            <label for="recipient-name" class="col-form-label">Email:</label>
            <input type="email" formControlName="EmailId" class="form-control" id="recipient-name">
          </div>
          <div class="mb-3">
            <label for="message-text" class="col-form-label">Message:</label>
            <textarea class="form-control" formControlName="Comments" id="message-text"></textarea>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" #closebutton class="btn btn-light" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" (click)="registerEvent()">Submit</button>
      </div>
    </div>
  </div>
</div>

<ng-template #videoDetail let-modal>
  <video-player *ngIf="playVideo  && videoURL" srcurl="{{videoURL}}"></video-player>
</ng-template>