import { Component, Input, OnChanges } from '@angular/core';
import { StringNullableChain } from 'lodash';

@Component({
  selector: 'lib-eventlanding',
  templateUrl: './eventlanding.component.html',
  styleUrls: ['./eventlanding.component.scss']
})
export class EventlandingComponent implements OnChanges {
@Input() eventData:any = {};
@Input() isUserLogin:boolean;
liveURL:string;
firstEventUUID: string = "506e6132-cc82-49e4-a28e-d933eca43e89";
secondEventUUID: string = "b46f9aeb-5832-41aa-a057-2c44a527bc67";
thirdEventUUID: string = "ba99bfaa-359d-413c-8ca5-3306e79290bd";
fourthEventUUID: string = "a431df9c-b463-4374-a7da-68f2a7ec5663";
fifthEventUUID: string = "ff182c2c-a413-444c-a195-138e22ea9d75";

constructor(){
  if(this.isUserLogin){
    let index = window.location.href.lastIndexOf('=');
    this.liveURL = window.location.href.substring(0,index+1);
  }
}

ngOnChanges(){
  if(this.isUserLogin){
    let index = window.location.href.lastIndexOf('=');
    this.liveURL = window.location.href.substring(0,index+1);
  }
}
 }
