export class AnalyticsViewModel {
    total: string;
    name: string;
    email: string;
    date: any;
}

export class AnalyticsCommetsAnsViewModel {
    id:string;
    uuid:string;
    sessionUuid: string;
    commentUuid: string;
    userUuid: string;
    ansText: any;
}