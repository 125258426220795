import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { PckApiModule } from 'pck-api';
import { PckGeneralComponentsModule } from 'pck-general-components';
import { SharedService } from './Service/shared.service';
import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { VideoDetailComponent } from './video-detail/video-detail.component';
import { ChannelDetailComponent } from './channel-detail/channel-detail.component';
import { SimplebarAngularModule } from 'simplebar-angular';
import { PodcastDetailComponent } from './podcast-detail/podcast-detail.component';
import { EventDetailComponent } from './Component/event-detail/event-detail.component';
import { ReactiveFormsModule } from '@angular/forms';
import { StaticChannelComponent } from './static-channel/static-channel.component';
import { AuditoriumComponent } from './Component/auditorium/auditorium.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import {MatTabsModule} from '@angular/material/tabs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VideoEndComponent } from './Component/video-end/video-end.component';
import { UserBlogComponent } from './Component/user-blog/user-blog.component';
import { FirstEventBlogComponent } from './Component/first-event-blog/first-event-blog.component';
import { SecondEventBlogComponent } from './Component/second-event-blog/second-event-blog.component';
import { ThirdEventBlogComponent } from './Component/third-event-blog/third-event-blog.component';
import { FifthEventBlogComponent } from './Component/fifth-event-blog/fifth-event-blog.component';
import { FreeMasterClassComponent } from './15thDec/icwp/free-master-class/free-master-class.component';

// import { VelzonControlsModule } from 'velzonpackage';


@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    VideoDetailComponent,
    ChannelDetailComponent,
    PodcastDetailComponent,
    EventDetailComponent,
    StaticChannelComponent,
    AuditoriumComponent,
    VideoEndComponent,
    UserBlogComponent,
    FirstEventBlogComponent,
    SecondEventBlogComponent,
    ThirdEventBlogComponent,
    FifthEventBlogComponent,
    FreeMasterClassComponent
  ],
  imports: [
    // VelzonControlsModule.forRoot(),    
    ReactiveFormsModule,
    PckApiModule.forRoot(),
    PckGeneralComponentsModule.forRoot(),
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: "XSRF-TOKEN",
      headerName: "X-XSRF-TOKEN",
    }),
    BrowserModule,
    AppRoutingModule,
    SimplebarAngularModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    PickerModule,
    MatButtonToggleModule,
    MatToolbarModule,
    MatRadioModule,
    MatProgressBarModule,
    MatButtonModule,
    MatIconModule,
    MatTabsModule,
    BrowserAnimationsModule
  ],
  providers: [SharedService,
    { provide: "Window", useValue: window }],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
