import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import videojs from 'video.js';
import { CommonService, CommonFunctions, AppInsightService, AdminService, SignalRChatService, EventService } from 'projects/pck-general-components/src/public-api';
import { DomSanitizer, Meta, SafeHtml, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-video-detail',
  templateUrl: './video-detail.component.html',
  styleUrls: ['./video-detail.component.scss']
})
export class VideoDetailComponent {
  videoLink: any;
  @ViewChild('singlePlayerTarget', { static: false }) singlePlayerTarget: ElementRef;
  @ViewChild('target', { static: false }) target: ElementRef;
  playerRef: any;
  vidObj: any;
  @Input() userData: {
    pageName: string,
    videoId: string,
    resourceUUID: string,
    sessionData: any;
  };
  @Output() eventAgenda = new EventEmitter<string>();
  @Input() options: {
    techOrder: ['youtube'],
    autoplay: boolean,
    muted: boolean,
    sources: {
      src: string,
      type: string,
    }[],
    fluid: boolean,
    controlBar: any,
    nativeControlsForTouch: boolean,
    controls: boolean,
  } = {
      "fluid": true,
      "techOrder": ['youtube'],
      "autoplay": true,
      "muted": false,
      "controlBar": {
        "playToggle": false,
        "captionsButton": false,
        "chaptersButton": false,
        "subtitlesButton": false,
        "remainingTimeDisplay": true,
        "progressControl": {
          "seekBar": false
        },
        "fullscreenToggle": true,
        "playbackRateMenuButton": false,
      },
      "nativeControlsForTouch": false,
      "sources": [],
      "controls": true
    };
  isPlayerReady: { eventType: string; currentTime: any; totalDuration: any; remainingTime: number; timeStamp: string; };

  georgiapageJsonld = {
    "@context": "http://schema.org",
    "@type": "VideoObject",
    "name": "Empowering SFC Providers: Georgia Care Event video",
    "description": "Special event for Structured Family Caregiving-SFC Providers in Georgia.",
    "thumbnailUrl": "https://img.youtube.com/vi/ES50utac3B4/maxresdefault.jpg",
    "uploadDate": "2023-12-15T00:00:00Z",
    "duration": "PT49M43S",
    "url": "https://youtu.be/ES50utac3B4",
    "embedUrl": "https://www.youtube.com/embed/ES50utac3B4",
    "interactionStatistic": {
      "@type": "InteractionCounter",
      "interactionType": {
        "@type": "http://schema.org/WatchAction"
      }
    }
  };

  northcarolinapageJsonld = {
    "@context": "http://schema.org",
    "@type": "VideoObject",
    "name": "Care Transformation: Medicaid HCBS Waiver Event in North Carolina video",
    "description": "Special event for Medicaid Home and Community-Based Services Waiver - HCBS Waiver in North Carolina.",
    "thumbnailUrl": "https://img.youtube.com/vi/RlCZC9xcC-A/maxresdefault.jpg",
    "uploadDate": "2023-12-15T00:00:00Z",
    "duration": "PT49M42S",
    "url": "https://youtu.be/RlCZC9xcC-A",
    "embedUrl": "https://www.youtube.com/embed/RlCZC9xcC-A",
    "interactionStatistic": {
      "@type": "InteractionCounter",
      "interactionType": {
        "@type": "http://schema.org/WatchAction"
      }
    }
  };

  icwppageJsonld = {
    "@context": "http://schema.org",
    "@type": "VideoObject",
    "name": "Empowering ICWP Case Managers video",
    "description": "Special event for Independent Care Waiver Program - ICWP Case Managers in Georgia.",
    "thumbnailUrl": "https://img.youtube.com/vi/NkERqmviGMs/maxresdefault.jpg",
    "uploadDate": "2023-12-15T00:00:00Z",
    "duration": "PT49M42S",
    "url": "https://youtu.be/NkERqmviGMs",
    "embedUrl": "https://www.youtube.com/embed/NkERqmviGMs",
    "interactionStatistic": {
      "@type": "InteractionCounter",
      "interactionType": {
        "@type": "http://schema.org/WatchAction"
      }
    }
  };
  html: SafeHtml;


  constructor(private appInsightService: AppInsightService,
    private sanitizer: DomSanitizer,
    private meta: Meta,
    private titleService: Title) {
    this.meta.removeTag('name=name');
    this.meta.removeTag('name=description');
    this.meta.removeTag('name=keywords');
    if (window.location.pathname.includes('icwp')) {
      this.titleService.setTitle('Empowering ICWP Case Managers - video');
      this.meta.addTag({ name: 'keywords', content: 'Care Empowerment, Time-saving tools, Transformative techniques, Provider burnout prevention, Motivation identification, Clarity and focus, Long-term care niche, Case manager role, Family-based care, Immediate impact, Valuable insights, Myth-busting' });
      this.html = this.getSafeHTML(this.icwppageJsonld);
    }
    else if (window.location.pathname.includes('georgia')) {
      this.titleService.setTitle('Empowering SFC Providers: Georgia Care Event - video');
      this.meta.addTag({ name: 'keywords', content: 'Medicaid care delivery, Case management success, Care coordination efficiency, Patient outcomes improvement, Efficient operations in healthcare, Medicaid complexity solutions, Care Streaming benefits, Care management clarity, Proven strategies in healthcare, Chaos to order transformation, Medicaid provider success, Well-oiled care coordination' });
      this.html = this.getSafeHTML(this.georgiapageJsonld);
    } else {
      this.titleService.setTitle('Care Transformation: Medicaid HCBS Waiver Event in North Carolina - video');
      this.meta.addTag({ name: 'keywords', content: 'Medicaid beneficiary satisfaction, Administrative task reduction, Care Streaming leverage, Stress relief in healthcare, Quality care compliance, Non-compliance penalties avoidance, Connect and comply, Scheduling and referrals, Administrative simplicity, Care network connection, Care Streaming compliance, Care Wreck elimination' });
      this.html = this.getSafeHTML(this.northcarolinapageJsonld);
    }
  }

  getVideoType(videoURL: string): string {
    if (videoURL.includes('.mp4')) {
      return 'video/mp4'
    }
    else {
      return 'video/youtube';
    }
  }

  ngOnInit(): void {
    if (window.location.pathname.includes('icwp')) {
      this.videoLink = "https://www.youtube.com/watch?v=lTpdYT7M0D4";
    }
    else if (window.location.pathname.includes('georgia')) {
      this.videoLink = "https://www.youtube.com/watch?v=Q_Yeo_t54Yk";
    } else {
      this.videoLink = "https://www.youtube.com/watch?v=BGjQxiJw9RQ";
    }
    this.options.sources = [{
      src: this.videoLink,
      type: this.getVideoType(this.videoLink),
    }];
  }

  ngAfterViewInit() {
    this.playerRef = this.singlePlayerTarget.nativeElement;
    this.initSinglePlayer();
  }

  initSinglePlayer() {
    this.vidObj = new videojs(this.playerRef, this.options, () => {
      videojs.log('Your player is ready!');
      this.appInsightService.logEvent('video player Ready');
      const obj = {
        eventType: 'On Player Ready',
        currentTime: this.vidObj.currentTime(),
        totalDuration: this.vidObj.duration() ? this.vidObj.duration() : 0,
        remainingTime: 0,
        timeStamp: new Date().toLocaleString()
      };
      this.isPlayerReady = obj;
    });
    this.vidObj.play();
    this.vidObj.on('play', (data) => {
      console.log('407 play');
    });

    this.vidObj.on('pause', (data) => {
    });

    this.vidObj.on('ended', (data) => {
    });
  }

  getSafeHTML(jsonLD: { [key: string]: any }): SafeHtml {
    const json = jsonLD ? JSON.stringify(jsonLD, null, 2).replace(/<\/script>/g, '<\\/script>') : '';
    // escape / to prevent script tag in JSON
    const html = `<script type="application/ld+json">${json}</script>`;
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}