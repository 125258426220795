import { Inject, Injectable } from "@angular/core";

@Injectable()
export class EnvironmentService {
  constructor(@Inject("Window") private window: Window) { }

  // get data object from script in Index.cshtml
  private get environmentData(): any {
    return (this.window as any).appdata;
  }

  public getValue(key: string): string {
    if (!this.environmentData) {
      console.error("please set environment-variables");
      return '';
    }
    if (!this.environmentData[key]) {
      console.error(`please set environment-variable ${key}`);
      return '';
    }
    return this.environmentData[key];
  }

  public get serviceUrl(): string {
    return this.getValue("serviceUrl");
  }
}
