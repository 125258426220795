import { Component } from '@angular/core';
import { DomSanitizer, Meta, SafeHtml, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-free-master-class',
  templateUrl: './free-master-class.component.html',
  styleUrls: ['./free-master-class.component.scss']
})
export class FreeMasterClassComponent {
  pageJsonld = {
    "@context": "https://schema.org",
    "@type": "Event",
    "name": "FROM CARE WRECK TO CARE EMPOWERED",
    "description": "SPECIAL EVENT FOR STRUCTURED FAMILY CAREGIVING-SFC PROVIDERS IN GEORGIA",
    "startDate": "2023-12-15T10:00-05:00",
    "eventStatus": "https://schema.org/EventScheduled",
    "eventAttendanceMode": "https://schema.org/OnlineEventAttendanceMode",
    "location": {
      "@type": "VirtualLocation",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "75 Fifth Street NW",
        "addressLocality": "Atlanta",
        "addressRegion": "GA",
        "postalCode": "30328",
        "addressCountry": "United States"
      },
      "url": "https://www.eventkind.com/sfc/georgia/free-master-class"
    },
    "performer": {
      "@type": "Person",
      "name": "Anand Chaturvedi"
    },
    "keywords": [
      "Medicaid",
      "Structured Family Caregiving",
      "SFC Providers",
      "Special Event",
      "Georgia",
      "Healthcare",
      "Caregivers",
      "Collaboration",
      "Knowledge Sharing",
      "SFC providers",
      "structured caregiving",
      "Georgia caregiving",
      "family caregiving",
      "home-based care",
      "elderly care",
      "caregiving services",
      "Georgia SFC",
      "senior care",
      "caregiving support",
      "home health services",
      "respite care",
      "caregiver assistance",
      "in-home care",
      "Georgia seniors",
      "personalized care"
    ]
  };
  html: SafeHtml;

  constructor(private router: Router,
    private sanitizer: DomSanitizer,
    private meta: Meta,
    private titleService: Title) {
    this.titleService.setTitle('Empowering SFC Providers: Georgia Care Event');
    this.meta.removeTag('name=name');
    this.meta.removeTag('name=description');
    this.meta.removeTag('name=keywords');
    this.meta.addTag({ name: 'name', content: 'FROM CARE WRECK TO CARE EMPOWERED' });
    this.meta.addTag({ name: 'description', content: 'SPECIAL EVENT FOR STRUCTURED FAMILY CAREGIVING-SFC PROVIDERS IN GEORGIA' });
    this.meta.addTag({ name: 'keywords', content: 'Medicaid, Structured Family Caregiving, SFC Providers, Special Event, Georgia, Healthcare, Caregivers, Collaboration, Knowledge Sharing, SFC providers, structured caregiving, Georgia caregiving, family caregiving, home-based care, elderly care, caregiving services, Georgia SFC, senior care, caregiving support, home health services, respite care, caregiver assistance, in-home care, Georgia seniors, personalized Care' });
  }

  ngOnInit() {
    this.html = this.getSafeHTML(this.pageJsonld);
  }

  redirect() {
    this.router.navigateByUrl("sfc/georgia/free-master-class/video")
  }

  getSafeHTML(jsonLD: { [key: string]: any }): SafeHtml {
    const json = jsonLD ? JSON.stringify(jsonLD, null, 2).replace(/<\/script>/g, '<\\/script>') : '';
    // escape / to prevent script tag in JSON
    const html = `<script type="application/ld+json">${json}</script>`;
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}