import { HttpClient, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from '@angular/core';
import { RestService } from "./rest-api.service";

@Injectable()
export class PublicRestService extends RestService {
  constructor(@Inject(HttpClient) public http: HttpClient) {
    super(http);
  }

  get<T>(url: string): Promise<T | undefined> {
    return this.GET<T>(url);
  }

  post<T>(path: string, request: any): Promise<T | undefined> {
    return super.POST<T>(path, request);
  }

  put(path: string, request: any): Promise<void> {
    return super.PUT<void>(path, request);
  }

  delete(path: string, request: any): Promise<void> {
    return super.DELETE<void>(path, request);
  }
}
