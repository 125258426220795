import { Component } from '@angular/core';

@Component({
  selector: 'app-fifth-event-blog',
  templateUrl: './fifth-event-blog.component.html',
  styleUrls: ['./fifth-event-blog.component.scss']
})
export class FifthEventBlogComponent {

}
