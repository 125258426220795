///import { SessionFeedbackModalComponent } from './../dialog/session-feedback-modal/session-feedback-modal.component';
///import { MeetingScheduleComponent } from './../dialog/meeting-schedule/meeting-schedule.component';
//import { MatDialog } from '@angular/material/dialog';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation, AfterViewInit, NgZone, AfterViewChecked, Output, EventEmitter, HostListener } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { interval, Subscription, timer } from 'rxjs';
import videojs from 'video.js';
import 'videojs-youtube';
import * as moment from 'moment-timezone';
import { map } from 'rxjs/operators';
import { AppInsightService } from '../../service/appinsight.service';
import { CommonFunctions } from '../../service/common-functions';
import { CommonService } from '../../service/common.service';
import { SignalRChatService } from '../../service/signal-r-chat.service';
import { Message } from '../../model/message';
import { AnalyticsCommetsAnsViewModel } from '../../model/analytics-view-model';
import { AdminService } from '../../service/admin.service';
import { ITrackVideoData } from '../../model/videojs-view-modal';
import { environment } from '../../../environment';
import { EventService } from '../../service/event.service';

// declare let videojs: any;

@Component({
  selector: 'app-vjs-player',
  templateUrl: './videojs.component.html',
  styleUrls: ['./videojs.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [DatePipe]
})
export class VideojsComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
  vidObj: any;
  modal: any;
  popupopentime: any;
  timemsg: string = "";
  itemAns: AnalyticsCommetsAnsViewModel = new AnalyticsCommetsAnsViewModel()

  private apiBaseUrl = environment.dotNetApiEndpoint;
  resourceData: any;
  cnt2 = 30;
  sessionStartCount = 0;
  setInterval = setInterval;
  sessionres: any[];
  interval: any; //NodeJS.Timer;
  todaysession: any[];
  tomorrowsession: any[];
  yesterdaysession: any[];
  sessionData: any;
  pollTimes: any;
  sessionPollsList: any;
  timerSubscription: Subscription;
  @Input() sessionPolls: any = [];
  sessionCommets = [];
  sessioninterval: any;
  nextsessionData: any;
  filterSession: any;
  currentVideoSess: any;
  lastSession: any;
  eventDetail: { title: any; day: any; id: any };
  loginData: any;
  businessName: string;
  ngAfterViewChecked() {
    // 
  }

  @ViewChild('singlePlayerTarget', { static: false }) singlePlayerTarget: ElementRef;
  @ViewChild('target', { static: false }) target: ElementRef;
  private playerRef: any;
  @Input() type: string;
  @Input() chat: boolean;
  @Input() agenda: boolean;
  @Input() userData: {
    pageName: string,
    videoId: string,
    resourceUUID: string,
    sessionData: any;
  };
  @Output() eventAgenda = new EventEmitter<string>();
  // see options: https://github.com/videojs/video.js/blob/mastertutorial-options.html
  @Input() options: {
//    techOrder: ['youtube'],
    techOrder: any,
    aspectRatio: string,
    autoplay: boolean,
    muted: boolean,
    sources: {
      src: string,
      type: string,
    }[],
    fluid: boolean,
    controlBar: any,
    nativeControlsForTouch: boolean,
  };

  public errorMsg: string;
  public userDetails: any;
  public eventData: any;
  private isPlayerReady: any = null;
  private loadedPlayList: any;
  currentSessionData: any;
  feedbackStatus: boolean;
  commentForm: UntypedFormGroup;
  commentResponse: any = {};
  liked = false;

  form: UntypedFormGroup;
  pollMessage: string;

  @ViewChild(FormGroupDirective, { static: false }) formGroupDirective: FormGroupDirective;

  // Comments 
  txtMessage: string = '';
  // uniqueID: string = new Date().getTime().toString();  
  messages = new Array<Message>();
  message = new Message();
  public isEmojiPickerVisible: boolean;
  pollData: any = undefined;
  pollAnswerProgress: any;
  popupopopen: boolean = false;
  subscription: Subscription;

  // tslint:disable-next-line: max-line-length
  constructor(private datePipe: DatePipe, private commonService: CommonService, private commonFunctions: CommonFunctions, private appInsightService: AppInsightService, private adminService: AdminService, private fb: UntypedFormBuilder, private chatService: SignalRChatService, private _ngZone: NgZone, public router: Router, private eventService: EventService, private route: ActivatedRoute) {
    this.commonService.loginData.subscribe(x => {
      this.userDetails = x;

      if (this.type === 'single') {
        if (this.isPlayerReady) {
          this.saveVideoAnalytics(this.isPlayerReady);
          this.isPlayerReady = null;
          // if (x.videoAutoPlay) {
          //   this.vidObj.play();
          // }
        } else {
          // if (x.videoAutoPlay) {
          //   this.vidObj.play();
          // }
        }
      }
    });

    this.commonService.userEventData.subscribe(data => {
      this.eventData = data;
      this.eventDetail = {
        title: this.eventData.title,
        day: this.eventData.startDate.substring(0, 2),
        id: this.eventData.uuid
      }
    });
    this.subscribeToEvents();
    this.chatService.refreshChatData.subscribe(data => {
      if (data) {
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          element.isReplay = false;
          element.isAns = false;
        }

        this.sessionCommets = data;
      }
    });

    localStorage.setItem('tabState', 'open');
  }



  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload($event: any): void {
    this.saveVideoPlayedDuration('tabclose');
  }


  inutSet(event: any) {
    this.txtMessage = event.target.value
  }
  setAgenda() {
    this.eventAgenda.emit();
    var today = this.datePipe.transform(new Date(), 'MM-dd-yyyy');
    let yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    var yesterdaystr = this.datePipe.transform(yesterday, 'MM-dd-yyyy');
    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    var tomorrowstr = this.datePipe.transform(tomorrow, 'MM-dd-yyyy');
    this.todaysession = [];
    this.tomorrowsession = [];
    this.yesterdaysession = [];
    this.adminService.getAllSessionsByEventId(this.eventData.uuid).subscribe(response => {
      this.sessionData = response;
      response.forEach(element => {
        var startDate = moment(element.startDateTime).tz(element.timeZone)['_d'];
        var endDate = moment(element.endDateTime).tz(element.timeZone)['_d'];
        element.startDate = new Date(new Date(startDate).toDateString());
        element.endDate = new Date(new Date(endDate).toDateString());
        element.startTime = startDate.getHours() + ":" + startDate.getMinutes();
        element.endTime = endDate.getHours() + ":" + endDate.getMinutes();
        if (element.startDate == today) {
          this.todaysession.push(element)
        }
        else if (element.startDate == tomorrowstr) {
          this.tomorrowsession.push(element);
        }
        else if (element.startDate == yesterdaystr) {
          this.yesterdaysession.push(element);
        }
      });
    });

  }

  ngOnInit() {
    this.businessName = this.route.snapshot?.queryParamMap?.get('businessName');
    // this.timerSubscription = timer(0, 10000).pipe(
    //   map(() => {
    //     var date = new Date();
    //     var timestr = date.getHours() + ":" + date.getMinutes();
    //     if (this.sessionPollsList && this.sessionPollsList.length > 0) {
    //       var csess = this.sessionPollsList.find(x => x.pollTimeInSec <= this.vidObj.currentTime());
    //       var idx = this.sessionPollsList.findIndex(x => x.pollTimeInSec <= this.vidObj.currentTime());
    //       console.log("currentQue:" + csess);
    //       if (csess) {
    //         this.sessionPollsList.splice(idx, 1);
    //         this.popupopopen = true;
    //         this.pollData = csess;
    //         this.pollAnswerProgress = this.currentSessionData.sessionPollResponse;
    //         this.pollData.sessionPollOptions = this.pollData.sessionPollOptions.map(x => {
    //           const index = this.pollAnswerProgress.findIndex(y => y.uuid === x.uuid);
    //           if (index >= 0) {
    //             x.pollCount = this.pollAnswerProgress[index].pollCount;
    //           }
    //           return x;
    //         });
    //         this.interval = setInterval(() => {
    //           if (this.cnt2 > 0) {
    //             this.cnt2 = this.cnt2 - 1;
    //           } else {
    //             this.cnt2 = 30;
    //             this.popupopopen = false;
    //             if (this.vidObj.paused()) {
    //               this.vidObj.play();
    //             }
    //             clearInterval(this.interval);
    //           }
    //         }, 1000);
    //       }
    //     }
    //     this.getEventCurrentSequence();
    //   })
    // ).subscribe();
    this.appInsightService.logPageView('video player component loaded');
    this.commentForm = this.fb.group({
      commentText: ['']
    });
    this.form = this.fb.group({
      pollAnswer: ['']
    });
  }

  ngAfterViewInit() {
    this.playerRef = this.singlePlayerTarget.nativeElement;
    if (this.type === 'single') {
      this.initSinglePlayer();
      if (this.userData.pageName === 'SESSION_AUDI') {
        // this.startSession();
        //this.getCommentsHistory(this.currentSessionData.uuid);
        this.getSessionCommets(this.currentSessionData.uuid);
        this.getUserLikeStatus();
        // setTimeout(() => {
        //   this.vidObj.play();
        // }, 9000);
      }
    } else {
      this.vidObj = new videojs(this.target.nativeElement);
      this.initPlaylistPlayer();
      if (this.userData.pageName === 'SESSION_AUDI') {
        //this.getCommentsHistory(this.currentSessionData.uuid);
        this.getSessionCommets(this.currentSessionData.uuid);
        this.getUserLikeStatus();
      }
    }

    // if (!this.vidObj.paused()) {
    // this.skipVideoPlayDuration(this.currentSessionData.startDateTime);
    // }
  }

  getFeedbackStatus(sessionUUId) {
    const obj = {
      userUUID: this.userDetails.uuid,
      sessionUUID: sessionUUId
    };
    this.appInsightService.logEvent('Get feedback status web service call initiated');
    this.adminService.getFeedbackStatus(obj).subscribe(response => {
      this.feedbackStatus = response;
      this.appInsightService.logEvent('Feedback status response got successfully', { responseCode: response.status });
    });
  }


  getResources(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.resourceData = [];
      this.adminService.getAllResourceByClientUUID(this.userDetails.clientUUID).subscribe(response => {
        this.resourceData = response;
        resolve(true);
      });
    });
  }

  downloadfile(logoResourceUuid) {
    var url = this.apiBaseUrl + 'File/Download/' + logoResourceUuid;
    const link = document.createElement("a");
    link.href = url;
    link.click();
  }


  getSessionById(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.currentSessionData && this.currentSessionData.uuid) {
        this.adminService.getSession(this.currentSessionData.uuid).subscribe((res: any) => {
          // var sessionpollList = res.sessionPoll;
          // res.sessionPoll.forEach(element => {
          //   element.pollTime = moment.tz(element.pollTime, res.timeZone)["_d"];
          //   element.timestring = (element.pollTime).getHours() + ":" + (element.pollTime).getMinutes();
          //   var startDate =moment.tz(res.startDateTime, res.timeZone)["_d"];
          //   const diffTime = Math.abs(element.pollTime - startDate);
          //   element.duration = Math.ceil(diffTime / 1000 );
          // });
          resolve(res);
        });
      }
    });
  }

  initSinglePlayer() {
    this.sessionres = [];
    this.pollTimes = [];
    if (this.userData && this.userData.pageName === 'SESSION_AUDI') {
      this.currentSessionData = this.userData.sessionData;
      if (this.currentSessionData.sessionPollList && this.currentSessionData.sessionPollList.length > 0) {
        this.currentSessionData.sessionPoll = this.currentSessionData.sessionPollList[0];
      }
      var tempjson = this.currentSessionData['downladResource'] ? JSON.parse(this.currentSessionData['downladResource']) : "";
      if (tempjson && tempjson.length > 0) {
        this.getResources().then((res) => {
          if (res) {
            tempjson.forEach(element => {
              let index = this.resourceData.findIndex(x => x.uuid == element);
              if (index >= 0) {
                this.sessionres.push(this.resourceData[index]);
              }
            });
          }
        });
      }
      this.getSessionById().then((resp) => {
        if (resp) {
          this.sessionPollsList = resp.sessionPoll;
          console.log("respoll" + this.sessionPollsList);
        }
      });



      this.eventData.eventSequence = this.eventData.eventSequence.map(x => {
        if (x.uuid === this.currentSessionData.uuid) {
          x.active = true;
        } else {
          x.active = false;
        }
        return x;
      });
      sessionStorage.setItem('eventData', JSON.stringify(this.eventData));
      this.commonService.userEventData.next(this.eventData);
      this.options.fluid = true;
       this.options.techOrder =this.getVideoType(this.currentSessionData.sessionResourceVideoURL)=='video/mp4' ? ['html5', 'flash'] :['youtube'];
      this.options.sources = [
        {
          src: this.currentSessionData.sessionResourceVideoURL,
          type: this.getVideoType(this.currentSessionData.sessionResourceVideoURL)
         // type: 'video/youtube'
          //src:"http://techslides.com/demos/sample-videos/small.mp4",
         // type: this.getVideoType('http://techslides.com/demos/sample-videos/small.mp4')
        },
      ];
      this.options.autoplay = true;
    }
    // const elem = document.getElementById('singlePlayer');

    // Initialize player
    this.vidObj = new videojs(this.playerRef, this.options, () => {
      videojs.log('Your player is ready!');
      // if (this.userData.pageName === 'SESSION_AUDI') {
      //   this.skipVideoPlayDuration(this.currentSessionData.startDateTime);
      // }
      // if (this.vidObj.paused) {
      //   this.vidObj.play();
      // }
      this.appInsightService.logEvent('video player Ready');
      if (this.userDetails) {
        this.updateRegisterEvent();
        if (this.userDetails.uuid != "a88bcb52-2a9f-4d84-ab68-450337762706") {
          setTimeout(() => {
            this.sendWelcomeMessage();
          }, 2500);
        }
        //this.saveVideoAnalytics('On Player Ready');
        this.isPlayerReady = null;
      } else {
        const obj = {
          eventType: 'On Player Ready',
          currentTime: this.vidObj.currentTime(),
          totalDuration: this.vidObj.duration() ? this.vidObj.duration() : 0,
          remainingTime: 0,
          timeStamp: new Date().toLocaleString()
        };
        this.isPlayerReady = obj;

        var startdate = moment(this.userData.sessionData.startDateTime);
        var enddate = moment(this.userData.sessionData.endDateTime);

      }

    });
    this.vidObj.play();
    // this.createPlayerModal();
    // this.vidObj.on('loadedmetadata', () => {
    //   // this.vidObj.currentTime(10);

    // });




    this.vidObj.on('play', (data) => {
      console.log('407 play');
      this.startSession();
      this.vidObj.muted(false);
      this.vidObj.play();
      this.adminService.getAllSessionsByEventId(this.eventData.uuid).subscribe(response => {

        this.lastSession = response[response.length - 1].uuid;
        this.currentVideoSess = this.currentVideoSess ? this.currentVideoSess : this.currentSessionData;
        this.nextsessionData = response.filter(x => (x.startDateTime > this.currentVideoSess.endDateTime) && (x.uuid != this.currentVideoSess.uuid));
        this.nextsessionData = this.nextsessionData.sort((a, b) => new Date(a.startDateTime).getTime() - new Date(b.startDateTime).getTime());
        this.filterSession = this.nextsessionData.map(({ uuid, startDateTime, sessionResourceVideoURL, endDateTime, sessionPollsList, sessionPoll }) => ({ uuid, startDateTime, sessionResourceVideoURL, endDateTime, sessionPollsList, sessionPoll }));
      });
      //this.saveVideoAnalytics(data.type);
      if (this.subscription) {
        this.subscription.unsubscribe();
      }

      // if (this.popupopentime && this.pollData == undefined) {
      //   setTimeout(() => {
      //     this.vidObj.pause();
      //     this.popupopopen = true;
      //     this.interval = setInterval(() => {
      //       this.cnt2 = this.cnt2 - 1;
      //     }, 1000);
      //     if (this.currentSessionData.sessionPoll) {
      //       this.pollData = this.currentSessionData.sessionPoll;
      //       this.pollAnswerProgress = this.currentSessionData.sessionPollResponse;
      //       this.pollData.sessionPollOptions = this.pollData.sessionPollOptions.map(x => {
      //         const index = this.pollAnswerProgress.findIndex(y => y.uuid === x.uuid);
      //         x.pollCount = this.pollAnswerProgress[index].pollCount;
      //         return x;
      //       });
      //     }
      //     setTimeout(() => {
      //       if (this.popupopopen) {
      //         this.popupopopen = false;
      //         this.vidObj.play();
      //         if (this.interval) {
      //           clearInterval(this.interval);

      //           this.cnt2 = 30;
      //         }
      //       }
      //     }, 30000);

      //   }, this.popupopentime * 1000);
      // }
    });



    this.vidObj.on('pause', (data) => {
      //this.saveVideoAnalytics(data.type);
      this.saveVideoPlayedDuration('pause');
    });

    this.vidObj.on('ended', (data) => {
      //this.saveVideoAnalytics(data.type);
      if ((this.currentVideoSess.sessionPollList && this.currentVideoSess.sessionPollList.length > 0) || (this.currentVideoSess.sessionPoll && this.currentVideoSess.sessionPoll.length > 0)) {
        this.popupopopen = true;
        this.pollData = this.currentVideoSess.sessionPollList ? this.currentVideoSess.sessionPollList[0] : this.currentVideoSess.sessionPoll[0];
        if (this.vidObj.isFullscreen()) {
          this.vidObj.exitFullscreen();
        }
        this.interval = setInterval(() => {
          if (this.cnt2 > 0) {
            this.cnt2 = this.cnt2 - 1;
          } else {
            this.cnt2 = 30;
            this.popupopopen = false;
            if (this.lastSession == this.currentVideoSess.uuid) {
              this.router.navigateByUrl('/live-event-end');
            }
            else {
              this.playNextVideo();
            }
            clearInterval(this.interval);
          }
        }, 1000);
      } else {
        if (this.lastSession == this.currentVideoSess.uuid) {
          this.router.navigateByUrl('/live-event-end');
        }
        else {
          this.playNextVideo();
        }
      }


      if (this.userData.pageName === 'SESSION_AUDI') {

        // this.vidObj.dispose();
        this.subscription = interval(1 * 60 * 1000)
          .subscribe(() => {
            if (this.eventData) {
              this.getEventCurrentSequence();
            }
          });
      }

      if (this.userData.pageName === 'how-to-guide') {
        this.openMeetingModal('single');
      }

      // this.router.navigateByUrl('/live-event-end');
    });
  }

  playNextVideo(): void {
    let curSession = this.filterSession.find(x => ((new Date(x.startDateTime).getTime() - new Date(this.currentVideoSess.endDateTime).getTime()) / 1000) <= 120);
    //let curSession = this.filterSession.find(x=>this.isSessionStarted(x.startDateTime)<120);
    const typevideo = this.getVideoType(curSession.sessionResourceVideoURL)=='video/mp4' ? ['html5', 'flash'] :['youtube'];
    this.vidObj.options_.techOrder = typevideo;
    this.vidObj.options.techOrder = typevideo;
    this.vidObj.options_.playerOptions.techOrder = typevideo;
    this.vidObj.src({
      src: curSession.sessionResourceVideoURL,
      type: this.getVideoType(curSession.sessionResourceVideoURL),
    });
    this.currentVideoSess = curSession;
    // Increment the index to play the next video
    this.vidObj.play();
  }

  initPlaylistPlayer() {
    const playList = [];
    this.userData.sessionData.find(list => {
      const dur = list.duration.split(' ');
      const obj = {
        uuid: list.uuid,
        name: list.title,
        description: list.description,
        startTime: list.startTime,
        startDateTime: list.startDateTime,
        endDateTime: list.endDateTime,
        endTime: list.endTime,
        durationString: list.duration,
        duration: dur[0] * 60,
        onTime: false,
        isMain: list.isMain,
        showLikeDislike: list.showLikeDislike,
        sponsorName: list.sponsorName,
        showDemo: list.showDemo,
        resourceUUID: list.sessionResourceVideoUUID,
        sources: [
          // { src: "https://www.youtube.com/watch?v=8p5X3C4jVHg", type: 'video/youtube' },
          { src: list.sessionResourceVideoURL, type: this.getVideoType(list.sessionResourceVideoURL) },
          // { src:"http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
          // type: 'video/mp4'
        //}
        ],
        thumbnail: [
          {
            // tslint:disable: max-line-length
            srcset: list.sessionResourceVideoThumbnailUuid ? this.getResourceUrl(list.sessionResourceVideoThumbnailUuid) : '../../../assets/play_button.png',
            type: 'image/jpeg',
            media: '(min-width: 400px;)'
          },
          {
            src: list.sessionResourceVideoThumbnailUuid ? this.getResourceUrl(list.sessionResourceVideoThumbnailUuid) : '../../../assets/play_button.png'
          }
        ]
      };
      playList.push(obj);
    });
    // Initialize the playlist-ui plugin with no option (i.e. the defaults).
    this.vidObj.playlist(playList);

    this.vidObj.playlistUi();

    // this.vidObj.playlist.autoadvance(3);

    this.currentSessionData = playList[0];

    this.loadedPlayList = this.vidObj.playlist();

    this.vidObj.on('ready', (data) => {
      console.log('player ready state');
    });

    this.vidObj.on('beforeplaylistitem', (data) => {
      // console.log('player change state');
      // const nextPlayListItem = this.vidObj.playlist.nextIndex();
      // this.currentSessionData = this.loadedPlayList[nextPlayListItem];
    });

    this.vidObj.on('playlistitem', (data) => {
      // console.log(data);
      const currentPlayListItem = this.vidObj.playlist.currentItem();
      this.currentSessionData = this.loadedPlayList[currentPlayListItem];
      //this.getCommentsHistory(this.currentSessionData.uuid);
      this.getSessionCommets(this.currentSessionData.uuid);
      this.getUserLikeStatus();
    });

    this.vidObj.on('play', (data) => {
      const currentPlayListItem = this.vidObj.playlist.currentItem();
      this.playAccessCheck(this.loadedPlayList[currentPlayListItem]);
      this.currentSessionData = this.loadedPlayList[currentPlayListItem];
      this.getFeedbackStatus(this.currentSessionData.uuid);
      //this.saveVideoAnalytics(data.type);
    });

    this.vidObj.on('pause', (data) => {
      //this.saveVideoAnalytics(data.type);
    });

    this.vidObj.on('ended', (data) => {
      //this.saveVideoAnalytics(data.type);
      this.saveVideoPlayedDuration('ended');
      const currentIndex = this.vidObj.playlist.currentIndex();
      const lastIndex = this.vidObj.playlist.lastIndex();
      const currentPlayListItem = this.vidObj.playlist.currentItem();
      const isMain = this.loadedPlayList[currentPlayListItem].isMain;
      const showLikeDislike = this.loadedPlayList[currentPlayListItem].showLikeDislike;
      const showDemo = this.loadedPlayList[currentPlayListItem].showDemo;

      if (showLikeDislike && this.feedbackStatus) {
        this.openFeedbackModal(data.type);
      }
      if (showDemo) {
        this.openMeetingModal('multiple');
      }
    });

  }


  getEventCurrentSequence() {
    this.commonService.getEventCurrentSequence(this.eventData.uuid).subscribe(response => {
      if (response && response.length) {
        if (response[0].type === 'SESSION') {
          if (response[0].uuid !== this.currentSessionData.uuid) {
            this.options.autoplay = true;
            this.userData.sessionData = response[0];
            this.currentSessionData = this.userData.sessionData;
            this.reloadPage();
          }
        } else {
          this.router.navigateByUrl('/view/breakout');
        }
      } else {

      }
    });
  }

  reloadPage() {
    this.router.navigateByUrl('/view/refreshComponent', { skipLocationChange: true }).then(() => {
      this.router.navigate(["/view/audi"]);
    });
  }

  playAccessCheck(data: any) {
    this.appInsightService.logEvent(`Check video started time to play web service init`, data);
    const obj = {
      uuid: data.uuid,
      email: this.userDetails.emailId
    };
    this.adminService.joinSession(obj).subscribe(response => {
      this.appInsightService.logEvent('Player track data web service response successfully', { responseCode: response.status });
      const currentPlayListItem = this.vidObj.playlist.currentItem();
      this.loadedPlayList[currentPlayListItem].onTime = true;
      this.vidObj.play();
    }, err => {
      const currentPlayListItem = this.vidObj.playlist.currentItem();
      this.loadedPlayList[currentPlayListItem].onTime = false;
      this.vidObj.pause();
      this.errorMsg = err.error.DataValidationErrors.error;
      setTimeout(() => {
        this.errorMsg = null;
      }, 3000);
    });
  }

  skipVideoPlayDuration(startDateTime) {
    let st = startDateTime.replace('T', ' ');
    st = st + ' UTC';
    let startTime = new Date(st).getTime();
    // console.log('Start time: ' + startTime);
    let currentTime = Date.now();
    // console.log('Current time: ' + currentTime);
    let diffDuration = currentTime - startTime;
    // console.log('Difference duration: ' + new Date(diffDuration).getTime());
    let seconds = diffDuration / 1000;
    this.vidObj.currentTime(seconds);
  }

  isSessionStarted(startDateTime) {
    let st = startDateTime.replace('T', ' ');
    st = st + ' UTC';
    let startTime = new Date(st).getTime();
    // console.log('Start time: ' + startTime);
    let currentTime = Date.now();
    // console.log('Current time: ' + currentTime);
    let diffDuration = startTime - currentTime;
    // console.log('Difference duration: ' + new Date(diffDuration).getTime());
    let seconds = diffDuration / 1000;
    return seconds;
  }

  createPlayerModal() {
    // tslint:disable-next-line: max-line-length
    let modal_content = '<form class="wrapper"><h4>Would you like to join a raffle to win a one to one 30 minute session with Mark Bush?</h4><button type="button" mat-raised-button class="btn btn-primary" (click)="submitRaffleQuestion($event)" id="raffleBtn">Go</button></form>';
    const contentEl = document.createElement('div');
    // probably better to just build the entire thing via DOM methods
    contentEl.innerHTML = modal_content;
    // Create modal to display over player
    const ModalDialog = videojs.getComponent('ModalDialog');

    // console.log(ModalDialog.el());
    this.modal = new ModalDialog(this.vidObj, {
      content: contentEl,
      // We don't want this modal to go away when it closes.
      temporary: false,
    });
    this.vidObj.addChild(this.modal);

  }

  sendWelcomeMessage() {
    this.commonService.loginData.subscribe(x => {
      this.loginData = x;
    });
    let sessionUUID = this.currentSessionData.uuid;
    this.message = new Message();
    this.message.sessionUUID = sessionUUID;
    this.message.userUUID = "a88bcb52-2a9f-4d84-ab68-450337762706";
    this.message.firstName = "Road To";
    this.message.lastName = "Business Success";
    this.message.type = "sent";
    this.message.toUserId = this.loginData.uuid;
    if (this.userDetails.businessName && this.userDetails.businessName != "null" && this.userDetails.businessName != null && this.userDetails.businessName != "") {
      this.message.commentText = "Welcome " + this.userDetails.businessName
    } else {
      //this.message.commentText = "Welcome "+ this.userDetails.firstName
      let name = this.businessName ? this.businessName : this.userDetails.firstName;
      this.message.commentText = "Welcome " + name;

    }
    // if(this.userDetails.emailId.indexOf('guestuser')>=0 && this.userDetails.emailId.indexOf('@yopmail.com')>=0){
    //   this.userDetails.firstName = this.userDetails.firstName.replace('@yopmail.com','');
    //   this.message.commentText = "Welcome "+this.userDetails.firstName;
    // }else{
    //   this.message.commentText = "Welcome "+this.userDetails.firstName;
    // }
    if (!this.userDetails.firstName.includes('GuestUser')) {
      this.chatService.sendMessage(this.message);
      this.txtMessage = '';
    }
  }

  updateRegisterEvent() {
    let registerEventData = JSON.parse(sessionStorage.getItem('RegisterEventData'));
    if (registerEventData) {
      const formData: any = new FormData();
      formData.append('FirstName', registerEventData.FirstName);
      formData.append('LastName', registerEventData.LastName);
      formData.append('EmailId', registerEventData.EmailId);
      formData.append('PhoneNo', registerEventData.PhoneNo);
      formData.append('Comments', registerEventData.Comments);
      formData.append('EventUUID', registerEventData.EventUUID);
      formData.append('BusinessName', registerEventData.BusinessName);
      formData.append('UserId', JSON.parse(sessionStorage.getItem('userData')).id);
      this.eventService.UpdateRegisterViaEvent(formData).subscribe((response) => {
        if (response && response['uuid']) { }
      });
    }
  }
  saveVideoPlayedDuration(eventType) {
    let resourceUUID = this.currentSessionData ? this.currentSessionData.sessionResourceVideoUUID : this.userData.resourceUUID;
    const obj: ITrackVideoData = {
      eventUUID: this.eventData.uuid,
      emailId: this.userDetails.emailId,
      userUUID: this.userDetails.uuid,
      resourceUUID,
      eventType,
      currentTime: this.sessionStartCount,
      totalDuration: this.vidObj.duration() ? this.vidObj.duration() : 0,
      remainingTime: this.vidObj.remainingTime(),
      timeStamp: new Date().toLocaleString()
    };
    this.adminService.saveVideoAnalytics(obj).subscribe(response => {
      if (response) {
        this.appInsightService.logEvent('Player track data web service response successfully', { responseCode: response.status });
      }
    });
  }
  saveVideoAnalytics(eventType) {
    let resourceUUID = this.currentSessionData ? this.currentSessionData.sessionResourceVideoUUID : this.userData.resourceUUID;
    const obj: ITrackVideoData = {
      eventUUID: this.eventData.uuid,
      emailId: this.userDetails.emailId,
      userUUID: this.userDetails.uuid,
      resourceUUID,
      eventType,
      currentTime: this.vidObj.currentTime(),
      totalDuration: this.vidObj.duration() ? this.vidObj.duration() : 0,
      remainingTime: this.vidObj.remainingTime(),
      timeStamp: new Date().toLocaleString()
    };
    this.appInsightService.logEvent(`video ${eventType} event fired`, obj);

    if (obj.eventType !== 'On Player Ready') {
      this.adminService.saveVideoAnalytics(obj).subscribe(response => {
        if (response) {
          this.appInsightService.logEvent('Player track data web service response successfully', { responseCode: response.status });
        }
      });
    } else {
      this.vidObj.play();
    }
  }

  openMeetingModal(playerType) {
    let data: any;
    if (playerType === 'multiple') {
      const currentPlayListItem = this.vidObj.playlist.currentItem();
      data = this.loadedPlayList[currentPlayListItem];
    } else {
      data = this.userData.sessionData;
    }
    this.appInsightService.logEvent('Book a meeting modal initiate for Careonomy.');
    // const dialogRef = this.dialog.open(MeetingScheduleComponent, {
    //   width: '80%',
    //   disableClose: false,
    //   panelClass: 'meeting-dialog',
    //   data: {
    //     category: data.name,
    //     productType: data.sponsorName
    //   }
    // });

    // dialogRef.afterClosed().subscribe(result => {
    //   // console.log('The dialog was closed' + result);
    // });
  }

  openLikeModal() {
    // this.appInsightService.logEvent('Like video modal initiate.');
    // const dialogRef = this.dialog.open(LikeVideoComponent, {
    //   width: '300px',
    //   disableClose: false,
    //   data: { }
    // });

    // dialogRef.afterClosed().subscribe(result => {
    //   // console.log('The dialog was closed' + result);
    // });
  }

  openFeedbackModal(type) {
    const currentPlayListItem = this.vidObj.playlist.currentItem();
    const data = this.loadedPlayList[currentPlayListItem];
    this.appInsightService.logEvent('Session video feedback modal initiate.');
    // const dialogRef = this.dialog.open(SessionFeedbackModalComponent, {
    //   width: '600px',
    //   maxWidth: '80%',
    //   disableClose: false,
    //   data: {
    //     sessionUUID: data.uuid,
    //     userUUID: this.userDetails.uuid,
    //     type,
    //   }
    // });

    // dialogRef.afterClosed().subscribe(result => {
    //   // console.log('The dialog was closed' + result);
    // });
  }

  convertTime12Hrs(date, time) {
    return this.commonFunctions.convertTime12Hrs(date, time);
  }


  getResourceUrl(logoResourceUuid) {
    let url = '../../../assets/no-image.png';
    if (logoResourceUuid) {
      url = this.apiBaseUrl + 'File/Download/' + logoResourceUuid;
    }
    return url;
  }

  convertDateTimeToTimezone(date, type) {
    let stdString = date.split('T');
    let nd = stdString[0].replace(/-/g, "/");
    let sdt: string = nd + ' ' + stdString[1] + ' UTC';
    let convertedDate: any = new Date(sdt);
    let newDate = (convertedDate.getMonth() + 1) + '/' + convertedDate.getDate() + '/' + convertedDate.getFullYear();
    let newTime = convertedDate.getHours() + ':' + convertedDate.getMinutes();
    if (type === 'date') {
      return newDate;
    } else {
      return this.commonFunctions.convertTime12Hrs(newDate, newTime);
    }
  }

  onSubmit(formData) {
    const obj = {
      sessionUUID: this.currentSessionData.uuid,
      userUUID: this.userDetails.uuid,
      commentText: formData.commentText
    }
    this.adminService.postComment(obj).subscribe(response => {
      setTimeout(() => this.formGroupDirective.resetForm(), 0);
      this.commentResponse = {
        code: 200,
        message: 'Thank you! your comment is posted successfully.'
      };
      setTimeout(() => {
        this.commentResponse = false;
      }, 3000);
    }, err => {
      this.commentResponse = {
        code: err.status,
        message: 'Please try again'
      };
      setTimeout(() => {
        this.commentResponse = false;
      }, 3000);
    });
  }

  // comments
  sendMessage(): void {
    if (this.txtMessage.trim()) {
      let sessionUUID = this.currentSessionData.uuid;
      this.message = new Message();
      this.message.sessionUUID = sessionUUID;
      this.message.userUUID = this.userDetails.uuid;
      this.message.firstName = this.userDetails.firstName;
      this.message.lastName = this.userDetails.lastName;
      this.message.type = "sent";
      this.message.commentText = this.txtMessage;
      this.message.toUserId = this.loginData.uuid;
      // this.message.date = new Date();  
      // this.messages.push(this.message);  
      // console.log(this.message);
      this.chatService.sendMessage(this.message);
      this.txtMessage = '';
    }
  }

  private subscribeToEvents(): void {
    this.chatService.messageReceived.subscribe((message: any) => {
      this._ngZone.run(() => {
        if (message) {
          let filtermsg = message.filter((x: any) => x.sessionUuid == this.currentSessionData.uuid);
          if (filtermsg && filtermsg.length > 0) {
            this.sessionCommets = filtermsg;
            this.chatService.refreshChatData.next(filtermsg);
            this.refreshLeaderBoard();
          }
        }
      });
    });
  }

  getCommentsHistory(sessionUUID) {
    this.commonService.getCommentsHistory(sessionUUID).subscribe(response => {
      this.messages = response.map(x => {
        x.type = 'received';
        return x;
      });
    }, err => {
      this.messages = [];
    });
  }

  getSessionCommets(sessionUUID) {
    this.adminService.GetSessionCommmetsWithAns(sessionUUID).subscribe(async (res: any) => {
      if (res) {
        for (let index = 0; index < res.length; index++) {
          var element = res[index];
          element.isReplay = false;
          element.isAns = false;
        }
        this.sessionCommets = res;
      }
    })
  }


  convertTimeToLocalString(dateTimeString) {
    const d = dateTimeString.split('T');
    let nd = d[0].replace(/-/g, "/");
    const date = new Date(nd + ' ' + d[1] + ' UTC').toLocaleString('en-US', {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
      hour12: true,
      hour: '2-digit',
      minute: '2-digit',
    });
    return date;
  }

  getLetter(firstName) {
    return firstName.charAt(0);
  }

  addEmoji(event) {
    this.txtMessage = `${this.txtMessage}${event.emoji.native}`;
    this.isEmojiPickerVisible = false;
  }

  getUserLikeStatus() {
    const obj = {
      userUUID: this.userDetails.uuid,
      sessionUUID: this.currentSessionData.uuid,
    };
    this.adminService.getUserLikeStatus(obj).subscribe(response => {
      this.liked = response;
    });
  }

  favoriteClickEvent() {
    if (!this.liked) {
      this.liked = !this.liked
      const obj = {
        likeSession: true,
        sessionUUID: this.currentSessionData.uuid,
        userUUID: this.userDetails.uuid
      };
      this.adminService.saveUserLike(obj).subscribe(response => {
        this.liked = response;
      });
    }
  }

  getSessionAnswerResponse() {
    this.commonService.getUserSessionPollResponse(this.eventData.uuid, this.currentSessionData.uuid).subscribe(response => {
      this.pollAnswerProgress = response;
    });
  }

  getOptionValue(itemPollCount: number) {
    const pollResponseData = this.pollAnswerProgress ? this.pollAnswerProgress : null;
    let totalCounts = pollResponseData.reduce((sum, item) => sum + item.pollCount, 0);
    let countPercentage = itemPollCount / totalCounts * 100;
    return countPercentage;
  }

  onSubmitPoll(formData) {
    const obj = {
      "eventUUID": this.eventData.uuid,
      "sessionUUID": this.currentSessionData.uuid,
      "pollUUID": this.pollData.uuid,
      "pollOptionUUID": formData.pollAnswer,
      "userUUID": this.userDetails.uuid
    };
    this.commonService.savePollAnswer(obj).subscribe(response => {
      this.pollMessage = 'Thank you! Your response has been submitted successfully.'
      setTimeout(() => {
        this.pollMessage = null;
        this.popupopopen = false;
        this.cnt2 = 30;
        if (this.lastSession == this.currentVideoSess.uuid) {

          this.router.navigateByUrl('/live-event-end');
        }
        else {
          this.playNextVideo();
        }
        clearInterval(this.interval);
      }, 1000);
    }, err => {
      this.pollMessage = err.error.DataValidationErrors.error;
      setTimeout(() => {
        this.pollMessage = null;
        this.popupopopen = false;
        this.cnt2 = 30;
        if (this.lastSession == this.currentVideoSess.uuid) {
          this.router.navigateByUrl('/live-event-end');
        }
        else {
          this.playNextVideo();
        }
        clearInterval(this.interval);

      }, 1000);
    });
  }

  ngOnDestroy() {
    this.saveVideoPlayedDuration('ended');
    // destroy player
    if (this.vidObj) {
      this.vidObj.dispose();
      this.appInsightService.logEvent('video player destory event fired');
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    // this.timerSubscription.unsubscribe();
  }

  onReplayClick(replay: any) {
    replay.isReplay = !replay.isReplay;
    return replay;
  }
  onReplayView(replay: any) {
    replay.isAns = !replay.isAns;
    return replay;
  }
  onAnsReplay(replay: any, ans: any) {
    debugger;
    if (ans.trim()) {
      this.itemAns = new AnalyticsCommetsAnsViewModel();
      this.itemAns.commentUuid = replay.uuid;
      this.itemAns.sessionUuid = this.currentSessionData.uuid;
      this.itemAns.userUuid = this.userDetails.uuid;
      this.itemAns.ansText = ans;
      this.chatService.sendCommentsReplayMessage(this.itemAns);
    }
    // this.adminService.SaveSessionCommmetsWithAns(this.itemAns).subscribe((response: any) => {
    //   this.getSessionCommets(this.currentSessionData.uuid);
    // })
  }

  startSession() {
    this.sessioninterval = setInterval(() => {
      if (this.sessionStartCount >= 0) {
        this.sessionStartCount = this.sessionStartCount + 1;
      }
    }, 1000);
  }

  refreshLeaderBoard() {
    if (this.eventData && this.eventData.uuid) {
      const obj = {
        eventUUID: this.eventData.uuid,
      };
      this.chatService.getPointsByEventRfresh(obj);
    }
  }

  playEvent(event: any): void {
    console.log("Play " + event);
  }

  getVideoType(videoURL : string):string{

if(videoURL.includes('.mp4'))
{
return 'video/mp4'
  }
  else{
    return 'video/youtube';
  }
}

}