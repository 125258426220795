import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { EnvironmentService, PublicRestService } from 'pck-api';
import { PodcastModel } from '../model/podcast-model';
import { podcastPlayerModel } from '../model/podcast-player-model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PodcastService extends PublicRestService {
  private payloadUrl: string | undefined;
  constructor(
    @Inject(HttpClient) http: HttpClient,
    @Inject(EnvironmentService) public environmentData: EnvironmentService
  ) {
    super(http);
  }

  getPodcastList(): Promise<PodcastModel[] | any> {
    this.payloadUrl = this.environmentData.serviceUrl + 'Podcast/GetAll';
    return super.get<any>(this.payloadUrl);
  }

  savePodcastAnalytic(audioPlayer : podcastPlayerModel): Observable<any> {
    this.payloadUrl = this.environmentData.serviceUrl + 'PodcastAnalytics/PodcastSave';
    return this.http.post(this.payloadUrl, audioPlayer).pipe();
  }
}
