import { Injectable } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { EnvironmentService } from "pck-api";

@Injectable({
    providedIn: 'root'
})
export class CommonMethod {

    constructor(private environmentService: EnvironmentService,
        private modalService: NgbModal) { }

    getAttachmentUrl(logoResourceUuid: string): string {
        let url = '../../../assets/no-image.png';
        if (logoResourceUuid) {
            url = this.environmentService.serviceUrl + 'File/Download/' + logoResourceUuid;
        }
        return url;
    }

    openModalPopup(content: any, size: string): void {
        this.modalService.open(content, { size: size, scrollable: true });
    }
}
