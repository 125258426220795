import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PodcastCardComponent } from './component/podcast-card/podcast-card.component';
import { VideoCardComponent } from './component/video-card/video-card.component';
import { EventCardComponent } from './component/event-card/event-card.component';
import { CommonMethod } from './service/common-method';
import { SimplebarAngularModule } from 'simplebar-angular';
import { ChannelService } from './service/channel.service';
import { VideoService } from './service/video.service';
import { PodcastService } from './service/podcast.service';
import { EventService } from './service/event.service';
import { UserService } from './service/user.service';
import { CommonService } from './service/common.service';
import { AdminService } from './service/admin.service';
import { AppInsightService } from './service/appinsight.service';
import { CommonFunctions } from './service/common-functions';
import { LiveSessionService } from './service/livesession.service';
import { SignalRChatService } from './service/signal-r-chat.service';
import { VideojsComponent } from './component/videojs/videojs.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import {MatTabsModule} from '@angular/material/tabs';
import { EventlandingComponent } from './component/eventlanding/eventlanding.component';


@NgModule({
  declarations: [
    VideoCardComponent, PodcastCardComponent, EventCardComponent,VideojsComponent, EventlandingComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SimplebarAngularModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    PickerModule,
    MatButtonToggleModule,
    MatToolbarModule,
    MatRadioModule,
    MatProgressBarModule,
    MatButtonModule,
    MatIconModule,
    MatTabsModule
  
  ],
  exports: [
    VideoCardComponent,
    PodcastCardComponent,
    EventCardComponent,
    VideojsComponent,
    EventlandingComponent
    
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PckGeneralComponentsModule {
  constructor(
    @Optional() @SkipSelf() parentModule: PckGeneralComponentsModule) {
    if (parentModule) console.log("GeneralComponentsModule loaded");
  }

  static forRoot(): ModuleWithProviders<PckGeneralComponentsModule> {
    return {
      ngModule: PckGeneralComponentsModule,
      providers: [CommonMethod, ChannelService, VideoService,SignalRChatService, PodcastService,EventService,UserService, CommonService, AdminService, AppInsightService, CommonFunctions, LiveSessionService],
    };
  }

  static forChild(): ModuleWithProviders<PckGeneralComponentsModule> {
    return {
      ngModule: PckGeneralComponentsModule,
    };
  }
}
