import { EventEmitter, Injectable } from '@angular/core';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class CommonFunctions {

  playSessionData = new EventEmitter();
  // activeAudiData = new BehaviorSubject(JSON.parse(sessionStorage.getItem('activeAudiData')));
  currentAudiData: any;
  eventData: any;

  constructor(public commonService: CommonService) {
    this.commonService.userEventData.subscribe(event => {
      if (event) {
        this.eventData = event;
      }
    });
    // this.activeAudiData.subscribe(data => {
    //   this.currentAudiData = data;
    //   if (!this.currentAudiData) {
    //     this.setUpNextSessionData(); 
    //   }
    // });
  }

  // Convert Date format in MM/DD/YYYY string
  convertDate(dateTimeString) {
    const d = new Date(dateTimeString);
    const date = (d.getMonth() + 1) + '/' + d.getDate() + '/' + d.getFullYear();
    return date;
  }

  // Convert Time format in 24 HRS string
  convertTime(dateTimeString) {
    return new Date(dateTimeString).getHours() + ':' + new Date(dateTimeString).getMinutes();
  }

  convertUtcDateTime(dateString, timezone, type) {
    const dateInTz: any = new Date(dateString).toLocaleString("en-US", { timeZone: timezone, timeZoneName: 'short' });
    const tzShort = dateInTz.split(' ');

    let sd = dateString.getFullYear() + '/' + (dateString.getMonth() + 1) + '/' + dateString.getDate();
    const st = dateString.getHours() + ':' + dateString.getMinutes();
    const currentTz = new Date(Date.parse(sd + ' ' + st + ' ' + tzShort[3])).toLocaleString('en-US', { hour12: false, timeZone: timezone, timeZoneName: 'short' });
    const d1 = new Date(currentTz);
    const isoString = new Date(currentTz).toISOString();
    let utcDate = (d1.getUTCMonth() + 1) + '/' + d1.getUTCDate() + '/' + d1.getUTCFullYear();
    let utcTime = d1.getUTCHours() + ':' + d1.getUTCMinutes();
    if (type === 'date') {
      return utcDate;
    } else {
      return utcTime;
    }
  }

  convertToTimezone(dateString: any, timezone: any) {
    if (dateString !== null) {
      let stdString = dateString.split('T');
      let d = stdString[0].replace(/-/g, "/");
      let sdt: string = d + ' ' + stdString[1] + ' UTC';
      let currentTz = new Date(sdt).toLocaleString('en-US', { hour12: false, timeZone: timezone, timeZoneName: 'short' });
      let std = new Date(currentTz);
      // console.log(std);
      return std;
    } else {
      return null;
    }
  }

  convertToTimezoneString(dateString, timezone) {
    if (dateString !== null) {
      let stdString = dateString.split('T');
      let d = stdString[0].replace(/-/g, "/");
      let sdt: string = d + ' ' + stdString[1] + ' UTC';
      let currentTz = new Date(sdt).toLocaleString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true, timeZone: timezone });
      // let std = new Date(currentTz);
      return currentTz;
    } else {
      return null;
    }
  }

  convertTime12Hrs(date, time) {
    const dateTime = new Date(date + ' ' + time);
    return dateTime.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
  }

  // Calculate time difference from 2 date time string
  diff_minutes(dt2, dt1) {
    dt2 = new Date(dt2);
    dt1 = new Date(dt1);
    let diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    let time: any = Math.abs(Math.round(diff));
    time = this.convertMinsToHrsMins(time);
    return time;
  }

  convertMinsToHrsMins(mins) {
    let h: any = Math.floor(mins / 60);
    let m: any = mins % 60;
    h = h < 10 ? '0' + h : h;
    m = m < 10 ? '0' + m : m;
    return `${h}:${m}`;
  }
  // page scroll to top
  scrollToTop() {
    const scrollToTop = window.setInterval(() => {
      const pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
  }

}
