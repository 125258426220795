import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { EnvironmentService, PublicRestService } from 'pck-api';
import { VideoModel } from '../model/video-model';
import { Observable } from 'rxjs';
import { videoPlayerModel } from '../model/video-player-model';

@Injectable({
  providedIn: 'root'
})
export class VideoService extends PublicRestService {
  private payloadUrl: string | undefined;
  constructor(
    @Inject(HttpClient) http: HttpClient,
    @Inject(EnvironmentService) public environmentData: EnvironmentService
  ) {
    super(http);
  }

  getVideoList(): Promise<VideoModel[] | any> {
    this.payloadUrl = this.environmentData.serviceUrl + 'ChannelVideo/GetAll';
    return super.get<VideoModel[]>(this.payloadUrl);
  }

  saveVideoYoutubeAnalytics(videplayer : videoPlayerModel): Observable<any> {
    this.payloadUrl = this.environmentData.serviceUrl +'SavePlayerVideoData/VideoSave';
    return this.http.post(this.payloadUrl, videplayer).pipe();
  }

}
