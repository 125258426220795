import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { EnvironmentService, PublicRestService } from 'pck-api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventService extends PublicRestService {
  private payloadUrl: string | undefined;
  constructor(
    @Inject(HttpClient) http: HttpClient,
    @Inject(EnvironmentService) public environmentData: EnvironmentService
  ) {
    super(http);
  }


  getAllEvents(): Promise<any> {
    this.payloadUrl = this.environmentData.serviceUrl + 'Event/GetAll';
    return super.get<any>(this.payloadUrl);
  }

  getEventByUUID(EventUUID: string) {
    this.payloadUrl = this.environmentData.serviceUrl + `Event/Get/${EventUUID}`;
    return super.get<any>(this.payloadUrl);
  }


  getAllSessionByEvent(uuid: string): Promise<any> {
    this.payloadUrl = this.environmentData.serviceUrl + `Session/GetAllSessionsByEvent/${uuid}`;
    return super.get<any>(this.payloadUrl);
  }

  RegisterViaEvent(requestData: any): Observable<any> {
    this.payloadUrl = this.environmentData.serviceUrl + `RegisterEvent/Create`;
    return this.http.post(this.payloadUrl, requestData).pipe();
  }

  
  UpdateRegisterViaEvent(requestData: any): Observable<any> {
    this.payloadUrl = this.environmentData.serviceUrl + `RegisterEvent/Update`;
    return this.http.put(this.payloadUrl, requestData).pipe();
  }


  GetRegisterUser(requestData: any): Observable<any> {
    this.payloadUrl = this.environmentData.serviceUrl + `RegisterEvent/GetRegisterUser`;
    return this.http.post(this.payloadUrl, requestData).pipe();
  }

  getEventByChannelId(channelUUID: string): Promise<any> {
    this.payloadUrl = this.environmentData.serviceUrl + `Event/GetEventsByChannel/${channelUUID}`;
    return super.get<any>(this.payloadUrl);
  }
}