<div class="scroll-bar">
    <div class="row" *ngIf="eventList">
        <div *ngFor="let item of eventList" class="{{item.classStyle}} col-12 pb-20 event-card">
            <div class="item portfolio-thumb" (click)="openEvent(item)">
                <a href="javascript: void(0)">
                    <img class="img-fluid portfolio-image min-h-227"
                        [src]="item.eventResourceCardThumbnailUuid ? getAttachmentUrl(item.eventResourceCardThumbnailUuid) : 'assets/images/video-item-bg.png'">
                    <div class="card-overlay"></div>
                </a>
                <!-- <img class="img-fluid portfolio-image min-h-227"  [src]="item.eventResourceVideoThumbnailUuid ? getAttachmentUrl(item.eventResourceVideoThumbnailUuid) : 'assets/images/video-item-bg.png'"> -->
                <div class="down-content">
                    <h4 class="evt-desc" style="cursor:pointer;" data-toggle="tooltip" data-placement="left" [title]="item.title">{{item.title}}</h4>
                    <h6>{{item.actualStartTime}} {{item.timezone}}</h6>
                    <p class="evt-desc">{{item.description}}</p>
                    <div class="author-image">
                        <img [src]="getAttachmentUrl('6dd51b65-f172-46d8-a9a9-d41c0f461c51')" alt="Author 2">
                    </div>
                    <div class="text-button-free">
                        <a  style="padding-right: 30px;">Online <i class="fa fa-angle-double-right"></i></a>
                        <a >Free <i class="fa fa-angle-double-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>