import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
// import { ErrorDialogComponent } from '../common/dialog/error-dialog/error-dialog.component';
import { AppInsightService } from './appinsight.service';
import { CommonService } from './common.service';
import { environment } from '../../environment';

@Injectable({
  providedIn: 'root'
})
export class LiveSessionService {
  private baseUrl = environment.dotNetApiEndpoint;
  private payloadUrl: string;
  loginData: any = new BehaviorSubject('');

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    observe: 'response' as 'body'
  };
  constructor(private http: HttpClient, private appInsightService: AppInsightService, private commonService: CommonService) {
  }

  create(requestData): Observable<any> {
    this.payloadUrl = `${this.baseUrl}LiveSession/Create`;
    return this.http.post(this.payloadUrl, requestData).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        this.commonService.handelErrors(err);
        throw err;
      })
    );
  }

  getActiveSessionByEvent(eventUUID: string) {
    this.payloadUrl = `${this.baseUrl}LiveSession/GetByEvent/${eventUUID}`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        this.commonService.handelErrors(err);
        throw err;
      })
    );
  }

  JoinSessionBySession(sessionUUID: string, userUUID: string) {
    this.payloadUrl = `${this.baseUrl}LiveSession/JoinSessionBySession/${sessionUUID}/${userUUID}`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        this.commonService.handelErrors(err);
        throw err;
      })
    );
  }
}


