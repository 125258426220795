import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// import { ShareEventDialogComponent } from 'src/app/common/dialog/share-event-dialog/share-event-dialog.component';
// import { MatDialog } from '@angular/material/dialog';
import { Subscription, interval, timer } from 'rxjs';
import { DatePipe } from '@angular/common';
import { map } from 'rxjs/operators';
import * as moment from 'moment-timezone';
import { DomSanitizer, Meta } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { AdminService, CommonService, AppInsightService, CommonFunctions, LiveSessionService, EventService } from 'pck-general-components';
import { Location } from '@angular/common';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-auditorium',
  templateUrl: './auditorium.component.html',
  styleUrls: ['./auditorium.component.scss'],
  providers: [DatePipe]
})
export class AuditoriumComponent implements OnInit {
  private apiBaseUrl = environment.dotNetApiEndpoint;
  public loginData: any;
  public eventData: any;
  public enableChat = true;
  public activeAudiData: any;
  public playerOptions: any = {
    autoplay: true
  };
  public breakMessage: any = {
    show: false,
    message: '',
    type: '',
    sessonTime: ''
  };
  public viewPoll = false;
  subscription: Subscription;
  todayDate: any;
  sessionData: any;
  openAgenda: boolean;
  todaysession: any;
  tomorrowsession: any;
  yesterdaysession: any;
  pollList: any = [];
  sessionPollsList: any;
  showGoLivebtn: number = 0;
  joinURL: any;
  sessionImage: any;
  meetingNotStart: boolean;
  emailId: string;
  firstName: string;
  lastName: string;
  businessName: string;
  eventUUID: any;
  eventTimerCount: number;
  sessionTimerCount: number = 0;
  timerSubscription: Subscription;
  sessionTimerCount1: number = 0;
  timerSubscription1: Subscription;

  randomUserEmail: string = "GuestUser" + Math.floor(100000 + Math.random() * 900000) + "@yopmail.com";
  currentEvnet: string = 'ff182c2c-a413-444c-a195-138e22ea9d75';
  liveURL = "/live-event?emailId=" + this.randomUserEmail + "&firstName=&lastName=&businessName=&stateName=&eventUUID=" + this.currentEvnet;
  eventDetail: { title: any; day: any; id: any; };
  designString: string = ` <style>
  #timeDisplay {
    text-align: center;
    font-family: Arial, sans-serif;
      font-size: 36px;
      margin-top: 10px;
  }
  #days ,#hours, #minutes, #seconds {
      display: inline-block;
      margin: 0 10px;
      padding: 10px;
      background-color: #3498db;
      color: #fff;
      border-radius: 5px;
  }
  @media screen and (min-width: 320px) {

    .session-will-starts >* span{
      margin:5px 0px!important;
    }
  }
  @media screen and (max-width: 480px) {

    .session-will-starts >* span{
      margin:5px 0px!important;
    }
  }
  </style>`;
  contentString: string;
  validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  registerFormGroup = new FormGroup({
    FirstName: new FormControl(''),
    LastName: new FormControl(''),
    EmailId: new FormControl('', [Validators.required, Validators.pattern(this.validEmailRegex)]),
    PhoneNo: new FormControl('', [Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
    BusinessName: new FormControl('', Validators.required),
    Comments: new FormControl(''),
  });
  @ViewChild('closebutton') closebutton: any;
  submit: boolean;
  submitted: boolean;
  invalidemail: boolean;
  eventRegister: boolean;
  isDynamicLink : boolean;


  constructor(public commonService: CommonService, private route: ActivatedRoute, private activatedRoute: ActivatedRoute, private adminService: AdminService, private router: Router, public commonFunctions: CommonFunctions, public appInsightService: AppInsightService, private datePipe: DatePipe, private livesession: LiveSessionService, private domSanitizer: DomSanitizer, private meta: Meta, private location: Location, private eventService: EventService) {
    // // private _bottomSheet: MatBottomSheet
    this.route.queryParams.subscribe(async (params) => {
      this.emailId = this.route.snapshot.queryParamMap.get('emailId') ? this.route.snapshot.queryParamMap.get('emailId') : this.route.snapshot.queryParamMap.get('userId');
      this.firstName = this.route.snapshot.queryParamMap.get('firstName');
      this.lastName = this.route.snapshot.queryParamMap.get('lastName');
      this.businessName = this.route.snapshot.queryParamMap.get('businessName');
      this.eventUUID = this.route.snapshot.queryParamMap.get('eventUUID');
      if (this.route.snapshot.routeConfig['path'] == 'live-event/solving-staffing-challenges') {
        this.currentEvnet = '506e6132-cc82-49e4-a28e-d933eca43e89';
        this.emailId = this.randomUserEmail;
        this.firstName = this.route.snapshot.queryParamMap.get('firstName');
        this.lastName = this.route.snapshot.queryParamMap.get('lastName');
        this.businessName = '';
        this.eventUUID = this.currentEvnet;
      }
      else if (this.route.snapshot.routeConfig['path'] == 'live-event/finding-growth') {
        this.currentEvnet = 'b46f9aeb-5832-41aa-a057-2c44a527bc67';
        this.emailId = this.randomUserEmail;
        this.firstName = this.route.snapshot.queryParamMap.get('firstName');
        this.lastName = this.route.snapshot.queryParamMap.get('lastName');
        this.businessName = '';
        this.eventUUID = this.currentEvnet;
      }
      else if (this.route.snapshot.routeConfig['path'] == 'live-event/blueprint-for-serenity') {
        this.currentEvnet = 'ba99bfaa-359d-413c-8ca5-3306e79290bd';
        this.emailId = this.randomUserEmail;
        this.firstName = this.route.snapshot.queryParamMap.get('firstName');
        this.lastName = this.route.snapshot.queryParamMap.get('lastName');
        this.businessName = '';
        this.eventUUID = this.currentEvnet;
      }
      else if (this.route.snapshot.routeConfig['path'] == 'live-event/5s-for-compliance') {
        this.currentEvnet = 'a431df9c-b463-4374-a7da-68f2a7ec5663';
        this.emailId = this.randomUserEmail;
        this.firstName = this.route.snapshot.queryParamMap.get('firstName');
        this.lastName = this.route.snapshot.queryParamMap.get('lastName');
        this.businessName = '';
        this.eventUUID = this.currentEvnet;
      }
      else if (this.route.snapshot.routeConfig['path'] == 'live-event/consolidating-the-chaos') {
        this.currentEvnet = 'ff182c2c-a413-444c-a195-138e22ea9d75';
        this.emailId = this.randomUserEmail;
        this.firstName = this.route.snapshot.queryParamMap.get('firstName');
        this.lastName = this.route.snapshot.queryParamMap.get('lastName');
        this.businessName = '';
        this.eventUUID = this.currentEvnet;
      }
      else {
        this.isDynamicLink=true;
      await this.eventService.getEventByUUID(this.eventUUID).then((response) => {
          if (response) {}
        }, (err) => {
          this.eventUUID = this.currentEvnet;
        });
      }
      
      ///exception case for evnet id
      if (!this.eventUUID) {
        this.eventUUID = this.currentEvnet;
      }

      //var liveurl = "live-event?emailId=" + this.randomUserEmail + "&firstName=&lastName=&businessName=&stateName=&eventUUID="+this.currentEvnet;
      this.eventDetail = {
        title: 'Test',
        day: 1,
        id: this.eventUUID
      }

      // if(this.emailId && this.businessName){
        if(this.emailId){
        this.directJoinEvent();
      }
    else{
      this.showGoLivebtn = 4;
      this.registerFormGroup.controls['EmailId'].setValue(this.emailId);
        this.registerFormGroup.controls['FirstName'].setValue(this.firstName);
        this.registerFormGroup.controls['LastName'].setValue(this.lastName);
        this.registerFormGroup.controls['BusinessName'].setValue(this.businessName);
        this.registerFormGroup.updateValueAndValidity();
 }



     


    });

    const dateString = new Date().toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
    this.todayDate = dateString;
  }

  checkIsRegisterEvent(){
    const regformData: any = new FormData();
    regformData.append('EventUUID', this.eventUUID);
    regformData.append('EmailId', this.emailId);
    if (this.emailId && this.emailId.includes("@yopmail.com")) {
      this.emailId = "";
    }
    this.eventService.GetRegisterUser(regformData).subscribe(response => {
      if (response && response[0]) {
        this.eventRegister = true;
        this.directJoinEvent();
        this.registerFormGroup.controls['EmailId'].setValue(response[0].emailId);
        this.registerFormGroup.controls['FirstName'].setValue(response[0].firstName);
        this.registerFormGroup.controls['LastName'].setValue(response[0].lastName);
        this.registerFormGroup.controls['BusinessName'].setValue(response[0].businessName);
        this.registerFormGroup.controls['Comments'].setValue(response[0].comments);
        this.registerFormGroup.controls['PhoneNo'].setValue(response[0].phoneNo);
        this.registerFormGroup.updateValueAndValidity();
        //this.showGoLivebtn = 4;
      }
    }, error => {
      this.breakMessage = {
        show: true,
        message: 'user not register',
        type: 'preevent'
      }
      this.showGoLivebtn = 4;
      this.registerFormGroup.controls['EmailId'].setValue(this.emailId);
      this.registerFormGroup.controls['FirstName'].setValue(this.firstName);
      this.registerFormGroup.controls['LastName'].setValue(this.lastName);
      this.registerFormGroup.controls['BusinessName'].setValue(this.businessName);
      this.registerFormGroup.updateValueAndValidity();


    });
  }

  directJoinEvent() {
    let formData = {
      emailId: this.emailId,
      firstName: this.firstName ? this.firstName : this.emailId,
      lastName: this.lastName ? this.lastName : this.emailId,
      businessName: this.businessName,
      eventUUID: this.eventUUID // "dbfe3d73-5db9-4610-b2b5-97fd328c94a8"
    };

    this.commonService.joinLiveEventByEmail(formData).subscribe(
      response => {
        const data = response.body;
        if (!data.isValid) {

          // this.appInsightService.setUserId(data.emailId);
          // this.getEventByUUID(data.eventUUID, data.uuid);
          this.appInsightService.logEvent('Login successful and redirect to add user details modal pop-up.');
          //  this.openUserDetailsDialog(data);
          //  this.saveLoginDetailsToKranum(formData);
        } else {

          this.getEventByUUID(data.eventUUID, data.uuid).then((resp) => {
            if (resp) {
              this.commonService.loginData.subscribe(x => {
                this.loginData = x;
              });
              this.commonService.userEventData.subscribe(event => {
                if (event) {
                  event.pageName = 'SESSION_AUDI';
                  this.eventData = event;
                  this.eventDetail = {
                    title: this.eventData.title,
                    day: this.eventData.startDate.substring(0, 2),
                    id: this.eventData.uuid
                  }
                  this.meta.addTags([
                    { name: 'description', content: event.title },
                    { name: 'keywords', content: 'success , summit' }
                  ]);
                }
              });
              this.getEventCurrentSequence();
            }

          });
          this.appInsightService.logEvent('Login successful.');


          // bypass terms and condition screen
          sessionStorage.setItem('userData', JSON.stringify(data));
          this.commonService.loginData.next(data);
          this.loginData = data;
          this.appInsightService.setUserId(data.emailId);
        }
      },
      err => {
        if (err.status === 400) {
          this.showGoLivebtn = null;
          this.breakMessage = {
            show: true,
            message: err.error.DataValidationErrors.error,
            type: 'preevent'
          }
          this.appInsightService.logEvent('Login failed', { message: err });
        }
        else {
          this.appInsightService.logEvent('Login failed', { message: err });
          window.location.href = this.liveURL;

        }
      }
    );
  }

  getEventByUUID(uuid: string, userUUID: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.adminService.getEventByUUID(uuid, userUUID).subscribe(response => {
        this.commonService.userEventData.next(response);
        sessionStorage.setItem('eventData', JSON.stringify(response));
        resolve(true);
        // if (response.eventType === 'C') {
        //   if(response && response.track && response.track.length > 0 && response.track[0].session && response.track[0].session.length > 0 && response.eventBooth && response.eventBooth.length>0){
        //     this.router.navigate(['/view/lobby']);
        //   } else if(response && response.eventBooth && response.eventBooth.length>0){
        //     this.router.navigate(['/view/exhibitors']);
        //   }else if(response && response.track && response.track.length > 0 && response.track[0].session && response.track[0].session.length > 0){
        //     this.router.navigate(['/view/audi']);
        //   } else if (response && response.track && response.track.length > 0 && response.eventBooth.length==0  && response.track[0].session.length==0) {
        //     this.router.navigate(['/view/exhibitors']);
        //   } 
        //   //this.router.navigate(['/view/reception']);
        // } else {
        //   // this.router.navigate(['/virtual-bazaar']);
        //   // this.openVirtualIntroDialog();
        // }
      });
    });
  }


  getLiveSession(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.livesession.JoinSessionBySession(this.activeAudiData.uuid, this.loginData.id).subscribe((resp: any) => {
        if (resp.url) {
          resolve(resp.url);
        } else {
          this.meetingNotStart = true;
          resolve(null);
        }
      });
    });

  }

  ngOnInit() {
    this.appInsightService.logPageView('Auditorium component loaded');
    if (!this.activeAudiData) {
      this.subscription = interval(1 * 60 * 1000)
        .subscribe(() => {
          if (this.eventData) {
            this.appInsightService.logEvent('getEventCurrentSequence Api polling started');
            this.getEventCurrentSequence();
          }
        });
    }
  }

  initPlayer() {
    this.appInsightService.logEvent('Init player options for config');
    this.playerOptions = {
      "fluid": true,
      "techOrder": ['youtube'],
      "preload": "auto",
      "autoplay": false,
      "muted": false,
      "controls": true,
      "controlBar": {
        "playToggle": false,
        "captionsButton": false,
        "chaptersButton": false,
        "subtitlesButton": false,
        "remainingTimeDisplay": true,
        "progressControl": {
          "seekBar": false
        },
        "fullscreenToggle": true,
        "playbackRateMenuButton": false,
      },
      "nativeControlsForTouch": false,
      "loadingSpinner": false,
      "errorDisplay": false,
    };
  }

  // openNotification(): void {
  //   this.appInsightService.logEvent('Open notification function invoked');
  //   this._bottomSheet.open(NotificationComponent, {
  //     panelClass: 'notification-bottom-sheet',
  //     backdropClass: 'notification-container-ref',
  //   });
  // }

  openShareModal() {
    this.appInsightService.logEvent('open share modal method invoke');
    // const dialogRef = this.dialog.open(ShareEventDialogComponent, {
    //   width: '80%',
    //   height: '80%',
    //   panelClass: 'share-event-modal',
    //   disableClose: true,
    // });

    // dialogRef.afterClosed().subscribe(result => {
    //   console.log('Exhibit enquiry dialog was closed' + result);
    // });
  }

  getAttachmentUrl(logoResourceUuid) {
    let url = '../../../assets/no-image.png';
    if (logoResourceUuid) {
      url = this.apiBaseUrl + 'File/Download/' + logoResourceUuid;
    }
    return url;
  }

  getEventCurrentSequence() {
    this.appInsightService.logEvent('Get event current sequence api call initiated');
    this.commonService.getEventCurrentSequence(this.eventData.uuid).subscribe(data => {
      this.appInsightService.logEvent('Get event current sequence api success response', { response: data });
      this.activeAudiData = data.length ? data[0] : null;
      this.sessionPollsList = [];
      if (this.activeAudiData && this.activeAudiData.uuid) {
        this.adminService.getSession(this.activeAudiData.uuid).subscribe((res: any) => {
          if (res && res.sessionResourceVideoThumbnailUuid) {
            this.sessionImage = res.sessionResourceVideoThumbnailUuid;
          }

          var sessionpollList = res.sessionPoll;
          this.sessionPollsList = sessionpollList.forEach(element => {
            element.pollTime = moment(element.pollTime).tz(res.timeZone)['_d'];
            element.timestring = (element.pollTime).getHours() + ":" + (element.pollTime).getMinutes();
          });
        });
      }
      console.log(this.pollList);

      if (this.activeAudiData) {
        if (this.activeAudiData.type === 'SESSION') {

          if (this.activeAudiData.uuid && this.loginData.id) {
            if (this.activeAudiData.isLiveSession) {

              this.getLiveSession().then((resp) => {
                if (!resp) {
                  this.showGoLivebtn = 3;
                  console.log("Meeting not started");
                } else {
                  this.showGoLivebtn = 1;
                  this.joinURL = this.domSanitizer.bypassSecurityTrustResourceUrl(resp);
                }
              });
            } else {
              this.showGoLivebtn = 2;
              this.initPlayer();
            }
          }

        } else {
          this.appInsightService.logEvent('Redirect initiated from get event current sequence api success response', { redirectUrl: '/view/breakout' });
          this.router.navigateByUrl('/view/breakout');
        }
        this.subscription.unsubscribe();
      } else {
        this.showGoLivebtn = null;
        const index = this.eventData.eventSequence.findIndex(x => x.active);
        if (index >= 0) {
          const nextSessionData = this.eventData.eventSequence[index + 1];
          if (nextSessionData) {
            const type = nextSessionData.type.toLowerCase();
            const dateTime = this.commonFunctions.convertToTimezoneString(nextSessionData.startDateTime, this.eventData.timezone);
            //const dateTime = new Date(nextSessionData.startDateTime).toLocaleString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true});


            this.breakMessage = {
              show: true,
              message: `Your next ${type} will begin at ${dateTime} EST.`,
              type: 'presession',
              sessonTime: dateTime
            }
          } else {
            if (this.eventData.eventSequence && this.sessionTimerCount1 == 0) {
              const type = this.eventData.eventSequence[0].type.toLowerCase();
              const dateTime = this.commonFunctions.convertToTimezoneString(this.eventData.eventSequence[0].startDateTime, this.eventData.timezone);
              //const dateTime = new Date(nextSessionData.startDateTime).toLocaleString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true});
              const systemTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
              this.eventData.eventSequence.forEach(element => {
                element.startDateTime = this.convertUTCtoLocal(element.startDateTime, systemTimezone);
              });
              //const localDate = this.convertUTCtoLocal(utcDateString, systemTimezone);

              let curSession = this.eventData.eventSequence.find(x => ((new Date(x.startDateTime).getTime() - new Date().getTime()) / 1000) >= 0);
              this.sessionTimerCount1 = Math.ceil((new Date(curSession.startDateTime).getTime() - new Date().getTime()) / 1000);
              this.timerSubscription1 = timer(0, 1000).pipe(
                map(() => {
                  if (this.sessionTimerCount1 > 0) {
                    this.sessionTimerCount1 = Math.round(this.sessionTimerCount1) - 1;
                    //const errormsg = "Your Session will begins in " + this.secondsToDhms(this.sessionTimerCount1);
                    const errormsg = this.secondsToDhms(this.sessionTimerCount1);
                    // 
                    // const minutes = Math.floor(this.sessionTimerCount1 / 60) < 10 ? "0" + Math.floor(this.sessionTimerCount1 / 60) : Math.floor(this.sessionTimerCount1 / 60);
                    // const seconds = this.sessionTimerCount1 - Number(minutes) * 60;
                    // const errormsg = "Your Session will begins in " + minutes + " : " + (seconds < 10 ? "0" + seconds : seconds);
                    this.breakMessage = {
                      show: true,
                      message: errormsg,
                      type: 'presession'
                    }

                  } else {
                    this.breakMessage = {
                      show: true,
                      message: 'Now We Are Going To Live',
                      type: 'presession'
                    }

                  }
                })
              ).subscribe();

              // this.breakMessage = {
              //   show: true,
              //   message: `Your ${type} will begin at ${dateTime} EST.`,
              //   type: 'presession'
              // }
            } else {
              if (this.sessionTimerCount == 0 && this.sessionTimerCount1 == 0) {
                this.breakMessage = {
                  show: true,
                  message: `We don't have any session or breakout.`,
                  type: 'postsession'
                }
              }

            }
          }
        } else {
          if (this.eventData.eventSequence && this.eventData.eventSequence.length > 0 && this.sessionTimerCount == 0) {
            const type = this.eventData.eventSequence[0].type.toLowerCase();
            const dateTime = this.commonFunctions.convertToTimezoneString(this.eventData.eventSequence[0].startDateTime, this.eventData.timezone);
            //const dateTime = new Date(this.eventData.eventSequence[0].startDateTime).toLocaleString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true });
            const systemTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            this.eventData.eventSequence.forEach(element => {
              element.startDateTime = this.convertUTCtoLocal(element.startDateTime, systemTimezone);
            });
            //const localDate = this.convertUTCtoLocal(utcDateString, systemTimezone);

            let curSession = this.eventData.eventSequence.find(x => ((new Date(x.startDateTime).getTime() - new Date().getTime()) / 1000) >= 0);
            this.sessionTimerCount = Math.ceil((new Date(curSession.startDateTime).getTime() - new Date().getTime()) / 1000);

            // let curSession = this.eventData.eventSequence.find(x => ((new Date(x.startDateTime).getTime() - utcDatetime.getTime()) / 1000) >= 0);
            // this.sessionTimerCount = (new Date(curSession.startDateTime).getTime() - utcDatetime.getTime()) / 1000;
            this.timerSubscription = timer(0, 1000).pipe(
              map(() => {
                if (this.sessionTimerCount > 0) {
                  this.sessionTimerCount = this.sessionTimerCount - 1;
                  const errormsg = this.secondsToDhms(this.sessionTimerCount);
                  // this.sessionTimerCount = this.sessionTimerCount - 1;
                  // const minutes = Math.floor(this.sessionTimerCount / 60) < 10 ? "0" + Math.floor(this.sessionTimerCount / 60) : Math.floor(this.sessionTimerCount / 60);
                  // const seconds = this.sessionTimerCount - Number(minutes) * 60;
                  // const errormsg = "Your Session will begins in " + minutes + " : " + (seconds < 10 ? "0" + seconds : seconds);
                  this.breakMessage = {
                    show: true,
                    message: errormsg,
                    type: 'presession'
                  }

                }
                else {
                  this.breakMessage = {
                    show: true,
                    message: 'Now We Are Going To Live',
                    type: 'presession'
                  }
                }
              })
            ).subscribe();
          } else {
            if (this.sessionTimerCount == 0 && this.sessionTimerCount1 == 0) {
              this.breakMessage = {
                show: true,
                message: `We don't have any session or breakout.`,
                type: 'postsession'
              }
            }

          }
        }
      }
    }, err => {
      this.appInsightService.logEvent('Get event current sequence api error response', { response: err });
    });
  }

  convertUTCtoLocal(utcDateString, targetTimezone) {
    // Append "Z" to indicate that the input is in UTC time
    const utcDate = new Date(utcDateString + "Z");
    const localDate = new Intl.DateTimeFormat('en-US', {
      timeZone: targetTimezone,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short'
    }).format(utcDate);

    return localDate;
  }

  getAllSession() {
    var today = this.datePipe.transform(new Date(), 'MM-dd-yyyy');
    let yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    var yesterdaystr = this.datePipe.transform(yesterday, 'MM-dd-yyyy');
    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    var tomorrowstr = this.datePipe.transform(tomorrow, 'MM-dd-yyyy');
    this.todaysession = [];
    this.tomorrowsession = [];
    this.yesterdaysession = [];
    this.adminService.getAllSessionsByEventId(this.eventData.uuid).subscribe(response => {
      this.sessionData = response;
      response.forEach(element => {
        var startDate = moment(element.startDateTime).tz(element.timeZone)['_d'];
        var endDate = moment(element.endDateTime).tz(element.timeZone)['_d'];
        element.startDate = new Date(new Date(startDate).toDateString());
        element.endDate = new Date(new Date(endDate).toDateString());
        element.startTime = startDate.getHours() + ":" + startDate.getMinutes();
        element.endTime = endDate.getHours() + ":" + endDate.getMinutes();
        if (this.datePipe.transform(element.startDate, 'MM-dd-yyyy') == today) {
          this.todaysession.push(element)
        }
        else if (this.datePipe.transform(element.startDate, 'MM-dd-yyyy') == tomorrowstr) {
          this.tomorrowsession.push(element);
        }
        else if (this.datePipe.transform(element.startDate, 'MM-dd-yyyy') == yesterdaystr) {
          this.yesterdaysession.push(element);
        }
      });

    });
  }
  agendaOpen() {
    this.openAgenda = !this.openAgenda;
    if (this.openAgenda)
      this.getAllSession();
  }

  tConv24(time24: any) {
    var ts = time24;
    var H = +ts.substr(0, 2);
    var h = (H % 12) || 12;

    var k = ((h < 10) ? ("0" + h) : h);  // leading 0 at the left for 1 digit hours
    var ampm = H < 12 ? " AM" : " PM";
    ts = k + ts.substr(2, 3) + ampm;
    return ts;
  };


  ngOnDestroy() {
    this.appInsightService.logEvent('ngOnDestroy subscription unsubscribed');
    this.subscription.unsubscribe();
  }

  secondsToDhms(seconds) {
    seconds = Number(seconds)
    var d: any = Math.floor(seconds / (3600 * 24))
    var h: any = Math.floor((seconds % (3600 * 24)) / 3600)
    var m: any = Math.floor((seconds % 3600) / 60)
    var s: any = Math.floor(seconds % 60)
    // console.log(d, h, m, s)
    // var dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : ""
    // var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : ""
    // var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : ""
    // var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : ""
    d = d < 10 ? "0" + d : d;
    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;
    s = s < 10 ? "0" + s : s;
    this.contentString = `
    <div id="timeDisplay">
    <span id="days">`+ d + `</span> :
        <span id="hours" >`+ h + `</span> :
        <span id="minutes" >`+ m + `</span> :
        <span id="seconds" >`+ s + `</span> 
    </div>
    `
    if (d == '00') {
      this.contentString = this.contentString.replace(`<span id="days">` + d + `</span> :`, ``);
      if (h == '00') {
        this.contentString = this.contentString.replace(` <span id="hours" >` + h + `</span> :`, ``);
        if (m == '00') {
          this.contentString = this.contentString.replace(`<span id="minutes" >` + m + `</span> :`, ``);
        }
      }
    }
    const htmlstring = this.designString + this.contentString;

    return this.domSanitizer.bypassSecurityTrustHtml(htmlstring);
    // return dDisplay + hDisplay + mDisplay + sDisplay;
  }

  get f() {
    return this.registerFormGroup.controls;
  }

  registerEvent() {
    // if (!this.businessName) {
    //   this.registerFormGroup.controls['BusinessName'].setValue('DirectEntry');
    // }

    this.submitted = true;

    if (!this.registerFormGroup.controls['EmailId'].value.match(this.validEmailRegex) || this.registerFormGroup.controls['EmailId'].value.includes("@yopmail.com")) {
      this.registerFormGroup.controls['EmailId'].setValue("");
      this.registerFormGroup.updateValueAndValidity();
      this.invalidemail = true;
    }
    if (this.registerFormGroup.invalid) {
      return;
    }
    const formData: any = new FormData();
    formData.append('FirstName', this.registerFormGroup.controls['FirstName'].value);
    formData.append('LastName', this.registerFormGroup.controls['LastName'].value);
    formData.append('EmailId', this.registerFormGroup.controls['EmailId'].value);
    formData.append('PhoneNo', this.registerFormGroup.controls['PhoneNo'].value);
    formData.append('Comments', this.registerFormGroup.controls['Comments'].value);
    formData.append('EventUUID', this.eventUUID);
    formData.append('BusinessName', this.registerFormGroup.controls['BusinessName'].value);
    let RegisterEventData = {
      'FirstName': this.registerFormGroup.controls['FirstName'].value,
      'LastName': this.registerFormGroup.controls['LastName'].value,
      'EmailId': this.registerFormGroup.controls['EmailId'].value,
      'PhoneNo': this.registerFormGroup.controls['PhoneNo'].value,
      'Comments': this.registerFormGroup.controls['Comments'].value,
      'EventUUID': this.eventUUID,
      'BusinessName': this.registerFormGroup.controls['BusinessName'].value
    }

    sessionStorage.setItem('RegisterEventData', JSON.stringify(RegisterEventData));
    if (this.eventRegister) {
      this.eventService.UpdateRegisterViaEvent(formData).subscribe((response) => {
        if (response && response['uuid']) {
          Swal.fire(
            'Thank you',
            'Your registration is successful',
            'success'
          )
        }
        this.liveURL = "/live-event?emailId=" + this.registerFormGroup.controls['EmailId'].value + "&firstName=" + this.registerFormGroup.controls['FirstName'].value + "&lastName=" + this.registerFormGroup.controls['LastName'].value + "&businessName=" + this.registerFormGroup.controls['BusinessName'].value + "&stateName=&eventUUID=" + this.currentEvnet;
        window.location.href = this.liveURL;

      });
    }
    else {
      this.eventService.RegisterViaEvent(formData).subscribe(
        response => {
          if (response && response['uuid']) {

            Swal.fire(
              'Thank you',
              'Your registration is successful',
              'success'
            )

            this.liveURL = "/live-event?emailId=" + this.registerFormGroup.controls['EmailId'].value + "&firstName=" + this.registerFormGroup.controls['FirstName'].value + "&lastName=" + this.registerFormGroup.controls['LastName'].value + "&businessName=" + this.registerFormGroup.controls['BusinessName'].value + "&stateName=&eventUUID=" + this.currentEvnet;
            window.location.href = this.liveURL;


          }
        },
        error => {
          if (error.error.DataValidationErrors.error.includes('alreadyregister')) {
            this.liveURL = "/live-event?emailId=" + this.registerFormGroup.controls['EmailId'].value + "&firstName=" + this.registerFormGroup.controls['FirstName'].value + "&lastName=" + this.registerFormGroup.controls['LastName'].value + "&businessName=" + this.registerFormGroup.controls['BusinessName'].value + "&stateName=&eventUUID=" + this.currentEvnet;
            window.location.href = this.liveURL;
          }

          // const formData: any = new FormData();
          // formData.append('FirstName', this.registerFormGroup.controls['FirstName'].value);
          // formData.append('LastName', this.registerFormGroup.controls['LastName'].value);
          // formData.append('EmailId', this.registerFormGroup.controls['EmailId'].value);
          // formData.append('PhoneNo', this.registerFormGroup.controls['PhoneNo'].value);
          // formData.append('Comments', this.registerFormGroup.controls['Comments'].value);
          // formData.append('EventUUID', this.currentEvnet);
          // formData.append('BusinessName', this.registerFormGroup.controls['BusinessName'].value);
          // if (error.error.DataValidationErrors.error.includes('No Event')) {
          //   this.eventService.RegisterViaEvent(formData).subscribe(
          //     response => {
          //       this.liveURL = "/live-event?emailId=" + this.registerFormGroup.controls['EmailId'].value + "&firstName=" + this.registerFormGroup.controls['FirstName'].value + "&lastName=" + this.registerFormGroup.controls['LastName'].value + "&businessName=" + this.registerFormGroup.controls['BusinessName'].value + "&stateName=&eventUUID=" + this.currentEvnet;
          //       window.location.href = this.liveURL;
          //     });
          // }
        }
      );

    }
  }

}
