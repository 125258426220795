<!-- <div class="row page-wrap">
  <section class="col-12 main-preview-player pr-0" [class.col-md-8]="chat">
    <div class="col-12 p-0" *ngIf="type === 'single'" [ngClass]="popupopopen?'d-none':'d-block'">
      <video #singlePlayerTarget class="video-js vjs-default-skin vjs-big-play-centered p-0"
        preload="auto"></video>
    </div>

    <div class="col-12 col-lg-8 px-0 pr-sm-3" *ngIf="type === 'multiple'" [ngClass]="popupopopen?'d-none':'d-block'">
      <video id="preview-player" #target class="video-js vjs-big-play-centered vjs-fluid" controls preload="auto"
        crossorigin="anonymous">
        <p class="vjs-no-js">To view this video please enable JavaScript, and consider upgrading to a web browser that
          <a href="http://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
        </p>
      </video>
      <small class="text-muted">
        <b>Note:</b> To watch the video on full screen, please click on Full Screen icon <mat-icon class="align-middle">
          fullscreen</mat-icon>
      </small>
      <p [class.text-success]="commentResponse.code === 200" [class.text-danger]="commentResponse.code !== 200"
        *ngIf="commentResponse">{{ commentResponse.message }}</p>
     </div>

    <div class="col-12 col-lg-4 px-0 px-sm-3" *ngIf="type === 'multiple'" [ngClass]="popupopopen?'d-none':'d-block'">
      <div class="vjs-playlist"></div>
    </div>
    <p class="col-12 text-danger pt-4 mb-0" *ngIf="errorMsg">{{ errorMsg }}</p>
  </section>

  <section class="col-12 live-comments-box" [class.col-md-4]="chat" [class.show]="chat">

    <mat-tab-group [selectedIndex]="0">
      <mat-tab label="Comments">
        <div class="messaging col-12">
          <div class="inbox_msg">
            <div class="mesgs">

              <mat-toolbar *ngIf="sessionCommets.length>0"
                class="toolbar-counts mb-1 mb-md-3 d-flex justify-content-between align-items-center pr-3">
                <span>{{ sessionCommets.length }} Comments</span>
                <span class="text-danger favorite" [class.liked]="liked" (click)="favoriteClickEvent()">
                  <mat-icon class="align-middle">{{liked ? 'favorite' : 'favorite_border' }}</mat-icon>
                </span>
              </mat-toolbar>

              <div class="type_msg">
                <div class="input_msg_write">
                  <mat-form-field class="col-12 p-md-0">
                    <mat-label>Add a public comment...</mat-label>
                    <input matInput class="write_msg" [value]="txtMessage" (input)="inutSet($event)"
                      (keydown.enter)="sendMessage()" placeholder="Type a message" autocomplete="off">
                    <button mat-raised-button matSuffix class="msg_send_btn"
                      (click)="isEmojiPickerVisible = !isEmojiPickerVisible;"> 😀</button>
                    <emoji-mart class="emoji-mart" *ngIf="isEmojiPickerVisible" (emojiSelect)="addEmoji($event)"
                      title="Choose your emoji"></emoji-mart>
                    <button mat-raised-button matSuffix type="button" *ngIf="txtMessage" class="msg_send_btn"
                      (click)="sendMessage()">
                      <mat-icon>send</mat-icon>
                    </button>
                  </mat-form-field>
                </div>
              </div>


              <div class="msg_history pl-3">
                <ng-container *ngFor="let comments of sessionCommets.slice().reverse();  let i = index">
                  <div class="received_msg">
                    <div class="received_withd_msg mb-2">
                      <span class="circle-img">{{
                        comments.firstName.charAt(0).toUpperCase() }}</span>
                      <p>
                        <strong class="mr-2">{{comments.firstName + ' ' + comments.lastName}}</strong>
                        <small class="text-muted">{{ convertTimeToLocalString(comments.createdDate)}}</small>
                      </p>
                      <p>
                        {{comments.commentText}}
                        <button class="btn" (click)="onReplayClick(comments)">Reply</button>
                      </p>
                      <ng-container *ngFor="let ans of comments.sessionCommentsAnswers; let j = index">


                        <div class="received_withd_msg mb-2" *ngIf="comments.isAns">
                          <span class="circle-img">{{
                            ans.firstName.charAt(0).toUpperCase() }}</span>
                          <p>
                            <strong class="mr-2">{{ans.firstName + ' ' + ans.lastName}}</strong>
                            <small class="text-muted">{{ convertTimeToLocalString(ans.createdDate)}}</small>
                          </p>
                          <p>
                            {{ans.ansText}}
                          </p>
                        </div>
                      </ng-container>

                      <ng-container *ngIf="comments.isReplay">
                        <div class="form-inline sessions-comments-form">
                          <div class="form-group">
                            <input type="text" #ans class="form-control" id="tType-reply" placeholder="Type a reply">
                            <button type="submit" class="btn btn-primary" (click)="onAnsReplay(comments,ans.value)"><i
                                class="fa-solid fa-circle-right"></i></button>
                          </div>
                        </div>
                      </ng-container>
                      <div class="row" *ngIf="comments.sessionCommentsAnswers.length > 0">
                        <div class="col-md-2"></div>
                        <div class="col-md-8">
                          <a (click)="onReplayView(comments)"> {{comments.isAns ? 'Hide' : 'View'}} {{ comments.sessionCommentsAnswers.length }} more
                            replies </a>
                        </div>
                      </div>
                    </div>
                  </div>
 </ng-container>
              </div>

            </div>
          </div>

        </div>
      </mat-tab>
      <mat-tab label="Resources">
        <table *ngIf="sessionres">
          <tr *ngFor="let item of sessionres">
            <td>{{item.title}}</td>
            <td><a class="resource-file" [href]="getResourceUrl(item.uuid)"><i class="fa fa-download"
                  aria-hidden="true"></i></a></td>
          </tr>
        </table>
      </mat-tab>

    </mat-tab-group>

  </section>

  <section class="col-12 poll h-100 align-items-center p-md-5 mt-4" [class.col-md-8]="chat"
    *ngIf="pollData && popupopopen" [ngClass]="!popupopopen?'d-none':'d-block'">
    <form [formGroup]="form" class="custom-qustion-style">
      <h4 class="poll-title" style="margin-bottom: 10px !important;">{{ pollData.pollQuestion }}</h4>
      <mat-radio-group class="row radio-group" aria-label="Select an option" formControlName="pollAnswer"
        (change)="onSubmitPoll(form.value)">
        <div class="col-12 radio-btn-wrap mb-3" *ngFor="let item of pollData.sessionPollOptions">
          <mat-progress-bar class="poll-progress" color="primary" mode="determinate"
            [value]="getOptionValue(item.pollCount)"></mat-progress-bar>
          <mat-radio-button [value]="item.uuid" color="accent">
            {{ item.pollOption }}
          </mat-radio-button>
        </div>
      </mat-radio-group>
      <div class="answer text-success" *ngIf="pollMessage">{{ pollMessage }}</div>
    </form>
    <p>video will resume again in {{cnt2}} second</p>
  </section>

</div>  -->

<!-- NEW CODE STARTS -->


<div class="session-page-session-starts">
  <!-- <div class="session-page-session-starts-child"></div>
  <div class="session-page-session-starts-item"></div> -->
  <header class="header custom-header-dv">
    <div class="logo-wrap success-summit-logo">
      <img class="logo-event-icon" alt="" src="assets/public/logo-event.svg" />
    </div>

    <div class="logo-wrap caryfy_logo">
      <div class="inner_caryfy_logo">
        <!-- <img class="presented-by-icon" alt="" src="assets/public/presented-by.svg" /> -->

        <img class="logo-caryfyai-icon" alt="" src="assets/public/caryfysponsored.png" />
      </div>
    </div>
    <!-- <div class="resposive-icons logo-wrap">
      <div class="row">
        <div class="chat">
          <img class="ioniconcchatboxoutline" alt="" src="assets/public/ioniconcchatboxoutline.svg" />
        </div>
        <div class="chat">
          <img class="ioniconcchatboxoutline" alt="" src="assets/public/ioniconccloseoutline.svg" />
        </div>
        <div class="chat">
          <img class="ioniconcchatboxoutline" alt="" src="assets/public/ioniconccontractoutline.svg" />
        </div>
        <div class="chat">
          <img class="ioniconcchatboxoutline" alt="" src="assets/public/ioniconeexpandoutline.svg" />
        </div>
      </div>
    </div> -->
    <hr class="border-bottom-dv">
  </header>
  <div class="session-video" style="position: relative;">
    <div class="iframe_video">
      <div class="row page-wrap">
        <section class="col-12 main-preview-player pr-0">
          <div class="col-12 p-0" *ngIf="type === 'single'" [ngClass]="popupopopen?'d-none':'d-block'">
            <video #singlePlayerTarget id="singlePlayerTarget" class="video-js vjs-default-skin vjs-big-play-centered p-0"
            controls preload="auto" autoplay muted></video>
            <!-- <video-player #singlePlayerTarget srcurl="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4" (play)="playEvent($event)"></video-player> -->
          </div>

          <div class="col-12 col-lg-8 px-0 pr-sm-3" *ngIf="type === 'multiple'"
            [ngClass]="popupopopen?'d-none':'d-block'">
            <video id="preview-player" #target class="video-js vjs-big-play-centered vjs-fluid" controls preload="auto"
              crossorigin="anonymous">
              <p class="vjs-no-js">To view this video please enable JavaScript, and consider upgrading to a web browser
                that
                <a href="http://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
              </p>
            </video>
            <small class="text-muted">
              <b>Note:</b> To watch the video on full screen, please click on Full Screen icon <mat-icon
                class="align-middle">
                fullscreen</mat-icon>
            </small>
            <p [class.text-success]="commentResponse.code === 200" [class.text-danger]="commentResponse.code !== 200"
              *ngIf="commentResponse">{{ commentResponse.message }}</p>
          </div>

          <div class="col-12 col-lg-4 px-0 px-sm-3" *ngIf="type === 'multiple'"
            [ngClass]="popupopopen?'d-none':'d-block'">
            <div class="vjs-playlist"></div>
          </div>
          <p class="col-12 text-danger pt-4 mb-0" *ngIf="errorMsg">{{ errorMsg }}</p>
        </section>
      </div>
      <!-- Commented inner Quetion popup -->
      <!-- <div class="question overlay_question_dv" *ngIf="pollData && popupopopen">
        <form [formGroup]="form" class="custom-qustion-style">
         
          <div class="item-question">
            <div class="sit-sit-feugiat">
              <h3>{{pollData.pollQuestion }}</h3>
            </div>
            <div class="ioniconrradiobuttonoffout-parent" *ngFor="let item of pollData.sessionPollOptions">
              <input class="ioniconrradiobuttonoffout" type="radio" [value]="item.uuid" formControlName="pollAnswer"
                (change)="onSubmitPoll(form.value)" />
              <div class="nibh-id-vivamus">{{ item.pollOption }}</div>
            </div>
          </div>
         
        </form>
       <div class="action">
          <div class="answer-timer">
            <div class="submit-your-answer">Submit your answer in {{cnt2}} </div>
            <b class="sec"*ngIf="pollMessage">{{ pollMessage }}.</b>
          </div>
          <button class="submit">
            <div class="submit1">Submit</div>
          </button>
        </div>
      </div> -->
    </div>
    <div class="comments" >
      <div class="title comment-heading-dv" [ngClass]="popupopopen?'d-none':'d-block'">
        <img class="ioniconcchatboxoutline1" alt="" src="assets/public/ioniconcchatboxoutline2.svg" />

        <div class="comments1"> Comments
          <!-- <span class="text-danger favorite"
          [class.liked]="liked" (click)="favoriteClickEvent()">
          <mat-icon class="align-middle">{{liked ? 'favorite' : 'favorite_border' }}</mat-icon>
        </span> -->
        </div>
      </div>
      <div class="inner-comment-div" [ngClass]="popupopopen?'d-none':'d-block'">
        <div class="comment-wrap">
          <div class="item-comment">
            <ng-container *ngFor="let comments of sessionCommets.slice().reverse();  let i = index">

              
              <div class="title">
                <div class="this-is-awesome">
                  <div>
                    <b class="udaysutaria" *ngIf="comments.firstName!= comments.lastName">{{comments.firstName + ' ' +
                      comments.lastName}}</b>
                      <b class="udaysutaria" *ngIf="comments.firstName== comments.lastName">{{(businessName && businessName!=null && businessName!='null') ? businessName :   comments.firstName}}</b>
                  </div>

                 <p class="message-p"> {{comments.commentText}}</p>
                </div>
                <button class="reply" (click)="onReplayClick(comments)">
                  <img class="ioniconaarrowundooutline" alt="" src="assets/public/ioniconaarrowundooutline.svg" />
                </button>
              </div>
              <ng-container *ngFor="let ans of comments.sessionCommentsAnswers; let j = index">

                <div class="main-item-reply"  *ngIf="comments.isAns">
                  <div class="item-reply">
                    
                    <div class="yes-you-can">
                      <p><b class="udaysutaria" *ngIf="ans.firstName!= ans.lastName">{{ans.firstName + ' ' + ans.lastName}}</b>
                      <b class="udaysutaria" *ngIf="ans.firstName== ans.lastName">{{(businessName && businessName!=null && businessName!='null') ? businessName :   ans.firstName}}</b>
                    </p> 
                    <p>{{ans.ansText}}</p>
                  </div>
                  </div>


                </div>
              </ng-container>
              <ng-container *ngIf="comments.isReplay">
                <div class="form-inline ">
                  <div class=" sessions-comments-form">
                    <input class="input" type="text" #ans placeholder="Type a reply" autocomplete="off" />

                    <button type="submit" class="post" (click)="onAnsReplay(comments,ans.value)">
                      <img class="ioniconssendoutline" alt="" src="assets/public/ioniconssendoutline.svg" />
                    </button>
                  </div>
                </div>
              </ng-container>
              <div class="row col-12" *ngIf="comments.sessionCommentsAnswers.length > 0">
                <div class="col-md-1"></div>
                <div class="col-md-8">
                  <a class="more-reply" (click)="onReplayView(comments)"> {{comments.isAns ? 'Hide' : 'View'}} {{
                    comments.sessionCommentsAnswers.length }} more </a>
                </div>
              </div>

            </ng-container>
            <!-- <b class="udaysutaria">UdaySutaria:</b> -->
            <!-- <div class="title">
            <div class="this-is-awesome">
              This is awesome session. I’ll be surly book Caryfy.AI demo for
              my agency
            </div>
            <button class="reply">
              <img class="ioniconaarrowundooutline" alt="" src="assets/public/ioniconaarrowundooutline.svg" />
            </button>
          </div> -->
          </div>
          <!-- <div class="item-has-reply">
          <div class="item-comment1">
            <b class="udaysutaria">Deepesh:</b>
            <div class="title">
              <div class="this-is-awesome">
                Can register my agency with 10 employees with caryfy?
              </div>
              <button class="reply">
                <img class="ioniconaarrowundooutline" alt="" src="assets/public/ioniconaarrowundooutline.svg" />
              </button>
            </div>
          </div>
          <div class="item-reply">
            <b class="udaysutaria">Amit:</b>
            <div class="yes-you-can">Yes, you can.</div>
          </div>
        </div> -->

        </div>
      </div>


      <!-- <div class="form-group">
        <input class="input" type="text" [value]="txtMessage" (input)="inutSet($event)" (keydown.enter)="sendMessage()"
          placeholder="Type a message" autocomplete="off" />

        <button class="post" (click)="sendMessage()">
          <img class="ioniconssendoutline" alt="" src="assets/public/ioniconssendoutline.svg" />
        </button>
      </div> -->

      <div class="submit_blg_dv" [ngClass]="popupopopen?'d-none':'d-block'">
        <div class="form-group">
          <input class="input" type="text" [value]="txtMessage" (input)="inutSet($event)" (keydown.enter)="sendMessage()"
            placeholder="Type a message" autocomplete="off" />
    
          <button class="post" (click)="sendMessage()">
            <img class="ioniconssendoutline" alt="" src="assets/public/ioniconssendoutline.svg" />
          </button>
        </div>
      </div>

    </div>
    <div class="overley-question-blg " *ngIf="pollData && popupopopen">
      <div class="question overlay_question_dv" *ngIf="pollData && popupopopen">
  
  
        <form [formGroup]="form" class="custom-qustion-style">
  
          <div class="item-question">
            <div class="sit-sit-feugiat">
              <h3>{{pollData.pollQuestion }}</h3>
            </div>
            <div class="ioniconrradiobuttonoffout-parent" *ngFor="let item of pollData.sessionPollOptions">
              <input class="ioniconrradiobuttonoffout" type="radio" [value]="item.uuid" formControlName="pollAnswer"
                (change)="onSubmitPoll(form.value)" />
              <div class="nibh-id-vivamus">{{ item.pollOption }}</div>
            </div>
          </div>
  
        </form>
  
        <div class="action">
          <div class="answer-timer">
            <b class="sec"*ngIf="pollMessage">{{ pollMessage }}.</b>
            
            <div class="submit-your-answer" *ngIf="!pollMessage">Submit your answer in {{cnt2}} seconds</div>
          </div>
          <!-- <button class="submit">
            <div class="submit1">Submit</div>
          </button> -->
        </div>
      </div>
    </div>
  
  </div>


 
</div>
<!-- <lib-eventlanding [eventData]="eventDetail"></lib-eventlanding> -->