import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { Observable, of, Subject, BehaviorSubject, EMPTY } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AppInsightService } from './appinsight.service';
import { environment } from '../../environment';
// import { ErrorDialogComponent } from '../common/dialog/error-dialog/error-dialog.component';
// import { MatDialog } from '@angular/material/dialog';


@Injectable({
  providedIn: 'root'
})
export class CommonService {
  public hideclose = new BehaviorSubject(false);
  public selectedmenuurl = new Subject();
  public currentUserName = new Subject();
  public currentUserImageuuid = new Subject();
  private baseUrl = environment.apiEndpoint;
  private dotNetBaseUrl = environment.dotNetApiEndpoint;
  private payloadUrl: string;
  public isOnlineListData: any;
  blurContent = new BehaviorSubject(false);
  loginData: any = new BehaviorSubject({});
  userEventData: any = new BehaviorSubject('');
  videoPlayerData: any = new BehaviorSubject('');
  agreementSigned = new BehaviorSubject(false);
  isMainSessionLive = new BehaviorSubject(false);
  isEventBreakoutLive = new BehaviorSubject(false);
  isEventLive = new BehaviorSubject(false);
  showPointsDialog = new BehaviorSubject(false);
  selectedBoothSponsor = new BehaviorSubject(null);
  notificationList = new BehaviorSubject([]);
  totatRewardPoints = new BehaviorSubject(null);
  public isOnlineList = new BehaviorSubject([]);
  isReloadVideoDetails = new BehaviorSubject(false);


  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    observe: 'response' as 'body'
  };
  public userData: any;
  public categoryData: any;
  channelList: any;

  constructor(private http: HttpClient, private appInsightService: AppInsightService) {
    const eventData = JSON.parse(sessionStorage.getItem('eventData'));
    if (eventData) {
      this.userEventData.next(eventData);
    }
    const loginData = JSON.parse(sessionStorage.getItem('userData'));
    if (loginData) {
      this.loginData.next(loginData);
    }

    // const isUser = JSON.parse(sessionStorage.getItem('isOnlineUser'));
    // if (isUser) {
    //   this.setOnlineData(isUser)
    // }
    this.setCategoryData();

  }

  registration(requestData): Observable<any> {
    this.payloadUrl = `${this.baseUrl}EventKindController/registerForEventKind`;
    return this.http.post(this.payloadUrl, requestData, this.httpOptions).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  validateEmail(requestData): Observable<any> {
    this.payloadUrl = `${this.baseUrl}EventKindController/validateEmailForEventKind`;
    return this.http.post(this.payloadUrl, requestData, this.httpOptions).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  // joinEventByEmail(requestData): Observable<any> {
  //   this.payloadUrl = `${this.dotNetBaseUrl}Event/Join?EmailId=${requestData.EmailId}&EventUUID=${requestData.EventUUID}`;
  //   return this.http.get(this.payloadUrl).pipe(
  //     catchError(err => {
  //       this.appInsightService.logException(err);
  //       throw err;
  //     })
  //   );
  // }

  joinEventByEmail(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Event/Join`;
    return this.http.post(this.payloadUrl, requestData, this.httpOptions).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  joinLiveEventByEmail(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Event/LiveJoin`;
    return this.http.post(this.payloadUrl, requestData, this.httpOptions).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }


  saveLoginDetailsInKranumDB(requestData): Observable<any> {
    this.payloadUrl = `${this.baseUrl}EventController/addEventKindUserLoggedInDetails`;
    return this.http.post(this.payloadUrl, requestData, this.httpOptions).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  saveVideoAnalytics(requestData): Observable<any> {
    this.payloadUrl = `${this.baseUrl}OverviewWS/addPlayerVideoUserDetails`;
    return this.http.post(this.payloadUrl, requestData, this.httpOptions).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  saveUserDetails(requestData): Observable<any> {
    this.payloadUrl = `${this.baseUrl}BusinessContactWS/updateBusinessDetailByEventKind`;
    return this.http.post(this.payloadUrl, requestData, this.httpOptions).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  updateUserDetails(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}User/Update`;
    return this.http.post(this.payloadUrl, requestData).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  // Upload files
  uploadFiles(files): Observable<Object> {
    this.payloadUrl = `${this.baseUrl}files`;
    if (!files || files.length === 0) {
      return EMPTY;
    }

    const data = new FormData();

    for (const file of files) {
      data.append('file', file.slice(), file.name);
    }
    console.log(data);
    return this.http.post(this.payloadUrl, data, this.httpOptions).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  adminAuth(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}user/login`;
    return this.http.post(this.payloadUrl, requestData, this.httpOptions).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  sponsorAuth(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}SponsorUser/Login`;
    return this.http.post(this.payloadUrl, requestData, this.httpOptions).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  // care Business Forum survey api
  checkCBFStatus(requestData): Observable<any> {
    this.payloadUrl = `${this.baseUrl}CareBusinessForumController/isCareBusinessForumFilled`;
    return this.http.post(this.payloadUrl, requestData, this.httpOptions).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  getSurveyQuestion(requestData): Observable<any> {
    this.payloadUrl = `${this.baseUrl}CareBusinessForumController/getSurveyQuestion`;
    return this.http.post(this.payloadUrl, requestData, this.httpOptions).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  saveSurveyQuestion(requestData): Observable<any> {
    this.payloadUrl = `${this.baseUrl}CareBusinessForumController/addSurveyQuestionAnswer`;
    return this.http.post(this.payloadUrl, requestData, this.httpOptions).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  saveBoothQuery(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}EventBoothEnquiry/Create`;
    return this.http.post(this.payloadUrl, requestData, this.httpOptions).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  saveBoothAnalytics(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}EventBoothUserActivityLog/Create`;
    return this.http.post(this.payloadUrl, requestData, this.httpOptions).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  saveTermsAndConditions(requestData, loginType): Observable<any> {
    if (loginType === 'clientLogin' || loginType === 'User') {
      this.payloadUrl = `${this.dotNetBaseUrl}User/PolicyTerms`;
    } else {
      this.payloadUrl = `${this.dotNetBaseUrl}SponsorUser/PolicyTerms`;
    }
    return this.http.post(this.payloadUrl, requestData, this.httpOptions).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  resetPassword(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}User/ResetPassword`;
    return this.http.post(this.payloadUrl, requestData, this.httpOptions).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  resetSponsorUserPassword(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}SponsorUser/ResetPassword`;
    return this.http.post(this.payloadUrl, requestData, this.httpOptions).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  signUpClient(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Client/Signup`;
    return this.http.post(this.payloadUrl, requestData).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  updateclient(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}client/update`;
    return this.http.put(this.payloadUrl, requestData).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        this.handelErrors(err);
        throw err;
      })
    );
  }

  enquiry(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Enquiry/Create`;
    return this.http.post(this.payloadUrl, requestData, this.httpOptions).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  getCommentsHistory(sessionUUID): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Session/GetComments/${sessionUUID}`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  shareEvent(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Event/Share`;
    return this.http.post(this.payloadUrl, requestData, this.httpOptions).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  trackAppointmentClickEvent(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}EventBoothUserBookAppointmentActivityLog/Create`;
    return this.http.post(this.payloadUrl, requestData, this.httpOptions).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  trackSocialClickEvent(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}EventBoothUserSocialMediaActivityLog/Create`;
    return this.http.post(this.payloadUrl, requestData, this.httpOptions).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  trackBuyNowClickEvent(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}EventBoothUserBuyNowActivityLog/Create`;
    return this.http.post(this.payloadUrl, requestData, this.httpOptions).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  getZoomSignature(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Zoom/CreateZoomJoinMeetingToken`;
    return this.http.post(this.payloadUrl, requestData, this.httpOptions).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  getUserPoints(uuid: string, eventUUID: string): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}User/GetUserActivityPoint/${uuid}/${eventUUID}`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  getEventCurrentSequence(eventUUID: string): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Event/GetEventCurrentSequence/${eventUUID}`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  savePollAnswer(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Session/CreateUserSessionPoll`;
    return this.http.post(this.payloadUrl, requestData, this.httpOptions).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  getUserSessionPollResponse(eventUUID: string, sessionUUID: string): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Analytics/GetUserSessionPollResponse/${eventUUID}/${sessionUUID}`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  getAllCategory(): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Category/GetAll`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        //this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  isUserLogin(): boolean {
    if (localStorage.getItem("IsLogin") != "" && localStorage.getItem("IsLogin") == 'true') {
      return true;
    }
    return false;

  }

  sonserUserLogout(uuid: string) {
    this.payloadUrl = `${this.dotNetBaseUrl}SponsorUser/SponsorUserSignOff`;
    //var requestData = "uuid=" + uuid;
    const requestData: any = new FormData();
    requestData.append('uuid', uuid);

    return this.http.post(this.payloadUrl, requestData).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        this.handelErrors(err);
        throw err;
      })
    );

  }

  private handleError<T>(operation, result?: T) {
    return (error: any): Observable<T> => {
      // console.error(error);
      this.appInsightService.logException(error);
      return of(result as T);
    };
  }
  public getImgFile(url, filename?): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getBase64ImageFromUrl(url)
        .then(dataUrl => {
          console.log('Here is Base64 Url', dataUrl)
          var imageBlob = this.dataURItoBlob(dataUrl);
          var imageName = filename ? filename : "";
          const imageFile = new File([imageBlob], imageName, { type: 'image/png' });
          console.log("Here is JavaScript File Object", imageFile)
          var img = imageFile;
          return resolve(imageFile);
        })
    })
  }

  private dataURItoBlob(dataURI) {
    var imageData = dataURI.toString();
    var byteCharacters = atob(imageData.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''));
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);
    var blob = new Blob([byteArray], {
      type: undefined
    });
    return blob;
  }

  public async getBase64ImageFromUrl(imageUrl) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();

    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.addEventListener("load", function () {
        resolve(reader.result);
      }, false);

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
  }

  getUserUUID(): string {
    if (localStorage.getItem("clientData") && JSON.parse(localStorage.getItem("clientData"))["UUID"]) {
      return JSON.parse(localStorage.getItem("clientData"))["UUID"];
    }
    return "";
  }

  getUserID(): string {
    if (localStorage.getItem("clientData") && JSON.parse(localStorage.getItem("clientData"))["userId"]) {
      return JSON.parse(localStorage.getItem("clientData"))["userId"];
    }
    return "";
  }

  getClientID(): string {
    if (localStorage.getItem("clientData") && JSON.parse(localStorage.getItem("clientData"))["clientId"]) {
      return JSON.parse(localStorage.getItem("clientData"))["clientId"];
    }
    return "";
  }

  getClientUUID(): string {
    if (localStorage.getItem("clientData") && JSON.parse(localStorage.getItem("clientData"))["clientUUID"]) {
      return JSON.parse(localStorage.getItem("clientData"))["clientUUID"];
    }
    return "";
  }

  getClientFullName(): string {
    if (localStorage.getItem("clientData") && JSON.parse(localStorage.getItem("clientData"))["clientName"]) {
      return JSON.parse(localStorage.getItem("clientData"))["clientName"];
    }
    return "";
  }

  getClientName(): string {
    if (localStorage.getItem("clientData") && JSON.parse(localStorage.getItem("clientData"))["clientName"]) {
      let nameArray = JSON.parse(localStorage.getItem("clientData"))["clientName"].split(" ");
      return nameArray[0].substring(0, 1) + nameArray[1].substring(0, 1);
    }
    return "";
  }

  getClientInitialArray(): Array<string> {
    if (localStorage.getItem("intialArray") && JSON.parse(localStorage.getItem("intialArray"))) {
      let intialArray = JSON.parse(localStorage.getItem("intialArray"));
      return intialArray;
    }
    return [];
  }

  getUserSopnser(): any {
    if (localStorage.getItem("clientDataSopnser") && JSON.parse(localStorage.getItem("clientDataSopnser"))["sponsorClients"]) {
      return JSON.parse(localStorage.getItem("clientDataSopnser"))["sponsorClients"];
    }
    return [];
  }

  getClientEmail(): string {
    if (localStorage.getItem("clientData") && JSON.parse(localStorage.getItem("clientData"))["clientEmail"]) {
      return JSON.parse(localStorage.getItem("clientData"))["clientEmail"];
    }
    return "";
  }

  getUserLoginType(): string {
    if (localStorage.getItem("loginType")) {
      return localStorage.getItem("loginType");
    }
    return "";
  }
  getUserLoginRoles(): string {
    if (localStorage.getItem("clientData") && JSON.parse(localStorage.getItem("clientData"))["roles"]) {
      return JSON.parse(localStorage.getItem("clientData"))["roles"];
    }
    return "";
  }

  getAllUser(): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}User/GetAll`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  setUserData() {
    this.getAllUser().subscribe((user) => {
      this.userData = user;
    })
  }

  getCurrentUser(): Observable<any> {
    var currentUseruuid = this.getUserUUID();
    var user = "";
    this.getAllUser().subscribe((user) => {
      this.userData = user;
      user = this.userData.find(x => x.uuid == currentUseruuid);
    });
    return user['emailId'];
  }
  setCategoryData() {
    this.getAllCategory().subscribe((categorydata) => {
      this.categoryData = categorydata.sort((a, b) => a.categoryName.localeCompare(b.categoryName));
      //this.categoryData = categorydata;
    })
  }
  saveVideoYoutubeAnalytics(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}SavePlayerVideoData/VideoSave`;
    return this.http.post(this.payloadUrl, requestData).pipe(
      catchError(err => {
        //this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  getChannelList(hidePopup?): Observable<any> {
    this.payloadUrl = `${this.baseUrl}Channel/GetAll`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        if (!hidePopup) {
          this.handelErrors(err);
        }
        throw err;
      })
    );
  }

  setChannelList(hidepopup: boolean) {
    this.getChannelList(hidepopup).subscribe((responseChnnel: any) => {
      this.channelList = responseChnnel;
    });
  }

  handelErrors(err: any) {
    let error: any = '';
    if (err.status === 404) {
      error = {
        status: err.status,
        message: ['Not found']
      }
    } else if (err.status === 500) {
      error = {
        status: err.status,
        message: ['Oops! Something went wrong']
      }
    } else {
      const errorsArr = err.error.errors ? err.error.errors : err.error.DataValidationErrors;
      error = {
        status: err.status,
        message: Object.values(errorsArr)
      }
    }
    // const dialogRef = this.dialog.open(ErrorDialogComponent, {
    //   width: '350px',
    //   disableClose: false,
    //   data: error
    // });

    // dialogRef.afterClosed().subscribe(result => {
    //   // console.log('The dialog was closed' + result);
    //   this.appInsightService.logEvent('Error dialog close event');
    // });
  }

  HandleImageUpload(event, setminheight, setminwidth, setmaxheight, setmaxwidth): Promise<any> {
    return new Promise((resolve, reject) => {
      const file = event;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        console.log("Read base64URL:" + reader.result);
        var img = new Image();
        img.src = reader.result.toString();
        img.addEventListener('load', function () {
          if (img.width >= setminwidth && img.height >= setminheight && img.width <= setmaxwidth && img.height <= setmaxheight) {
            resolve("");
          }
          else {
            var msg = "image size should between " + setminwidth + "x" + setminheight + "pixels  to " + setmaxwidth + "x" + setmaxheight + " pixels";
            resolve(msg);
            // // Swal.fire({
            // //   allowOutsideClick: false,
            // //   type: 'error',
            // //   title: 'Oops...',
            // //   text: "image size should between " + setminwidth + "x" + setminheight + " and " + setmaxwidth + "x" + setmaxheight,
            // // })

          }
        });
      };
    });
  }

  userTeamDelete(uuid): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}User/delete/${uuid}`;
    return this.http.delete(this.payloadUrl, this.httpOptions).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        this.handelErrors(err);
        throw err;
      })
    );
  }

  userSponserTeamDelete(uuid): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}SponsorUser/delete/${uuid}`;
    return this.http.delete(this.payloadUrl, this.httpOptions).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        this.handelErrors(err);
        throw err;
      })
    );
  }
  getUserDetails(uuid): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}User/Get/${uuid}`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);

        this.handelErrors(err);

        throw err;
      })
    );
  }

  getAllSponsor(): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}client/GetAll`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        // this.handelErrors(err);
        throw err;
      })
    );
  }

  getCliendDetailByUUID(uuid): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Client/Get/${uuid}`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        this.handelErrors(err);
        throw err;
      })
    );
  }

  getSponsor(uuid: string): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}SponsorUser/Get/${uuid}`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        // this.handelErrors(err);
        throw err;
      })
    );
  }

  updateSponsorUser(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}SponsorUser/Update`;
    return this.http.post(this.payloadUrl, requestData).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        // this.handelErrors(err);
        throw err;
      })
    );
  }

  getUserListByIds(requestData: string): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}User/GetUsersByIds`;
    return this.http.post(this.payloadUrl, requestData).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        this.handelErrors(err);
        throw err;
      })
    );

  }

  HideEventMenu(hide) {
    if (document.getElementsByClassName('mega-menu mega-menu--multiLevel event-mega-menu-dv event-menucustome-blg') && document.getElementsByClassName('mega-menu mega-menu--multiLevel event-mega-menu-dv event-menucustome-blg')[0]) {
      if (hide) {
        document.getElementsByClassName('mega-menu mega-menu--multiLevel event-mega-menu-dv event-menucustome-blg')[0]['style'].display = 'none';
      } else {
        document.getElementsByClassName('mega-menu mega-menu--multiLevel event-mega-menu-dv event-menucustome-blg')[0]['style'].display = 'block';
      }
    }
  }

  getUser(uuid): Promise<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}User/Get/${uuid}`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    ).toPromise();
  }

  getYTImageByYTUrl(ytlink) {
    let linkid = ""
    if (ytlink.includes('?v=')) {
      linkid = ytlink.split('?v=')[1];
    }
    return "https://img.youtube.com/vi/" + linkid + "/mqdefault.jpg"
  }

  signOff(uuid: any) {
    this.payloadUrl = `${this.dotNetBaseUrl}User/SignOff`;
    const requestData: any = new FormData();
    requestData.append('uuid', uuid);
    return this.http.post(this.payloadUrl, requestData).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        this.handelErrors(err);
        throw err;
      })
    );
  }

  sendOTP(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}UserOtp/Create`;
    return this.http.post(this.payloadUrl, requestData, this.httpOptions).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  VerifyOTP(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}UserOtp/VerfiyOtp`;
    return this.http.post(this.payloadUrl, requestData, this.httpOptions).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }
  
  setOnlineData(data: any) {
    if (data) {
      var arr = data.toString()
      localStorage.setItem('isOnlineUser', arr);
      this.isOnlineList.next(data);
    }
  }

  getOnlineData() {
    if (localStorage.getItem("isOnlineUser") != null) {
      var arr  = localStorage.getItem('isOnlineUser');
      var data = arr.split(",");
      return data;
    } else {
      return [];
    }

  }


}
