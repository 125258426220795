import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { PublicRestService, EnvironmentService } from 'pck-api';
import { VideoModel } from 'pck-general-components';

@Injectable({
  providedIn: 'root'
})
export class SharedService extends PublicRestService {  
  private payloadUrl: string | undefined;
  constructor(
      @Inject(HttpClient) http: HttpClient,
      @Inject(EnvironmentService) public environmentData: EnvironmentService
  ) {
      super(http);
  }






}
