import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { EnvironmentService, PublicRestService } from 'pck-api';

@Injectable({
  providedIn: 'root'
})
export class UserService extends PublicRestService {
  private payloadUrl: string | undefined;
  constructor(
    @Inject(HttpClient) http: HttpClient,
    @Inject(EnvironmentService) public environmentData: EnvironmentService
  ) {
    super(http);
  }

  getUserListByIds(requestData: string): Promise<any> {
    this.payloadUrl = this.environmentData.serviceUrl + 'User/GetUsersByIds';
    return super.post<any>(this.payloadUrl,requestData);
   }

}