// import { MatDialog } from '@angular/material/dialog';
// import { ErrorDialogComponent } from './../common/dialog/error-dialog/error-dialog.component';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { Observable, of, Subject, BehaviorSubject, EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environment';
import { AppInsightService } from './appinsight.service';




@Injectable({
  providedIn: 'root'
})
export class AdminService {
  private baseUrl = environment.apiEndpoint;
  private dotNetBaseUrl = environment.dotNetApiEndpoint;
  private payloadUrl: string;
  loginData: any = new BehaviorSubject('');
  videoPlayerData: any = new BehaviorSubject('');
  editEventDetails: any = new BehaviorSubject('');
  editExhibitDetails: any = new BehaviorSubject('');
  editCompanyDetails: any = new BehaviorSubject('');
  eventTabs: any = new BehaviorSubject([]);
  eventType: any = new BehaviorSubject('');
  previewData: any = new BehaviorSubject({ preview: false });
  eventAnalyticsDetails: any = new BehaviorSubject('');
  refreshCompany = new BehaviorSubject(false);
  eventAnalyticsDetailsRepots: any;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    observe: 'response' as 'body'
  };

  constructor(private http: HttpClient, private appInsightService: AppInsightService) {

  }

  saveEvent(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}event/create`;
    return this.http.post(this.payloadUrl, requestData).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        this.handelErrors(err);
        throw err;
      })
    );
  }

  updateEvent(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}event/update`;
    return this.http.put(this.payloadUrl, requestData).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        this.handelErrors(err);
        throw err;
      })
    );
  }

  deleteEvent(uuid): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}event/delete/${uuid}`;
    return this.http.delete(this.payloadUrl, this.httpOptions).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        this.handelErrors(err);
        throw err;
      })
    );
  }

  getEventByUUID(uuid: string, userUUID: string): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Event/Get/${uuid}/${userUUID}`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        // this.handelErrors(err);
        throw err;
      })
    );
  }

  getAllEvent(uuid: string): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Event/GetEventsByClient/${uuid}`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        // this.handelErrors(err);
        throw err;
      })
    );
  }

  getAllEventByType(uuid: string, type: string): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Event/GetEventsByClientAndEventType/${uuid}/${type}`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        // this.handelErrors(err);
        throw err;
      })
    );
  }

  getAllPastEvent(uuid: string): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Event/GetEventsByClient/${uuid}`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        // this.handelErrors(err);
        throw err;
      })
    );
  }

  getBoothByEventAndSponsorUUID(eventUUID: string, clientUUID: string): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}EventBooth/GetBoothByEventAndSponsor/${eventUUID}/${clientUUID}`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        this.handelErrors(err);
        throw err;
      })
    );
  }


  saveBoothDetails(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}eventBooth/create`;
    return this.http.post(this.payloadUrl, requestData).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        this.handelErrors(err);
        throw err;
      })
    );
  }

  updateBoothDetails(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}eventBooth/update`;
    return this.http.put(this.payloadUrl, requestData).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        this.handelErrors(err);
        throw err;
      })
    );
  }

  getBoothDetails(uuid: string): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}eventBooth/GetBoothsByEvent/${uuid}`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        // this.handelErrors(err);
        throw err;
      })
    );
  }

  getBoothsBySponsor(ClientUUID: string): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}EventBooth/GetBoothsBySponsor/${ClientUUID}`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        this.handelErrors(err);
        throw err;
      })
    );
  }

  deleteBooth(uuid): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}eventBooth/delete/${uuid}`;
    return this.http.delete(this.payloadUrl, this.httpOptions).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        this.handelErrors(err);
        throw err;
      })
    );
  }

  saveTrack(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}track/create`;
    return this.http.post(this.payloadUrl, requestData).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        this.handelErrors(err);
        throw err;
      })
    );
  }

  updateTrack(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}track/update`;
    return this.http.put(this.payloadUrl, requestData).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        this.handelErrors(err);
        throw err;
      })
    );
  }

  deleteTrack(uuid): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}track/delete/${uuid}`;
    return this.http.delete(this.payloadUrl, this.httpOptions).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        this.handelErrors(err);
        throw err;
      })
    );
  }

  getAllTrack(uuid: string): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Track/GetTracksByEvent/${uuid}`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        // this.handelErrors(err);
        throw err;
      })
    );
  }


  saveSession(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Session/create`;
    return this.http.post(this.payloadUrl, requestData).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        this.handelErrors(err);
        throw err;
      })
    );
  }

  updateSession(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Session/update`;
    return this.http.put(this.payloadUrl, requestData).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        this.handelErrors(err);
        throw err;
      })
    );
  }

  deleteSession(uuid): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Session/delete/${uuid}`;
    return this.http.delete(this.payloadUrl, this.httpOptions).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        this.handelErrors(err);
        throw err;
      })
    );
  }

  joinSession(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Session/Join?EmailId=${requestData.email}&SessionUUID=${requestData.uuid}`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  getAllSession(uuid: string): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Session/GetSessionsByEvent/${uuid}`;
    // this.payloadUrl = `${this.dotNetBaseUrl}Session/GetAll`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        // this.handelErrors(err);
        throw err;
      })
    );
  }
  getAllSessionsByEventId(uuid: string): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Session/GetAllSessionsByEventId/${uuid}`;
    // this.payloadUrl = `${this.dotNetBaseUrl}Session/GetAll`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        // this.handelErrors(err);
        throw err;
      })
    );
  }
  getSession(SessionUUID: string) {
    this.payloadUrl = `${this.dotNetBaseUrl}Session/Get/${SessionUUID}`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  getAllSessionByEvent(uuid: string): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Session/GetAllSessionsByEvent/${uuid}`;
    // this.payloadUrl = `${this.dotNetBaseUrl}Session/GetAll`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        // this.handelErrors(err);
        throw err;
      })
    );
  }

  getEventByID(EventUUID: string) {
    this.payloadUrl = `${this.dotNetBaseUrl}Event/GetByEvent/${EventUUID}`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  GetSessionPollAnalytic(uuid: string): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Session/GetSessionPollAnalytic/${uuid}`;
    // this.payloadUrl = `${this.dotNetBaseUrl}Session/GetAll`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        // this.handelErrors(err);
        throw err;
      })
    );
  }


  GetSessionCommmetsWithAns(uuid: string): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Session/GetCommentsAns/${uuid}`;
    // this.payloadUrl = `${this.dotNetBaseUrl}Session/GetAll`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        // this.handelErrors(err);
        throw err;
      })
    );
  }

  GetChat(requestData: any): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}SponsorChat/GetChat?currentUser=` + requestData.currentUser + `&otherUser=` + requestData.otherUser + `&sessionUUId=` + requestData.sessionUUId + `&eventUUID=` + requestData.eventUUID;
    // this.payloadUrl = `${this.dotNetBaseUrl}Session/GetAll`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        //this.appInsightService.logException(err);
        // this.handelErrors(err);
        throw err;
      })
    );
  }

  getChatUsers(SponsorUserUUID) {
    this.payloadUrl = `${this.dotNetBaseUrl}SponsorChat/GetUserListChat?uuid=` + SponsorUserUUID.UUID + `&eventUuid=` + SponsorUserUUID.eventUUID;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        // this.handelErrors(err);
        throw err;
      })
    );

  }


  SaveSessionCommmetsWithAns(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Session/PostCommentsAns`;
    // this.payloadUrl = `${this.dotNetBaseUrl}Session/GetAll`;
    return this.http.post(this.payloadUrl, requestData).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        this.handelErrors(err);
        throw err;
      })
    );
  }

  saveBreakout(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}eventBreakout/create`;
    return this.http.post(this.payloadUrl, requestData).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        this.handelErrors(err);
        throw err;
      })
    );
  }

  updateBreakout(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}eventBreakout/update`;
    return this.http.put(this.payloadUrl, requestData).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        this.handelErrors(err);
        throw err;
      })
    );
  }

  deleteBreakout(uuid): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}eventBreakout/delete/${uuid}`;
    return this.http.delete(this.payloadUrl, this.httpOptions).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        this.handelErrors(err);
        throw err;
      })
    );
  }

  getAllBreakout(uuid: string): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}EventBreakout/GetEventBreakoutsByEvent/${uuid}`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        // this.handelErrors(err);
        throw err;
      })
    );
  }

  saveSponsor(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}client/create`;
    return this.http.post(this.payloadUrl, requestData).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        this.handelErrors(err);
        throw err;
      })
    );
  }

  updateSponsor(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}client/update`;
    return this.http.put(this.payloadUrl, requestData).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        this.handelErrors(err);
        throw err;
      })
    );
  }

  deleteSponsor(uuid): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}client/delete/${uuid}`;
    return this.http.delete(this.payloadUrl, this.httpOptions).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        this.handelErrors(err);
        throw err;
      })
    );
  }

  getAllSponsor(): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}client/GetAll`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        // this.handelErrors(err);
        throw err;
      })
    );
  }

  getCompanyDetails(uuid: string): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}client/Get/${uuid}`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        // this.handelErrors(err);
        throw err;
      })
    );
  }

  getClientSponsors(clientUUID): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Client/GetClientSponsors/${clientUUID}`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        // this.handelErrors(err);
        throw err;
      })
    );
  }

  getSponsorsClient(clientUUID): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Client/GetSponsorClients/${clientUUID}`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        // this.handelErrors(err);
        throw err;
      })
    );
  }

  getAllSponsorNames(): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}client/GetClientNames`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        // this.handelErrors(err);
        throw err;
      })
    );
  }

  saveUser(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}user/create`;
    return this.http.post(this.payloadUrl, requestData).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        this.handelErrors(err);
        throw err;
      })
    );
  }

  updateUser(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}user/update`;
    return this.http.post(this.payloadUrl, requestData).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        this.handelErrors(err);
        throw err;
      })
    );
  }

  deleteUser(uuid): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}user/delete/${uuid}`;
    return this.http.delete(this.payloadUrl, this.httpOptions).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        this.handelErrors(err);
        throw err;
      })
    );
  }

  getAllUser(): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}user/GetAll`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        // this.handelErrors(err);
        throw err;
      })
    );
  }

  getUserByUUid(uuid): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}user/get/${uuid}`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        // this.handelErrors(err);
        throw err;
      })
    );
  }

  getUserNames(): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}user/GetUserNames`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        // this.handelErrors(err);
        throw err;
      })
    );
  }

  getUserNamesByClientUUID(clientUUID: string): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}user/GetUserNames/${clientUUID}`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        // this.handelErrors(err);
        throw err;
      })
    );
  }

  getAllUserRoles(): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Role/GetAll`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        // this.handelErrors(err);
        throw err;
      })
    );
  }

  saveResource(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Resource/create`;
    return this.http.post(this.payloadUrl, requestData).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        this.handelErrors(err);
        throw err;
      })
    );
  }

  updateResource(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Resource/Update`;
    return this.http.put(this.payloadUrl, requestData).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        this.handelErrors(err);
        throw err;
      })
    );
  }

  deleteResource(uuid): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Resource/delete/${uuid}`;
    return this.http.delete(this.payloadUrl, this.httpOptions).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        this.handelErrors(err);
        throw err;
      })
    );
  }

  getAllResource(clientUUID?: string): Observable<any> {
    if (clientUUID) {
      this.payloadUrl = `${this.dotNetBaseUrl}Resource/GetAll/${clientUUID}`;
    } else {
      this.payloadUrl = `${this.dotNetBaseUrl}Resource/GetAll`;
    }
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        // this.handelErrors(err);
        throw err;
      })
    );
  }

  getAllResourceByClientUUID(ClientUUID: string): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Resource/GetAllByClient/${ClientUUID}`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        // this.handelErrors(err);
        throw err;
      })
    );
  }

  getAllResourceByEventUUID(ClientUUID: string, EventUUID: string): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Resource/GetAllByClientAndEvent/${ClientUUID}/${EventUUID}`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        // this.handelErrors(err);
        throw err;
      })
    );
  }

  saveVideoAnalytics(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}SavePlayerVideoData/Save`;
    return this.http.post(this.payloadUrl, requestData).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  saveContactUsDetails(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}UserQuestionsForSponsor/Save`;
    return this.http.post(this.payloadUrl, requestData).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  getBreakoutQuestions(userUUID): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}UserBreakoutQuestion/get/${userUUID}`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        this.handelErrors(err);
        throw err;
      })
    );
  }

  saveBreakOutQuestions(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}UserBreakoutQuestion/Save`;
    return this.http.post(this.payloadUrl, requestData).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  getFeedbackStatus(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Session/GetUserSessionLikeDislike`;
    return this.http.post(this.payloadUrl, requestData).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  saveFeedbackQuestions(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Session/SaveUserLikeDislike`;
    return this.http.post(this.payloadUrl, requestData).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  saveBroadCastMessage(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}BroadcastMessage/Create`;
    return this.http.post(this.payloadUrl, requestData).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  updateBroadCastMessage(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}BroadcastMessage/Update`;
    return this.http.put(this.payloadUrl, requestData).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  getAllBroadcastMessages(eventUUID): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}BroadcastMessage/GetBroadcastMessageByEvent/${eventUUID}`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        this.handelErrors(err);
        throw err;
      })
    );
  }

  deleteBroadcastMessage(uuid): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}BroadcastMessage/Delete/${uuid}`;
    return this.http.delete(this.payloadUrl, this.httpOptions).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        this.handelErrors(err);
        throw err;
      })
    );
  }

  getLiveUpdates(uuid): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Event/LiveUpdate/${uuid}`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        // this.handelErrors(err);
        throw err;
      })
    );
  }

  getGalleryByEvents(uuid): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}EventGallery/GetByEvent/${uuid}`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        this.handelErrors(err);
        throw err;
      })
    );
  }

  getGalleryByEventUUID(uuid): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Event/GetGallery/${uuid}`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        this.handelErrors(err);
        throw err;
      })
    );
  }

  saveGallery(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}EventGallery/Create`;
    return this.http.post(this.payloadUrl, requestData).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  updateGallery(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}EventGallery/Update`;
    return this.http.put(this.payloadUrl, requestData).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  deleteGallery(uuid): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}EventGallery/Delete/${uuid}`;
    return this.http.delete(this.payloadUrl, this.httpOptions).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        this.handelErrors(err);
        throw err;
      })
    );
  }

  getAllAttendeesAnalytics(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Analytics/AttendedUsers`;
    return this.http.post(this.payloadUrl, requestData).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        // this.handelErrors(err);
        throw err;
      })
    );
  }

  getAllNonAttendeesAnalytics(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Analytics/NotAttendedUsers`;
    return this.http.post(this.payloadUrl, requestData).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        // this.handelErrors(err);
        throw err;
      })
    );
  }

  getVideoAnalytics(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Analytics/PlayerVideo`;
    return this.http.post(this.payloadUrl, requestData).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        // this.handelErrors(err);
        throw err;
      })
    );
  }

  getUserProfileDetails(uuid): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Analytics/GetUserProfile/${uuid}`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        this.handelErrors(err);
        throw err;
      })
    );
  }

  downloadResource(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}File/DownloadResourceFile`;
    return this.http.post(this.payloadUrl, requestData).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        this.handelErrors(err);
        throw err;
      })
    );
  }

  getAllDownloadedResource(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Analytics/GetUserResourceDownload`;
    return this.http.post(this.payloadUrl, requestData).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        this.handelErrors(err);
        throw err;
      })
    );
  }

  getInviteSponsorStatus(uuid: string): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Client/InviteSponsor/${uuid}`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        // this.handelErrors(err);
        throw err;
      })
    );
  }

  sendSponsorInvite(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Client/CreateClientSponsorInvite`;
    return this.http.post(this.payloadUrl, requestData).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        this.handelErrors(err);
        throw err;
      })
    );
  }

  getSponsorInvite(uuid: string): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Client/GetClientSponsorInvite/${uuid}`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        // this.handelErrors(err);
        throw err;
      })
    );
  }

  acceptInvite(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Client/AcceptInvite`;
    return this.http.post(this.payloadUrl, requestData).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  createSponsor(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Client/CreateClientSponsor`;
    return this.http.post(this.payloadUrl, requestData).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        this.handelErrors(err);
        throw err;
      })
    );
  }

  getSponsorTeamMembers(ClientUUID: string): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}SponsorUser/GetAll/${ClientUUID}`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        // this.handelErrors(err);
        throw err;
      })
    );
  }

  getSponsorTeamMembersById(uuid: string): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}SponsorUser/Get/${uuid}`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        // this.handelErrors(err);
        throw err;
      })
    );
  }

  saveSponsorUser(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}SponsorUser/Create`;
    return this.http.post(this.payloadUrl, requestData).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        // this.handelErrors(err);
        throw err;
      })
    );
  }

  updateSponsorUser(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}SponsorUser/Update`;
    return this.http.post(this.payloadUrl, requestData).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        // this.handelErrors(err);
        throw err;
      })
    );
  }

  saveProductInterestByUser(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}EventBooth/CreateUserInterest`;
    return this.http.post(this.payloadUrl, requestData).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        // this.handelErrors(err);
        throw err;
      })
    );
  }

  getUserIntrest(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}EventBooth/GetUserInterest`;
    return this.http.post(this.payloadUrl, requestData).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        // this.handelErrors(err);
        throw err;
      })
    );
  }

  getEventBoothsAndGalleryByEvent(eventUUID): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}EventBooth/GetEventBoothsAndGalleryByEvent/${eventUUID}`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        // this.handelErrors(err);
        throw err;
      })
    );
  }

  getAllInterestAnalytics(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Analytics/GetEventBoothGallaryUserInterest?eventBoothUUID=${requestData.eventBoothUUID}&eventBoothGalleryResourceUUID=${requestData.eventBoothGalleryResourceUUID}`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        // this.handelErrors(err);
        throw err;
      })
    );
  }

  getEventBooth(uuid): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}EventBooth/Get/${uuid}`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        // this.handelErrors(err);
        throw err;
      })
    );
  }

  approveBooth(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}EventBooth/Approve`;
    return this.http.put(this.payloadUrl, requestData).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  postComment(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Session/PostComments`;
    return this.http.post(this.payloadUrl, requestData).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        // this.handelErrors(err);
        throw err;
      })
    );
  }

  getSessionVideoComments(uuid: string): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Session/GetComments/${uuid}`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        // this.handelErrors(err);
        throw err;
      })
    );
  }

  saveUserLike(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Session/SaveUserLike`;
    return this.http.post(this.payloadUrl, requestData).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  getUserLikeStatus(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Session/GetUserSessionLike`;
    return this.http.post(this.payloadUrl, requestData).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  getExhibitAttendees(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Analytics/EventBoothAttendedUsers`;
    return this.http.post(this.payloadUrl, requestData).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  forgotPassword(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}User/ForgotPassword`;
    return this.http.post(this.payloadUrl, requestData, this.httpOptions).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  getEventBoothEnquiryCount(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Analytics/GetEventBoothEnquiryCount`;
    return this.http.post(this.payloadUrl, requestData).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  getEventBoothEnquiry(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Analytics/EventBoothEnquiry`;
    return this.http.post(this.payloadUrl, requestData).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  getEventSummary(uuid: string): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Dashboard/GetEventDashboard/${uuid}`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        // this.handelErrors(err);
        throw err;
      })
    );
  }

  getSessionSummary(eventUUID: string, sessionUUID: string): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Dashboard/GetSessionDashboard/${eventUUID}/${sessionUUID}`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        // this.handelErrors(err);
        throw err;
      })
    );
  }

  getEventBoothSummary(eventUUID: string, eventBoothUUID: string): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Dashboard/GetEventboothDashboard/${eventUUID}/${eventBoothUUID}`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        // this.handelErrors(err);
        throw err;
      })
    );
  }

  getEventResourceSummary(eventUUID: string, eventBoothUUID: string): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Dashboard/GetEventboothResourceDashboard/${eventUUID}/${eventBoothUUID}`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        // this.handelErrors(err);
        throw err;
      })
    );
  }

  getEventList(): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}Event/GetAll`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  isOnlineExhibitor(uuid: string) {
    this.payloadUrl = `${this.dotNetBaseUrl}SponsorUser/CurentUserLoginOrNot?uuid=` + uuid;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  GetByEventPrizes(uuid: string) {
    this.payloadUrl = `${this.dotNetBaseUrl}prizes/GetByEvent/${uuid}`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }

  getPointsByEvent(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}user/GetUserActivityPointByEvent`;
    return this.http.post(this.payloadUrl,requestData).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        // this.handelErrors(err);
        throw err;
      })
    );
  }

  addExtraPoint(requestData): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}ExtraPointDetailsActivityLog/Create`;
    return this.http.post(this.payloadUrl,requestData).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        this.handelErrors(err);
        throw err;
      })
    );
  }
  getUserPoints(uuid: string, eventUUID: string): Observable<any> {
    this.payloadUrl = `${this.dotNetBaseUrl}User/GetUserPointByEvent/${uuid}/${eventUUID}`;
    return this.http.get(this.payloadUrl).pipe(
      catchError(err => {
        this.appInsightService.logException(err);
        throw err;
      })
    );
  }
 

  handelErrors(err: any) {
    let error: any = '';
    if (err.status === 404) {
      error = {
        status: err.status,
        message: ['Not found']
      }
    } else if (err.status === 500) {
      error = {
        status: err.status,
        message: ['Oops! Something went wrong']
      }
    } else {
      const errorsArr = err.error.errors ? err.error.errors : err.error.DataValidationErrors;
      error = {
        status: err.status,
        message: Object.values(errorsArr)
      }
    }
    // const dialogRef = this.dialog.open(ErrorDialogComponent, {
    //   width: '350px',
    //   disableClose: false,
    //   data: error
    // });

    // dialogRef.afterClosed().subscribe(result => {
    //   // console.log('The dialog was closed' + result);
    //   this.appInsightService.logEvent('Error dialog close event');
    // });
  }
}
