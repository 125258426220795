import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PublicRestService } from './core/services/public-rest-service';
import { EnvironmentService } from './core/services/environment-service';
import { HttpClient } from '@angular/common/http';

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [], 
  exports: []
})

export class PckApiModule {
  constructor(@Optional() @SkipSelf() parentModule: PckApiModule) {
    if (parentModule) console.log("PckApiModule loaded");
  }
  
  static forRoot(): ModuleWithProviders<PckApiModule> {
    return {
      ngModule: PckApiModule,
      providers: [EnvironmentService, PublicRestService],
    };
  }

  static forChild(): ModuleWithProviders<PckApiModule> {
    return {
      ngModule: PckApiModule,
    };
  }
}
