import { Component } from '@angular/core';

@Component({
  selector: 'app-video-end',
  templateUrl: './video-end.component.html',
  styleUrls: ['./video-end.component.scss']
})
export class VideoEndComponent {

}
