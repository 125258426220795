<div [innerHTML]="html"></div>
<div class="event-15th-icwp">
    <header class="section15">
        <div class="container12">
            <img class="brand-product-logos3" alt="" src="assets/images/container@2x.png" />
        </div>
    </header>
    <section class="section7 fontfamily">
        <div class="container5">
            <img class="col-image-icon2" alt="" src="assets/images/Col_Image.png" />

            <div class="col-text2">
                <div class="h5-group">
                    <b class="h51">FREE MASTERCLASS</b>
                    <h1 class="h11">From Care Wreck to Care Empowered:</h1>
                </div>
                <div class="h31">
                    The One Thing You Can Do To Transform Your Medicaid Practice 
                </div>
                <button class="button3" (click)="redirect()">
                    <b class="watch-now3">Watch Now</b>
                </button>
            </div>
        </div>
    </section>
    <section class="section8">
        <div class="container6">
            <b class="h23">Special event for Independent Care Waiver Program - ICWP Case
                Managers in Georgia</b>
            <div class="paragraphs4">
                Hundreds of care providers are simplifying care coordination with
                this simple solution!
            </div>
            <button class="button3" (click)="redirect()">
                <b class="watch-now3">Watch Now</b>
            </button>
        </div>
    </section>
    <section class="section9">
        <div class="container5">
            <div class="col-text3">
                <div class="h2-container">
                    <b class="h23">Join this workshop and discover...</b>
                    <div class="paragraphs4">
                        <ul class="how-to-move-out-of-care-chaos1">
                            <li class="how-to-move1">
                                How to move out of care chaos and into the success you want
                                and need.
                            </li>
                            <li class="how-to-move1">
                                Strategies to save time and make Medicaid compliance
                                effortlessly streamlined.
                            </li>
                            <li>
                                The secrets to improving your financial health and expanding
                                your service offerings.
                            </li>
                        </ul>
                    </div>
                </div>
                <button class="button3" (click)="redirect()">
                    <b class="watch-now5">Watch Now</b>
                </button>
            </div>
            <img class="col-image-icon3" alt="" src="assets/images/col-image@2x.png" />
        </div>
    </section>
    <section class="section8">
        <div class="container8">
            <div class="h2-container">
                <b class="h51">WONDERING IF THIS REALLY WORKS?</b>
                <div class="paragraphs4">
                    Check out how many care providers are mastering the overwhelming
                    stress and chaos using this...
                </div>
            </div>
            <div class="person-image1">
                <img class="otlperson-icon1" alt="" src="assets/images/otlperson@2x.png" />
            </div>
            <div class="paragraphs7">
                <p class="implementing-these-tools1">
                    "Implementing these tools has saved us countless hours in
                    documentation and allowed us to focus more on client care."
                </p>
                <p class="implementing-these-tools1">
                    <b>– Bernae Plechette</b>
                </p>
            </div>
        </div>
    </section>
    <section class="section11">
        <div class="container9">
            <div class="brand-product-logos-container">
                <img class="brand-product-logos1" alt="" src="assets/images/brand--product-logos@2x.png" />
            </div>
            <div class="copyright-20231">
                Copyright © 2023 caryfy.ai. All rights reserved
            </div>
        </div>
    </section>
</div>