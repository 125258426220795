import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonMethod, EventService, ChannelService, VideoModel } from 'pck-general-components';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-static-channel',
  templateUrl: './static-channel.component.html',
  styleUrls: ['./static-channel.component.scss']
})
export class StaticChannelComponent implements OnInit {
  @ViewChild('closebutton') closebutton: any;

  emailId: string = "";
  firstName: string = "";
  stateName: any = "Georgia";
  lastName: string = "";
  businessName: string = "";
  validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  isUserRegister: boolean;
  defaulText: string = "REGISTER NOW";
  registerFormGroup = new FormGroup({
    FirstName: new FormControl('', Validators.required),
    LastName: new FormControl(''),
    EmailId: new FormControl('', [Validators.required, Validators.pattern(this.validEmailRegex)]),
    PhoneNo: new FormControl('', [Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
    BusinessName: new FormControl('', Validators.required),
    Comments: new FormControl(''),
  });
  videoURL: any = "https://media.publit.io/file/Masterclass-on-Referral-Management.mp4";
  playVideo: boolean;
  liveEventURL = "";
  userEmail: string = "GuestUser"+Math.floor(100000 + Math.random() * 900000)+"@yopmail.com";
  firstEventUUID: string = "506e6132-cc82-49e4-a28e-d933eca43e89";
  secondEventUUID: string = "b46f9aeb-5832-41aa-a057-2c44a527bc67";
  thirdEventUUID: string = "ba99bfaa-359d-413c-8ca5-3306e79290bd";
  fourthEventUUID: string = "a431df9c-b463-4374-a7da-68f2a7ec5663";
  fifthEventUUID: string = "045e1bc5-52f5-41ea-b915-f3603dd68d29";

  constructor(private route: ActivatedRoute, private commonMethod: CommonMethod, private eventService: EventService, private activatedRoute: ActivatedRoute, private channelService: ChannelService, private router: Router,private meta: Meta) {

    //this.liveEventURL="live-event?emailId="+this.userEmail+"&firstName="+this.firstName+"&lastName="+this.lastName+"&businessName="+this.businessName+"&stateName="+this.stateName+"&eventUUID="+this.eventUUID;

    this.meta.addTags([ 
      { name: 'description', content: 'Success summit' }, 
      { name: 'keywords', content: 'success,summit,medicaid,workshop' } 
  ]);
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.emailId = this.route.snapshot.queryParamMap.get('emailId') != null ? this.route.snapshot.queryParamMap.get('emailId') : "";
      this.firstName = this.route.snapshot.queryParamMap.get('firstName') != null ? this.route.snapshot.queryParamMap.get('firstName') : "";
      this.lastName = this.route.snapshot.queryParamMap.get('lastName') != null ? this.route.snapshot.queryParamMap.get('lastName') : "";
      this.businessName = this.route.snapshot.queryParamMap.get('businessName') != null ? this.route.snapshot.queryParamMap.get('businessName') : "";

      this.userEmail = this.emailId ? this.emailId : this.userEmail;

      //this.liveEventURL="live-event?emailId="+this.userEmail+"&firstName="+this.firstName+"&lastName="+this.lastName+"&businessName="+this.businessName+"&stateName="+this.stateName+"&eventUUID="+this.firstEventUUID;
      this.liveEventURL = "live-event?emailId=" + this.userEmail + "&firstName=" + this.firstName + "&lastName=" + this.lastName + "&businessName=" + this.businessName + "&stateName=" + this.stateName + "&eventUUID=";
      if (this.emailId) {
        localStorage.setItem('emailId', this.emailId)
        this.registerFormGroup.controls['EmailId'].setValue(this.emailId);
        this.registerFormGroup.controls['FirstName'].setValue(this.firstName + " " + this.lastName);
        this.registerFormGroup.controls['LastName'].setValue(this.lastName);
        this.registerFormGroup.controls['BusinessName'].setValue(this.businessName);
        const formData: any = new FormData();
        formData.append('ChannelUUID', 'a3aab25f-9f3b-46ef-9d1c-2e5d78aef2be');
        formData.append('EmailId', this.emailId);
        this.channelService.GetRegisterUser(formData).subscribe((userresp: any) => {
          if (userresp) {
            this.isUserRegister = true;
            this.defaulText = "You've already registered";
          }
        });
      }
      if (params && params['stateName'])
        this.stateName = params['stateName'];
    });
  }


  clearForm() {
    // Use patchValue to set blank values for all controls
    this.registerFormGroup.patchValue({
      FirstName: '',
      LastName: '',
      EmailId: '',
      PhoneNo: '',
      BusinessName: '',
      Comments: ''
      // ...
    });
  }

  registerEvent() {
    if (!this.businessName) {
      this.registerFormGroup.controls['BusinessName'].setValue('DirectEntry');
    }

    if (this.registerFormGroup.invalid) {
      Swal.fire(
        'Oops!',
        'Please Fill All Details',
        'info'
      )

      return;
    }
    const formData: any = new FormData();
    formData.append('FirstName', this.registerFormGroup.controls['FirstName'].value);
    formData.append('LastName', this.lastName);
    formData.append('EmailId', this.registerFormGroup.controls['EmailId'].value);
    formData.append('PhoneNo', this.registerFormGroup.controls['PhoneNo'].value);
    formData.append('Comments', this.registerFormGroup.controls['Comments'].value);
    formData.append('ChannelUUID', 'a3aab25f-9f3b-46ef-9d1c-2e5d78aef2be');
    formData.append('BusinessName', this.registerFormGroup.controls['BusinessName'].value);
    this.channelService.RegisterViaEvent(formData).subscribe(
      (response) => {
        this.clearForm();
        // Handle success - response contains the data from the server
        if (response && response['uuid']) {
          this.closebutton.nativeElement.click();
          Swal.fire(
            'Thank you',
            'Your registration is successful',
            'success'
          )
        }
      },
      (error) => {
        // Handle error - error contains the error message or details
        console.error('An error occurred:', error);
        this.closebutton.nativeElement.click();
        Swal.fire(
          'Oops!',
          'Your Are Already Register',
          'error'
        )
      }
      // You can also display error messages to the user here

    );
  }

  onVideoClick(item: any, content: any): void {
    this.playVideo = true;
    console.log('onVideoClick', item);
    this.commonMethod.openModalPopup(content, 'xl');
  }

}
