import { Component } from '@angular/core';

@Component({
  selector: 'app-third-event-blog',
  templateUrl: './third-event-blog.component.html',
  styleUrls: ['./third-event-blog.component.scss']
})
export class ThirdEventBlogComponent {

}
