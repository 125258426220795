<!-- <div *ngIf="showGoLivebtn!=0">
    <section [ngClass]="showGoLivebtn==1?'livepage auditorium-session auditorium-session_live custom-auditorium-session':'page auditorium-session custom-auditorium-session'">
        <div class="navbartoggle-blg-dv">
            <div class="row">
                <div class="navbartoggle-header">
                  </div>
            </div>
        </div>
        <nav *ngIf="showGoLivebtn!=1" class="responsive_right-menuhide">
            <ul>
              </ul>
        </nav>
        <div class="container-fluid audi-container audi-container-desktop live_audi-container">
            <div class="col-12 mb-4 p-0 player-container h-100" [class.d-none]="openAgenda" [class..d-block]="!openAgenda" *ngIf="activeAudiData">
                <div *ngIf="showGoLivebtn==0">
                    <div class="card-img cony"><img [src]="activeAudiData.sessionResourceVideoThumbnailUuid?getAttachmentUrl(activeAudiData.sessionResourceVideoThumbnailUuid):''" class="img-fluid recent-img-dv"></div>
                    <p *ngIf="meetingNotStart">LiveSession is not started</p>
                </div>
                <div class="divifrm liveevent-blog" *ngIf="showGoLivebtn==1">
                    <iframe [src]="joinURL" allow="microphone *; camera *; display-capture *;" allowFullScreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" sandbox="allow-same-origin allow-scripts allow-modals allow-forms" style='width:80%;height:80%;border:0'
                        scrolling="no">
                </iframe>
                <div class="liveevent-navbar_menu">
                   <div class="ul_liveevent_menu">
                    <nav class="liveevent_menu">
                        <ul>
                           
                            <li class="home custom-mb-style" routerLink="/view/reception">
                                <img src="../../../assets/audiance-view/auditorium/logout_icondv.png">
                                <p>Logout</p>
                            </li>
                        </ul>
                    </nav>
                   </div>
                </div>
            </div>
                <app-vjs-player *ngIf="showGoLivebtn==2" [type]="'single'" [chat]="enableChat" [sessionPolls]="sessionPollsList" [options]="playerOptions" [userData]="{pageName: 'SESSION_AUDI', sessionData: activeAudiData, videoId: '', resourceUUID: ''}"></app-vjs-player>
            </div>
            <div class="break-message d-flex flex-column w-100 audi-container-desktop align-items-center justify-content-center" *ngIf="breakMessage.show && !openAgenda && showGoLivebtn!=2">
                <h2 class="mb-0" *ngIf="eventData && eventData.title">{{ eventData.title }}</h2>
                <p>{{ todayDate }}</p>
                <p class="text-success w-75 text-center">{{ breakMessage.message }}</p>
            </div>
        </div>
    </section>
</div> -->
<div class="spinnercontainer" *ngIf="showGoLivebtn==0  && !breakMessage.show"
  style="display: flex;align-items: center;justify-content: center;height:100%;">
  <div class="spinner-border text-primary" role="status">
  </div>
</div>

<!-- NEW CODE STARTS -->
<div class="session-page-user-reaches-be welcome_screen_blg "
  *ngIf="breakMessage.show && !openAgenda && showGoLivebtn!=2 && showGoLivebtn!=4">
  <!-- <div class="session-page-user-reaches-be-child"></div>
    <div class="session-page-user-reaches-be-item"></div> -->
  <header class="header custom-header-dv">
    <div class="logo-wrap success-summit-logo">
      <img class="logo-event-icon" alt="" src="assets/public/logo-event.svg" />
    </div>
    <div class="logo-wrap caryfy_logo">
      <div class="inner_caryfy_logo">
        <!-- <img class="presented-by-icon" alt="" src="assets/public/presented-by.svg" /> -->

        <img class="logo-caryfyai-icon" alt="" src="assets/public/caryfysponsored.png" />
      </div>
    </div>

    <!-- <hr class="border-bottom-dv"> -->
  </header>
  <div class="pre-session pre-session-dv ">
    <div class="col-1">
      <div class="timer">
        <h6 class="session-will-starts" *ngIf="breakMessage.type!='presession'">{{breakMessage.message}}</h6>
        <h6 class="session-will-starts"
          *ngIf="breakMessage.type=='presession' && (sessionTimerCount1>0 ||sessionTimerCount>0)">
          Your Session will begin in
        </h6>

        <h6 class="session-will-starts" *ngIf="breakMessage.type=='presession'">
          <div [innerHTML]="breakMessage.message"></div>
        </h6>
      </div>
      <div class="motivation">
        <div class="its-wonderful-to">
          <p>It's wonderful to have you join us for this session! We
            value your enthusiasm and commitment.</p>
        </div>
      </div>
    </div>
    <div class="col-2">
      <div class="event-title">
        <!-- <h6 class="the-2nd-session">Success Summit - 2023</h6> -->
        <h1 class="success-summit-">
          Success Summit - 2023
          <!-- <span *ngIf="eventData && eventData.title">{{ eventData.title }}</span> -->
        </h1>
      </div>
      <div class="learning">
        <h6 class="the-2nd-session">What you’ll learn?</h6>
        <p class="vulputate-at-dignissim">
          - Recognize areas of operational waste and misalignment<br>
          - Implement processes for improved scheduling, billing and collection
        </p>
      </div>
      <div class="learning">
        <h6 class="the-2nd-session">It is interactive</h6>
        <p class="vulputate-at-dignissim">
          We are sharing best practices together. Feel free to type your comments in chat or if you have any questions
          related to the information being shared. PLUS, there will be poll questions on your screens.
        </p>
      </div>
      <!-- <div class="host-detail">
        <img class="host-image-icon" alt="" src="assets/public/host-image@2x.png" />

        <div class="host-name">
          <h6 class="anand-chaturvedi">Anand Chaturvedi</h6>
          <p class="care-business-coach">CEO, Caryfy AI</p>
        </div>
      </div> -->
    </div>
  </div>
</div>
<app-vjs-player *ngIf="showGoLivebtn==2" [type]="'single'" [chat]="enableChat" [sessionPolls]="sessionPollsList"
  [options]="playerOptions"
  [userData]="{pageName: 'SESSION_AUDI', sessionData: activeAudiData, videoId: '', resourceUUID: ''}"></app-vjs-player>
<div class="modal-dialog" *ngIf="showGoLivebtn==4">
  <div class="modal-content">
    <div class="modal-header">
      <h2 class="modal-title" id="varyingcontentModalLabel">Please Verify Your Details</h2>
      <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
    </div>
    <div class="modal-body">
      <form [formGroup]="registerFormGroup">
        <div class="mb-3 row">
          <div class="col-md-6">
            <label for="firstName-name" class="col-form-label">First Name</label>
            <input type="text" formControlName="FirstName" class="form-control" id="firstName-name"
              value="{{firstName}}">

          </div>
          <div class="col-md-6">
            <label for="lastName-name" class="col-form-label">Last Name</label>
            <input type="text" formControlName="LastName" class="form-control" id="lastName-name" value="{{lastName}}">
          </div>
        </div>
        <div class="mb-3">
          <label for="recipient-name" class="col-form-label required"
            [ngClass]="registerFormGroup.controls['EmailId'].errors && submitted?'saverequired':''">Email</label>
          <input type="email" formControlName="EmailId" class="form-control"
            [ngClass]="registerFormGroup.controls['EmailId'].errors  && submitted?'errorcss':''" id="recipient-name"
            value="{{emailId}}">
          <div *ngIf="registerFormGroup.controls['EmailId'].errors && submitted">
            <label *ngIf="!invalidemail" class="text-danger">Please Enter Email Id</label>
            <label *ngIf="invalidemail" class="text-danger">Please Enter Valid Email Id</label>

          </div>
        </div>
        <div class="mb-3">
          <label for="businessName-text" class="col-form-label required">Business Name</label>
          <input type="text" formControlName="BusinessName" class="form-control"
            [ngClass]="registerFormGroup.controls['BusinessName'].errors  && submitted?'errorcss':''"
            id="businessName-text" value="{{businessName}}">
          <div *ngIf="registerFormGroup.controls['BusinessName'].errors && submitted">
            <label *ngIf="!invalidemail" class="text-danger">Please Enter Business Name</label>

          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <!-- <button type="button" #closebutton class="btn btn-light" data-bs-dismiss="modal">Close</button> -->
      <button type="button" class="btn btn-primary" (click)="registerEvent()">Watch Video</button>
    </div>
  </div>
</div>
<lib-eventlanding *ngIf="eventDetail && eventDetail.id" [eventData]="eventDetail" [isUserLogin]="this.showGoLivebtn==2 && isDynamicLink" [liveURL]=""></lib-eventlanding>
<!-- Varying modal content -->