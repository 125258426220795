import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-blog',
  templateUrl: './user-blog.component.html',
  styleUrls: ['./user-blog.component.scss']
})
export class UserBlogComponent implements OnInit{
  ngOnInit(): void {
    var html = document.getElementsByTagName('body')[0];
    html.classList.add("blog");
    // html.style.backgroundSize = 'cover!important' ;
    // html.style.backgroundRepeat = 'no-repeat';
    // if (html) {
    //   // Use the style property to set the background-image CSS property with !important
    //   html.style.setProperty('background-image', 'url("your-image-url.jpg")', 'important');
    // }
    //throw new Error('Method not implemented.');
  }

}
